import {
  deleteAPIService,
  getAPIService,
  postAPIService,
  putAPIService,
} from "./BaseServce";
import { LayerCompact, LayerReview } from "../models/Layer";

export const getLayers = async (shotId: number) => {
  const res = await getAPIService("/layers", { shot_id: shotId });
  return res.data.results as LayerCompact[];
};

export const createLayer = async (shotId: number) => {
  const res = await postAPIService(`/layers`, { shot_id: shotId });
  return res.data as LayerCompact;
};

export const getLayerDetail = async (layerId: number) => {
  const res = await getAPIService(`/layers/${layerId}`, {});
  return res.data as LayerCompact;
};

export const renameLayer = async (id: number, name: string) => {
  const res = await putAPIService(`/layers/${id}`, { name });
  return res.data;
};

export const deleteLayer = async (id: number) => {
  const res = await deleteAPIService(`/layers/${id}`);
  return res.data;
};

export const getLayerReview = async (layerId: number, ip: string) => {
  const res = await getAPIService(
    `/layers/${layerId}/review`,
    {
      ip,
    },
    true,
  );
  return res.data as LayerReview;
};
