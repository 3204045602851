import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";

import { ReactComponent as NoWorkspaceIcon } from "assets/no-workspace.svg";
import { Workspace } from "models/Workspace";
import { getWorkspace } from "services/WorkspaceService";

import styles from "./WorkspacesPage.module.scss";
import Loader from "../../components/Loader";
import { StyledTab, StyledTabs } from "../../components/StyledTab";
import WorkspaceItem from "../../components/WorkspaceItem";
import { arrayToObjectById, getUser } from "../../utils/helper";

const WorkspacesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState<{ [id: number]: Workspace }>({});
  const [myWorkspace, setMyWorkspace] = useState<Workspace>();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getWorkspace().then((res) => {
      const user = getUser();
      setMyWorkspace(res.find((w) => w.creator.id === user.id));
      setWorkspaces(
        arrayToObjectById(res.filter((w) => w.creator.id !== user.id)),
      );
    });
  }, []);

  return (
    <div>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <h3 className={styles.title}>Workspaces</h3>
          </div>
          <Box>
            <StyledTabs
              value={value}
              onChange={handleChange}
              sx={{ marginLeft: "24px" }}
            >
              <StyledTab label="My Workspace" />
              <StyledTab sx={{ marginLeft: "20px" }} label="Shared Workspace" />
            </StyledTabs>
          </Box>
          {value === 0 && myWorkspace && (
            <WorkspaceItem
              key={myWorkspace?.id}
              workspace={myWorkspace}
              updateWorkspace={(w: Workspace) => setMyWorkspace(w)}
            />
          )}
          {value === 1 && (
            <div className={styles.innerContainer}>
              {Object.keys(workspaces).length > 0 &&
                Object.keys(workspaces).map((key: string) => {
                  const workspace = workspaces[key];
                  return (
                    <WorkspaceItem
                      key={workspace.id}
                      workspace={workspace}
                      updateWorkspace={(w: Workspace) =>
                        setWorkspaces({ ...workspaces, [w.id]: w })
                      }
                    />
                  );
                })}
              {Object.keys(workspaces).length === 0 && (
                <div className={styles.noWorkspace}>
                  <NoWorkspaceIcon />
                  <p>You don’t have any shared workspaces for now.</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkspacesPage;
