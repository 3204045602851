import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import styles from "./VideoPlayer.module.scss";

interface PlayButtonProps {
  isPlaying: boolean;
  onPause: () => void;
  onPlay: () => void;
  disabled: boolean;
}

const PlayButton: React.FC<PlayButtonProps> = ({
  isPlaying,
  onPause,
  onPlay,
  disabled,
}) => {
  return (
    <Tooltip
      title="Play (k)"
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            color: "#E8EAEDCC",
            bgcolor: "#0B0B0F",
            borderRadius: "5px",
            "& .MuiTooltip-arrow": {
              color: "#0B0B0F",
            },
          },
        },
      }}
    >
      <IconButton
        onClick={() => {
          isPlaying ? onPause() : onPlay();
        }}
        disabled={disabled}
        className={styles.playButton}
      >
        {isPlaying ? (
          <PauseIcon className={styles.playButtonIcon} />
        ) : (
          <PlayArrowIcon className={styles.playButtonIcon} />
        )}
      </IconButton>
    </Tooltip>
  );
};
export default PlayButton;
