import { Button, Tooltip } from "@mui/material";
import React, { useState } from "react";

import styles from "./Guide.module.scss";

type Props = {
  id: string;
  guides: string[];
  title: string;
  description: string;
  open: boolean;
  children: React.ReactElement;
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  noMoreTips: (tip?: string) => void;
};

const Guide = ({
  id,
  guides,
  title,
  description,
  open,
  children,
  placement,
  noMoreTips,
}: Props) => {
  return (
    <Tooltip
      open={open && guides.includes(id)}
      arrow
      disableFocusListener
      disableHoverListener
      placement={placement}
      slotProps={{
        tooltip: {
          sx: {
            padding: "0px",
            backgroundColor: "unset",
            fontFamily: "Inter",
          },
        },
        arrow: {
          sx: {
            color: "#5555DB",
          },
        },
      }}
      title={
        <div className={styles.container}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>
            {description.split("\\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
          <div className={styles.btns}>
            <Button className={styles.btn} onClick={() => noMoreTips(id)}>
              Got it
            </Button>
            <Button className={styles.btn} onClick={() => noMoreTips()}>
              Don't show more tips
            </Button>
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default Guide;
