import {
  getAPIService,
  postAPIService,
  deleteAPIService,
  putAPIService,
} from "./BaseServce";
import { Project, ProjectDetail } from "../models/Project";

export const getProjects = async (
  recent = false,
  searchTerm: string | null = null,
) => {
  const res = await getAPIService("/projects", {
    search_term: searchTerm,
    recent,
  });
  return res.data as Project[];
};

export const getProject = async (id: number) => {
  const res = await getAPIService(`/projects/${id}`);
  return res.data as Project;
};

export const createProject = async (name: string, companyId: number) => {
  const res = await postAPIService("/projects", {
    name,
    company_id: companyId,
  });
  return res.data as ProjectDetail;
};

export const deleteProject = async (id: number) => {
  const res = await deleteAPIService(`/projects/${id}`);
  return res.data;
};

export const renameProject = async (id: number, name: string) => {
  const res = await putAPIService(`/projects/${id}`, { name });
  return res.data;
};
