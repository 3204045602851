import React from "react";

import styles from "./DotElastic.module.scss";

const DotElastic = () => {
  return (
    <div data-title="dot-elastic">
      <div>
        <div className={styles.dotElastic} />
      </div>
    </div>
  );
};

export default DotElastic;
