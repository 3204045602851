import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { ClickAwayListener, Tooltip } from "@mui/material";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { STATUSES, ViewModes } from "constants/constants";
import { CutoutInProgress } from "models/Cutout";
import { LayerCompact } from "models/Layer";
import { renameLayer } from "services/LayerService";
import { useClickHandler } from "utils/hooks";

import styles from "./Layers.module.scss";

interface LayerProps {
  layer: LayerCompact;
  isAnnotating: boolean;
  layerId: string | undefined;
  selectedLayer: LayerCompact | null;
  setLayerAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setSelectedLayer: React.Dispatch<React.SetStateAction<LayerCompact | null>>;
  setCutoutInProgress: React.Dispatch<
    React.SetStateAction<CutoutInProgress | null>
  >;
  layersViewMode: ViewModes;
  showRenameLayerModal: boolean;
  setShowRenameLayerModal: React.Dispatch<React.SetStateAction<boolean>>;
  setLayers: React.Dispatch<React.SetStateAction<LayerCompact[]>>;
  layers: LayerCompact[];
  layerInputEl: React.MutableRefObject<HTMLInputElement | null>;
  handleSingleClick: () => void;
}
const Layer: React.FC<LayerProps> = ({
  layer,
  isAnnotating,
  layerId,
  selectedLayer,
  setSelectedLayer,
  setCutoutInProgress,
  setLayerAnchorEl,
  layersViewMode,
  showRenameLayerModal,
  setShowRenameLayerModal,
  setLayers,
  layers,
  layerInputEl,
  handleSingleClick,
}) => {
  const [showInferenceCompleted, setShowInferenceCompleted] = useState(false);

  const handleClick = useClickHandler(handleSingleClick, () => {
    setSelectedLayer(layer);
    setShowRenameLayerModal(true);
  });

  const getStatusClassNames = (status) => {
    const styleNames = [styles.layerContainer];
    styleNames.push(
      layersViewMode === ViewModes.Preview
        ? styles.previewLayerContainer
        : styles.listLayerContainer,
    );
    switch (status) {
      case STATUSES.FAILED:
        styleNames.push(styles.inferenceFailed);
        break;
      case STATUSES.IN_PROGRESS:
        styleNames.push(
          styles.inferencing,
          layersViewMode === ViewModes.Preview
            ? styles.previewInferencing
            : styles.listInferencing,
        );
        if (
          selectedLayer?.id === layer.id ||
          (layerId && +layerId) === layer.id
        )
          styleNames.push(styles.selectedLayerInferencing);
        break;
      case STATUSES.COMPLETED:
        styleNames.push(styles.inferenceSuccess);
        break;
    }
    if (
      (layerId && +layerId === layer.id && !isAnnotating) ||
      selectedLayer?.id === layer.id
    )
      styleNames.push(styles.selectedLayer);
    return styleNames;
  };

  return (
    <div
      key={layer.id}
      className={cx(getStatusClassNames(layer.latest_inference_cutout_status))}
      onClick={handleClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setLayerAnchorEl(e.currentTarget);
        setSelectedLayer(layer);
      }}
    >
      <div
        className={cx(
          styles.layerThumbnail,
          layersViewMode === ViewModes.Preview
            ? styles.previewThumbnail
            : styles.listThumbnail,
        )}
        style={{ backgroundColor: layer.color }}
      >
        {layersViewMode === ViewModes.Preview && (
          <>
            {layer.latest_inference_cutout_status === STATUSES.FAILED && (
              <div className={styles.statusOverlay}>
                Error occurred while inferencing
              </div>
            )}
            {layer.latest_inference_cutout_status === STATUSES.IN_PROGRESS && (
              <div className={styles.statusOverlay}>Inferencing...</div>
            )}
          </>
        )}
        {layer.cover_image_url ? (
          <img
            alt={layer.cover_image_url}
            className={styles.thumbnailImage}
            src={layer.cover_image_url}
          />
        ) : (
          <AspectRatioIcon
            className={
              layersViewMode === ViewModes.Preview
                ? styles.largeIcon
                : styles.smallIcon
            }
          />
        )}
      </div>
      <div
        className={cx(
          styles.layerDetails,
          layersViewMode === ViewModes.List && styles.listLayerDetails,
        )}
      >
        {showRenameLayerModal && selectedLayer?.id === layer.id ? (
          <input
            type="text"
            className={styles.editLayerName}
            defaultValue={layer.name}
            ref={layerInputEl}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "Enter") e.currentTarget.blur();
            }}
            onBlur={(e) => {
              if (e.relatedTarget?.classList.contains("MuiMenu-paper")) return;
              const newName = e.currentTarget.value.trim();
              const newLayer = JSON.parse(JSON.stringify(layer));
              newLayer.name = newName;
              if (newName.length) {
                renameLayer(layer.id, e.currentTarget.value)
                  .then(() => {
                    setLayers(
                      [
                        newLayer,
                        ...layers.filter((v) => v.id !== layer.id),
                      ].sort((l1, l2) => l2.id - l1.id),
                    );
                    setCutoutInProgress(null);
                    setSelectedLayer(null);
                  })
                  .catch((error) => toast.error(error.data));
              }
              setShowRenameLayerModal(false);
            }}
          />
        ) : (
          <>
            {layersViewMode === ViewModes.List && (
              <>
                {layer.latest_inference_cutout_status === STATUSES.FAILED && (
                  <div className={styles.listLayerStatus}>
                    Error occurred...
                  </div>
                )}
                {layer.latest_inference_cutout_status ===
                  STATUSES.IN_PROGRESS && (
                  <div className={styles.listLayerStatus}>Inferencing...</div>
                )}
              </>
            )}
            <div className={styles.name}>{layer.name}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Layer;
