import { Button } from "@mui/material";
import { useFormik, Field, FormikProvider } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { ReactComponent as RightArrowIcon } from "assets/right-arrow.svg";
import { InputField } from "components/InputField";
import { SLAPSHOT_LOGIN_URL } from "constants/urls";
import { resetPassword } from "services/LoginService";

import styles from "./SlapshotResetPassword.module.scss";

const SlapshotResetPasswordPage = () => {
  const { userIdentifier, token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/^(?![0-9]*$)/, "Password cannot be entirely numeric")
        .required("Password is required"),
    }),
    onSubmit: async (values, { setFieldError }) => {
      setIsLoading(true);
      userIdentifier &&
        token &&
        resetPassword(userIdentifier, token, values.password)
          .then((res) => {
            toast.success(res.message);
            navigate(SLAPSHOT_LOGIN_URL);
          })
          .catch((e) => {
            setFieldError("password", e.detail);
          })
          .finally(() => {
            setIsLoading(false);
          });
    },
  });

  return (
    <FormikProvider value={formik}>
      <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
        <div className={styles.innerContainer}>
          <div className={styles.textContainer}>
            <h3>Reset Password</h3>
            <p>
              Please choose a new password to continue creating
              <br />
              your amazing predictions.
            </p>
          </div>
          <Field
            name="password"
            type="password"
            component={InputField}
            inputClassName={styles.input}
            label="Password"
            placeholder="* * * * * * * * *"
          />
        </div>
        <Button
          type="submit"
          className="slapshotAuthButton"
          disabled={isLoading || !formik.values.password}
        >
          Send
          <RightArrowIcon style={{ marginLeft: "12px" }} />
        </Button>
      </form>
    </FormikProvider>
  );
};

export default SlapshotResetPasswordPage;
