/* eslint-disable react/destructuring-assignment */

import cx from "classnames";
import { FieldProps } from "formik";
import React from "react";

import styles from "./InputField.module.scss";

export const Input: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = (props) => {
  const preventDefault = (e: WheelEvent) => {
    e.preventDefault();
  };
  return (
    <input
      {...props}
      className={cx(styles.input, props.className)}
      onFocus={(e) => {
        e.target.addEventListener("wheel", preventDefault, {
          passive: false,
        });
      }}
    />
  );
};

interface InputFieldBaseProps {
  className?: string;
  inputClassName?: string;
  errorClassName?: string;
  labelClassName?: string;
  label?: string;
  prefix?: string; // to be added before the input, like a currency
  prefixClassName?: string;
  placeholder?: string;
  alignStart?: boolean;
}

export type InputFieldProps = InputFieldBaseProps & FieldProps;

export const InputField: React.FC<InputFieldProps> = ({
  field,
  label,
  prefix,
  prefixClassName,
  form: { touched, errors },
  className,
  inputClassName,
  labelClassName,
  errorClassName,
  alignStart = false,
  ...props
}) => {
  return (
    <div className={cx(className, styles.inputContainer)}>
      <div className={label && styles.fieldWithLabel}>
        {label && (
          <label
            htmlFor={field.name}
            className={cx(
              styles.label,
              labelClassName,
              !alignStart && styles.spaceBetween,
            )}
            id={field.name}
          >
            {label}
          </label>
        )}
        {prefix && <span className={prefixClassName}>{prefix}</span>}
        <Input
          type="text"
          {...field}
          {...props}
          className={cx(styles.input, inputClassName)}
        />
      </div>
      {touched[field.name] && errors[field.name] && (
        <div className={cx(styles.error, errorClassName)}>
          {errors[field.name]?.toString()}
        </div>
      )}
    </div>
  );
};
