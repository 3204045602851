import { CircularProgress } from "@mui/material";
import React from "react";

import styles from "./Loader.module.scss";
import Workspace from "../Workspace";

interface LoaderParams {
  isLoading: boolean;
  size?: number;
}
const Loader: React.FC<LoaderParams> = ({ isLoading, size }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div className={styles.container}>
      <CircularProgress color="inherit" size={size} />
    </div>
  );
};

export default Loader;
