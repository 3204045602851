import { AUTH_TOKEN } from "../constants/constants";
import { WEBSOCKET_URL } from "../constants/env";

export const ACTION_SUBSCRIBE_EXPORT_UPDATE = "subscribe_export_update";
export const ACTION_SUBSCRIBE_SHOT_UPDATE = "subscribe_shot_update";
export const ACTION_SUBSCRIBE_ANNOTATION_UPDATE = "subscribe_annotation_update";
export const ACTION_SUBSCRIBE_INFERENCE_UPDATE =
  "subscribe_infrence_cutout_update";
export const ACTION_SUBSCRIBE_CUTOUT_UPDATE = "subscribe_cutout_update";
export const ACTION_SUBSCRIBE_NOTIFICATION_UPDATE =
  "subscribe_notification_events";

const makeId = () => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 12; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getRequestData = (action: string, data?: unknown) => {
  return JSON.stringify({
    action,
    request_id: makeId(),
    data,
  });
};

export const getSocket = (path: string) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (!token) {
    return null;
  }

  const socket = new WebSocket(`${WEBSOCKET_URL}/${path}?token=${token}`);
  socket.onerror = (event) => {
    console.log("Error", event);
  };

  socket.onclose = (event) => {};
  return socket;
};
