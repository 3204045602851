export const PAGINATION_LIMIT = 50;

export const AUTH_TOKEN = "auth_token";
export const AUTH_USER = "auth_user";
export const ROUTES = {
  HOME: "/home",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SETTINGS: "/profile",
};

export const PASSWORD_REGEX =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})";

export const SORTING_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

export const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const LAYER_COLORS = [
  "#6565EC",
  "#D65DB1",
  "#FF8066",
  "#FFC75F",
  "#00C9A7",
  "#009EFA",
];

export const INTRO_GUIDES = "IntroGuides";
export enum IntroGuides {
  "UploadShot" = "UploadShot",
  "SelectShot" = "SelectShot",
  "CreateAnnotation" = "CreateAnnotation",
  "HoverObject" = "HoverObject",
  "ExitAnnotation" = "ExitAnnotation",
  "RunInference" = "RunInference",
  "FirstLayer" = "FirstLayer",
  "FirstCutout" = "FirstCutout",
}

export enum ViewModes {
  "Preview" = "Preview",
  "List" = "List",
}

export const DEFAULT_FPS = 24;

export enum STATUSES {
  "FAILED" = "FAILED",
  "IN_PROGRESS" = "IN_PROGRESS",
  "COMPLETED" = "COMPLETED",
}
