import {
  Avatar,
  Button,
  Dialog,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
} from "@mui/material";
import { useFormik, Field, FormikProvider } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as AddIcon } from "assets/add-icon.svg";
import { ReactComponent as AddUserMenuIcon } from "assets/add-user-menu.svg";
import { ReactComponent as AddUserIcon } from "assets/add-user.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as FolderAddIcon } from "assets/folder-add.svg";
import { ReactComponent as FolderIcon } from "assets/folder.svg";
import { ReactComponent as SettingsIcon } from "assets/gear.svg";
import { ReactComponent as RenameIcon } from "assets/pencil-icon.svg";
import { ReactComponent as ProjectIcon } from "assets/project-icon.svg";
import { ReactComponent as XMark } from "assets/x-mark.svg";
import { IntroGuides, INTRO_GUIDES } from "constants/constants";
import { UserDetail } from "models/User";
import { Workspace } from "models/Workspace";
import { renameWorkspace } from "services/WorkspaceService";

import styles from "./WorkspaceItem.module.scss";
import { SLAPSHOT_INVITE_USER } from "../../constants/urls";
import {
  createProject,
  deleteProject,
  renameProject,
} from "../../services/ProjectService";
import {
  getCreationTimeText,
  getTextInitials,
  getUser,
} from "../../utils/helper";
import { InputField } from "../InputField";
import Loader from "../Loader";

interface WorkspaceItemProps {
  workspace: Workspace;
  updateWorkspace: (w: Workspace) => void;
}

const WorkspaceItem: React.FC<WorkspaceItemProps> = ({
  workspace,
  updateWorkspace,
}) => {
  const navigate = useNavigate();
  const [mousePosition, setMousePosition] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });
  const [thumbnailIndexes, setThumbnailIndexes] = useState<{
    [id: number]: number;
  }>({});

  const [menuProject, setMenuProject] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [showCreateProjectModal, setShowCreateProjectModal] =
    useState<boolean>(false);
  const [showRenameProject, setShowRenameProject] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [settingsAnchor, setSettingsAnchor] =
    React.useState<null | HTMLElement>(null);
  const [showRenameWorkspace, setShowRenameWorkspace] =
    useState<boolean>(false);
  const renameWorkspaceEl = useRef<HTMLInputElement | null>(null);
  const renameProjectEl = useRef<HTMLInputElement | null>(null);

  const [membersAnchor, setMembersAnchor] = useState<HTMLElement | null>(null);
  const [members, setMembers] = useState<UserDetail[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const currentUser = getUser();

  const getUserNameText = (user: UserDetail) => {
    if (!user) {
      return "You";
    }
    let text = user.name;
    if (user.last_name) {
      text += ` ${user.last_name}`;
    }
    if (currentUser.email === user.email) {
      text += ` (you)`;
    }
    return text;
  };

  const filteredMembers = members.filter((m) =>
    getUserNameText(m).toLocaleLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    const defaultIndexes: { [id: number]: number } = {};
    workspace.projects.forEach((project) => {
      defaultIndexes[project.id] = 0;
    });
    setThumbnailIndexes(defaultIndexes);
  }, [workspace]);

  useEffect(() => {
    if (showRenameWorkspace) {
      renameWorkspaceEl.current?.focus();
    }
  }, [showRenameWorkspace]);

  useEffect(() => {
    if (showRenameProject) {
      renameProjectEl.current?.focus();
    }
  }, [showRenameProject]);

  const createProjectformik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      const res = await createProject(values.name, workspace.id)
        .then((response) => {
          if (workspace && !workspace.projects.length)
            localStorage.setItem(
              INTRO_GUIDES,
              JSON.stringify(Object.values(IntroGuides)),
            );
          updateWorkspace({
            ...workspace,
            projects: [response, ...workspace.projects],
          });
        })
        .finally(() => {
          setShowCreateProjectModal(false);
        });
    },
  });

  const handleCreateProjectModal = () => {
    createProjectformik.resetForm();
    setShowCreateProjectModal(!showCreateProjectModal);
  };

  const handleMouseMove = (id, e, thumbnails) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - left;
    const percentage = mouseX / width;
    const newIndex = Math.floor(percentage * thumbnails.length);

    setThumbnailIndexes((prevState) => ({
      ...prevState,
      [id]: newIndex,
    }));
  };

  const handleMouseLeave = (id) => {
    setThumbnailIndexes((prevState) => ({
      ...prevState,
      [id]: 0,
    }));
  };

  const handleClose = () => {
    setMenuProject(null);
    setMousePosition({ mouseX: null, mouseY: null });
  };

  return (
    <div>
      <div className={styles.workspaceContainer}>
        <div className={styles.workspaceHeader}>
          <div className={styles.workspaceInfo}>
            <div className={styles.workspaceIconContainer}>
              <FolderIcon className={styles.workspaceIcon} />
              <h5>{getTextInitials(workspace.name)}</h5>
            </div>
            {showRenameWorkspace ? (
              <input
                type="text"
                className={styles.renameWorkspace}
                defaultValue={workspace.name}
                ref={renameWorkspaceEl}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.currentTarget.blur();
                }}
                onBlur={(e) => {
                  if (e.relatedTarget?.classList.contains("MuiMenu-paper"))
                    return;
                  const newName = e.currentTarget.value.trim();
                  if (newName !== workspace.name && newName.length) {
                    renameWorkspace(workspace.id, newName).then((res) => {
                      updateWorkspace({
                        ...workspace,
                        name: res.name,
                      });
                    });
                  }
                  setShowRenameWorkspace(false);
                }}
              />
            ) : (
              <h3
                onClick={() => {
                  if (workspace.is_admin) {
                    setShowRenameWorkspace(true);
                  }
                }}
              >
                {workspace.name}
              </h3>
            )}
          </div>
          <div className={styles.workspaceActions}>
            <div
              className={styles.workspaceUsers}
              // onMouseOver={(e) => {
              //   setMembersAnchor(null);
              //   setMembersAnchor(e.currentTarget);
              //   setMembers(workspace.users);
              // }}
              // onFocus={(e) => {
              //   setMembersAnchor(null);
              //   setMembersAnchor(e.currentTarget);
              //   setMembers(workspace.users);
              // }}
            >
              {workspace.users.slice(0, 3).map((u, index) =>
                u.profile_picture_url ? (
                  <img
                    key={u.id}
                    className={styles.workspaceUser}
                    src={u.profile_picture_url}
                    alt={u.profile_picture_url}
                    style={{
                      marginRight: "-10px",
                      zIndex: index,
                      border: "2px solid #27273A",
                    }}
                  />
                ) : (
                  <Avatar
                    key={u.id}
                    className={styles.workspaceUserAvatar}
                    {...{ children: getTextInitials(u.name) }}
                    sx={{ bgcolor: "purple", width: 32, height: 32 }}
                    style={{
                      marginRight: "-10px",
                      zIndex: index,
                      border: "2px solid #27273A",
                      textAlign: "center",
                    }}
                  />
                ),
              )}
              {workspace.users.length > 3 && (
                <div
                  className={styles.userCount}
                >{`+${workspace.users.length - 3}`}</div>
              )}
              <span>
                {workspace.users.map((m, index) => (
                  <React.Fragment key={index}>
                    {getUserNameText(m)}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </div>
            {workspace.is_admin && (
              <button
                type="button"
                className={styles.inviteUserButton}
                onClick={() => {
                  navigate(
                    `${SLAPSHOT_INVITE_USER}?workspaceId=${workspace?.id}`,
                  );
                }}
              >
                <AddUserIcon /> Invite
              </button>
            )}

            <button
              type="button"
              className={styles.addProjectButton}
              onClick={handleCreateProjectModal}
            >
              <AddIcon /> Project
            </button>
            {workspace.is_admin && (
              <button
                className={styles.settingsButton}
                type="button"
                onClick={(e) => {
                  setSettingsAnchor(e.currentTarget);
                }}
              >
                <SettingsIcon />
              </button>
            )}
          </div>
        </div>
        {workspace?.projects.length > 0 ? (
          <div className={styles.workspaceProjects}>
            {workspace.projects.map((project) => (
              <div
                className={styles.projectContainer}
                key={project.id}
                onClick={() => {
                  navigate(`projects/${project.id}`);
                }}
                onContextMenu={(e) => {
                  if (!workspace.is_admin) {
                    return;
                  }
                  e.preventDefault();
                  e.stopPropagation();
                  setMousePosition({
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                  });
                  setMenuProject({
                    id: project.id,
                    name: project.name,
                  });
                }}
                onMouseMove={(e) =>
                  handleMouseMove(project.id, e, project.shot_thumbnails)
                }
                onMouseLeave={() => handleMouseLeave(project.id)}
              >
                {project.shot_thumbnails.length > 0 ? (
                  <div className={styles.projectThumbnail}>
                    <img
                      className={styles.thumbnailImage}
                      src={
                        project.shot_thumbnails[thumbnailIndexes[project.id]]
                      }
                      alt={
                        project.shot_thumbnails[thumbnailIndexes[project.id]]
                      }
                    />
                  </div>
                ) : (
                  <div className={styles.iconContainer}>
                    <ProjectIcon />
                  </div>
                )}
                <div className={styles.projectDetail}>
                  <div className={styles.projectName}>
                    {showRenameProject &&
                    menuProject &&
                    project.id === menuProject.id ? (
                      <input
                        type="text"
                        className={styles.renameProject}
                        defaultValue={project.name}
                        ref={renameProjectEl}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.currentTarget.blur();
                        }}
                        onBlur={(e) => {
                          if (
                            e.relatedTarget?.classList.contains("MuiMenu-paper")
                          )
                            return;
                          const newName = e.currentTarget.value.trim();
                          if (newName !== project.name && newName.length) {
                            console.log(newName);
                            renameProject(project.id, newName).then((res) => {
                              updateWorkspace({
                                ...workspace,
                                projects: workspace.projects.map((p) => {
                                  if (res.id === p.id) {
                                    return {
                                      ...p,
                                      name: res.name,
                                    };
                                  }
                                  return p;
                                }),
                              });
                            });
                          }
                          setShowRenameProject(false);
                        }}
                      />
                    ) : (
                      <h3>{project.name}</h3>
                    )}
                    <p>{getCreationTimeText(project.created)}</p>
                  </div>
                  <div className={styles.projectInfo}>
                    <h5>
                      {project.shots_count === 1
                        ? `${project.shots_count} Asset`
                        : `${project.shots_count} Assets`}
                    </h5>
                    <div
                      className={styles.projectUsers}
                      // onMouseEnter={(e) => {
                      //   setMembersAnchor(null);
                      //   setMembersAnchor(e.currentTarget);
                      //   setMembers(project.users);
                      // }}
                    >
                      {project.users.slice(0, 3).map((u, index) =>
                        u.profile_picture_url ? (
                          <img
                            key={u.id}
                            className={styles.projectUser}
                            src={u.profile_picture_url}
                            alt={u.profile_picture_url}
                            style={{
                              marginRight: "-5px",
                              zIndex: index,
                              border: "1px solid #27273A",
                            }}
                          />
                        ) : (
                          <Avatar
                            key={u.id}
                            className={styles.projectUserAvatar}
                            {...{ children: getTextInitials(u.name) }}
                            sx={{
                              bgcolor: "purple",
                              width: 16,
                              height: 16,
                            }}
                            style={{
                              marginRight: "-5px",
                              zIndex: index,
                              border: "1px solid #27273A",
                            }}
                          />
                        ),
                      )}
                      {project.users.length > 3 && (
                        <div
                          className={styles.projectUserCount}
                        >{`+${project.users.length - 3}`}</div>
                      )}
                      <span>
                        {project.users.map((m, index) => (
                          <React.Fragment key={index}>
                            {getUserNameText(m)}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.noProjectsContainer}>
            <div className={styles.text}>
              <FolderAddIcon />
              <h2>Create your first project</h2>
            </div>
            <Button
              className="primaryButton"
              onClick={handleCreateProjectModal}
            >
              <AddIcon className={styles.addIcon} /> Create New Project
            </Button>
          </div>
        )}
      </div>

      <Dialog
        open={showCreateProjectModal}
        PaperProps={{
          className: "dialog",
        }}
      >
        <FormikProvider value={createProjectformik}>
          <form
            className="modalContainer"
            onSubmit={createProjectformik.handleSubmit}
          >
            <div className="modalHeader">
              <h3 className="modalTitle">New Project</h3>
              <button
                onClick={handleCreateProjectModal}
                type="button"
                className="closeButton"
              >
                <XMark />
              </button>
            </div>
            <div className="modalContent">
              <p>
                Create project and upload all the shots here to create the
                predictions
              </p>
              <Field
                name="name"
                component={InputField}
                placeholder="Mission Impossible"
                label="Project Name"
              />
            </div>
            <div className="modalActions">
              <Button
                type="submit"
                disabled={
                  !!createProjectformik.errors.name ||
                  !createProjectformik.values.name
                }
                className="slapShotButton"
              >
                Create Project
              </Button>
            </div>
          </form>
        </FormikProvider>
      </Dialog>
      <Dialog
        open={showConfirmationModal}
        PaperProps={{
          className: "dialog",
        }}
      >
        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="modalTitle">Delete Project</h3>
            <button
              onClick={() => {
                setShowConfirmationModal(false);
              }}
              type="button"
              className="closeButton"
            >
              <XMark />
            </button>
          </div>
          <div className={styles.confirmationText}>
            <p>
              Are you sure you want to delete this project&nbsp;
              <b>{menuProject && menuProject.name}</b>? We highly recommend
              exporting your data before deleting
            </p>
          </div>
          <div className="modalActions">
            <Button
              type="button"
              className={styles.noBackgroundButton}
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className={styles.outlineButtonRed}
              onClick={() => {
                menuProject && deleteProject(menuProject.id);
                setShowConfirmationModal(false);
                handleClose();
                updateWorkspace({
                  ...workspace,
                  projects: workspace?.projects.filter(
                    (p) => p.id !== menuProject?.id,
                  ),
                });
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
      <Menu
        id="basic-menu"
        open={mousePosition.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mousePosition.mouseY !== null && mousePosition.mouseX !== null
            ? { top: mousePosition.mouseY, left: mousePosition.mouseX }
            : undefined
        }
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#111113",
            borderRadius: "7px",
            border: "1px solid #CCCCDC1F",
            padding: "8px 8px",
            width: "184px",
            boxShadow: "0px 8px 24px 0px #010409",
          },
        }}
      >
        <MenuItem
          className={styles.menuItem}
          onClick={() => {
            navigate(
              `${SLAPSHOT_INVITE_USER}?workspaceId=${workspace?.id}&projectId=${menuProject?.id}`,
            );
          }}
        >
          <AddUserMenuIcon style={{ marginRight: "8px" }} /> Invite member
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={() => {
            setMousePosition({ mouseX: null, mouseY: null });
            setShowRenameProject(true);
          }}
        >
          <RenameIcon style={{ marginRight: "8px" }} />
          Rename
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={() => {
            setMousePosition({ mouseX: null, mouseY: null });
            setShowConfirmationModal(true);
          }}
        >
          <DeleteIcon style={{ marginRight: "8px" }} />
          Delete
        </MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        open={!!settingsAnchor}
        anchorEl={settingsAnchor}
        onClose={() => {
          setSettingsAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginTop: "4px",
            backgroundColor: "#111113",
            borderRadius: "7px",
            border: "1px solid #CCCCDC1F",
            padding: "8px 8px",
            width: "184px",
            boxShadow: "0px 8px 24px 0px #010409",
          },
        }}
      >
        <MenuItem
          className={styles.menuItem}
          onClick={() => {
            setShowRenameWorkspace(true);
            setSettingsAnchor(null);
            renameWorkspaceEl.current?.focus();
          }}
        >
          <RenameIcon style={{ marginRight: "8px" }} />
          Rename
        </MenuItem>
        {workspace.is_admin && (
          <MenuItem
            className={styles.menuItem}
            onClick={() => {
              setSettingsAnchor(null);
              navigate(`${SLAPSHOT_INVITE_USER}?workspaceId=${workspace?.id}`);
            }}
          >
            <AddUserIcon style={{ marginRight: "8px" }} />
            Sharing settings
          </MenuItem>
        )}
      </Menu>
      <Popover
        id="basic-menu"
        open={!!membersAnchor}
        anchorEl={membersAnchor}
        onClose={() => {
          setMembersAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          onMouseLeave: () => {
            setMembersAnchor(null);
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            maxHeight: "260px",
            marginTop: "4px",
            padding: "16px",
            backgroundColor: "#22242A",
            border: "1px solid #CCCCDC1F",
            borderRadius: "7px",
            boxShadow: "0px 8px 24px 0px #010409",
            overflowY: "hidden",
          },
        }}
      >
        <div className={styles.membersPopover}>
          <div className={styles.popoverHeader}>
            <p>Pro members:</p>
            <button
              type="button"
              onClick={() => {
                setMembersAnchor(null);
              }}
              aria-label="Close"
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66732 1.27398L8.72732 0.333984L5.00065 4.06065L1.27398 0.333984L0.333984 1.27398L4.06065 5.00065L0.333984 8.72732L1.27398 9.66732L5.00065 5.94065L8.72732 9.66732L9.66732 8.72732L5.94065 5.00065L9.66732 1.27398Z"
                  fill="white"
                  fillOpacity="0.6"
                />
              </svg>
            </button>
          </div>
          <div className={styles.members} />
        </div>
        <div className={styles.members}>
          {filteredMembers.map((m) => (
            <div key={m.id} className={styles.memberContainer}>
              {m.profile_picture_url ? (
                <img
                  src={m.profile_picture_url}
                  alt={m.profile_picture_url}
                  className={styles.memberProfile}
                />
              ) : (
                <Avatar
                  key={m.id}
                  className={styles.memberAvatar}
                  {...{ children: getTextInitials(m.name) }}
                  sx={{ bgcolor: "purple", width: 22, height: 22 }}
                />
              )}
              <h5>{getUserNameText(m)}</h5>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default WorkspaceItem;
