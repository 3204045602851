import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Menu,
  Typography,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import cx from "classnames";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as DropdownIcon } from "assets/dropdown-icon.svg";
import { ReactComponent as EyeIcon } from "assets/eye.svg";
import { ReactComponent as HelpIcon } from "assets/help.svg";
import { ReactComponent as LogoutIcon } from "assets/logout-icon.svg";
import Notifications from "components/Notifications";
import ProjectSearchBar from "components/ProjectSearchBar";
import { Preferences } from "models/User";
import DownloadsButton from "pages/Downloads";

import styles from "./SlapshotMainLayout.module.scss";
import Logo from "../../assets/logo.png";
import UploadAnnotations from "../../components/UploadAnnotations";
import { SLAPSHOT_SUPPORT_URL } from "../../constants/env";
import {
  SLAPSHOT_HOME_URL,
  SLAPSHOT_LOGIN_URL,
  SLAPSHOT_PROFILE_URL,
} from "../../constants/urls";
import {
  getUserPreferences,
  getUserProfilePicture,
  updateUserPreferences,
} from "../../services/UserService";
import { getFirstName, getInitials, getUser } from "../../utils/helper";

interface UserContextType {
  preferences: Preferences | null;
  setPreferences: (value: Preferences | null) => void;
}

export const UserContext = createContext<UserContextType | null>(null);

const SlapshotMainLayout = () => {
  const navigate = useNavigate();
  const user = getUser();
  const [userProfilePicture, setUserProfilePicture] = useState<string | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showInferenceNotification, setShowInferenceNotification] =
    useState(false);
  const [showInferencedShotsNotification, setShowInferencedShotsNotification] =
    useState(false);
  const [showUploadAnnotation, setShowUploadAnnotation] = useState(false);
  const [inferenceModelName, setInferenceModelName] = useState("PETYR");
  const [unInferencedShots, setUnInferencedShots] = useState<{
    [key: string]: string;
  }>({});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [shots, setShots] = useState<string[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const [preferences, setPreferences] = useState<Preferences | null>(null);

  useEffect(() => {
    if (!user) {
      navigate(SLAPSHOT_LOGIN_URL);
    }
    if (!userProfilePicture) {
      getUserProfilePicture().then((res) => {
        setUserProfilePicture(res.profile_picture_url);
      });
    }
  }, [navigate, user, userProfilePicture]);

  const handleInferenceModelChange = (event: SelectChangeEvent) => {
    setInferenceModelName(event.target.value);
  };

  const userContextValue = useMemo(
    () => ({
      preferences,
      setPreferences: (value) => {
        setPreferences(value);
        updateUserPreferences(value).catch((e) => console.error(e));
      },
    }),
    [preferences],
  );

  useEffect(() => {
    getUserPreferences()
      .then((data) => {
        if (typeof data?.preferences === "string")
          setPreferences(JSON.parse(data?.preferences));
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <UserContext.Provider value={userContextValue}>
      <div className={cx("slapshotBackground", styles.mainContainer)}>
        <div className={styles.header}>
          <img
            src={Logo}
            alt="Slapshot"
            className={styles.slapshotLogo}
            onClick={() => {
              navigate(SLAPSHOT_HOME_URL);
            }}
          />
          <div className={styles.navButtons}>
            {/* <Button
              className={cx(
                styles.navButton,
                location.pathname === SLAPSHOT_HOME_URL &&
                  styles.navButtonActive,
              )}
              onClick={() => {
                navigate(SLAPSHOT_HOME_URL);
              }}
            >
              Projects
            </Button> */}
            <Button
              className={cx(
                styles.navButton,
                location.pathname === SLAPSHOT_HOME_URL &&
                  styles.navButtonActive,
              )}
              onClick={() => {
                navigate(SLAPSHOT_HOME_URL);
              }}
            >
              Workspaces
            </Button>
            <DownloadsButton />
            {/* <Button
            disabled={showUploadAnnotation}
            className={styles.navButton}
            onClick={() => {
              setShowUploadAnnotation(true);
            }}
          >
            Upload
          </Button>
          <Button
            className={cx(
              styles.navButton,
              location.pathname === SLAPSHOT_SETTINGS && styles.navButtonActive,
            )}
            onClick={() => {
              navigate(SLAPSHOT_SETTINGS);
            }}
          >
            Account Settings
          </Button>
          {projectId && (
            <Select
              className={cx(styles.navButton, styles.modelDropdown)}
              classes={{
                select: styles.modelDropdownSelect,
                iconOutlined: styles.modelDropdownIcon,
              }}
              labelId="inference-model"
              id="inference-model-name"
              value={inferenceModelName}
              label="Inference Model"
              onChange={handleInferenceModelChange}
            >
              <MenuItem value="PETYR">PETYR</MenuItem>
              <MenuItem value="TYWIN">TYWIN</MenuItem>
            </Select>
          )} */}
          </div>
          <div className={styles.headerUtils}>
            <ProjectSearchBar />
            <Notifications />
            <a
              className={styles.helpButton}
              target="_blank"
              rel="noreferrer"
              href={SLAPSHOT_SUPPORT_URL}
            >
              <HelpIcon />
              <span>Help</span>
            </a>
            <div
              className={styles.userDetails}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              {userProfilePicture ? (
                <img
                  className={styles.profilePicture}
                  src={userProfilePicture}
                  alt={userProfilePicture}
                />
              ) : (
                <Avatar
                  className={styles.avatarButton}
                  {...{ children: getInitials() }}
                  sx={{ bgcolor: "red", width: 14, height: 14 }}
                />
              )}
              <p>Hi {getFirstName()}</p>
              <button type="button" className={styles.dropdownButton}>
                <DropdownIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <Outlet />
        </div>
        <UploadAnnotations
          open={showUploadAnnotation}
          onClose={() => {
            setShowUploadAnnotation(false);
          }}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#1E1F23",
              borderRadius: "20px",
              border: "1px solid #334155",
              width: "260px",
              boxShadow: "0px 8px 10px -6px #0000001A",
              mt: 2.5,
            },
          }}
        >
          <div className={styles.menuDetails}>
            <h3>{getFirstName()}</h3>
            {/* TODO: fetch role from db */}
            <p>Admin</p>
          </div>
          <div className={styles.menuItems}>
            <MenuItem
              className={styles.menuButton}
              id="LayerRenameBtn"
              onClick={() => {
                navigate(SLAPSHOT_PROFILE_URL);
                setAnchorEl(null);
              }}
            >
              <EyeIcon />
              Profile Settings
            </MenuItem>
            <MenuItem
              className={cx(styles.menuButton, styles.logoutButton)}
              onClick={() => {
                localStorage.clear();
                navigate(SLAPSHOT_LOGIN_URL);
              }}
            >
              <LogoutIcon />
              Log out
            </MenuItem>
          </div>
        </Menu>
        <Dialog open={showInferenceNotification} fullWidth maxWidth="xs">
          <DialogContent>
            <Typography>Your inference is now running.</Typography>
            <Typography>
              We'll send you an email as soon as it is done!
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setShowInferenceNotification(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={!!error} fullWidth maxWidth="xs">
          <DialogTitle align="center">Error...</DialogTitle>
          <DialogContent>{error}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setError(null);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showInferencedShotsNotification} maxWidth="sm">
          <DialogContent>
            <div className={styles.successDialog}>
              <b>Inference Submitted Successfully</b>
            </div>
            {shots && (
              <Typography>Started inference for following shots:</Typography>
            )}
            {shots && (
              <ul>
                {shots.map((shot, index) => (
                  <li key={index}>
                    <b>{shot}</b>
                  </li>
                ))}
              </ul>
            )}
            {Object.keys(unInferencedShots).length > 0 && (
              <Typography>Inference for following shots Failed.</Typography>
            )}
            <ul>
              {Object.entries(unInferencedShots).map(([key, value]) => (
                <li key={key}>
                  <p>
                    <b>{key}</b> : {value}
                  </p>
                </li>
              ))}
            </ul>
            {Object.keys(unInferencedShots).length > 0 && (
              <Typography className={styles.reInferenceMessage}>
                Please try to inference for above shots again in some time.
              </Typography>
            )}
            <Typography>
              <b>We'll send you an email soon!</b>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowInferencedShotsNotification(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </UserContext.Provider>
  );
};

export default SlapshotMainLayout;
