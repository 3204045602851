import { getAPIService, postAPIService } from "./BaseServce";
import { Export } from "../models/Export";

export const getExports = async () => {
  const res = await getAPIService("/exports");
  return res.data.results as Export[];
};

export const createExport = async (
  projectId: number,
  layerIds: number[],
  exportModes: string[],
) => {
  const res = await postAPIService(`/exports`, {
    project_id: projectId,
    layer_ids: layerIds,
    export_modes: exportModes,
  });
  return res.data as Export[];
};

export const setLastDownload = async (exportId: number) => {
  const res = await postAPIService("/export/last-download", {
    export_id: exportId,
  });
  return res.data as { url: string | null };
};
