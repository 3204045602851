import React from "react";
import { Outlet } from "react-router-dom";

import CoverImage from "assets/auth_cover.jpeg";
import Logo from "assets/logo.png";

import styles from "./SlapshotAuthLayout.module.scss";

const SlapshotAuthLayout = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.imageContainer}>
        <img src={CoverImage} alt="Cover" className={styles.coverImage} />
      </div>
      <div className={styles.contentContainer}>
        <img src={Logo} alt="Slapshot" className={styles.logo} />
        <Outlet />
      </div>
    </div>
  );
};

export default SlapshotAuthLayout;
