import {
  Box,
  Dialog,
  Button,
  LinearProgress,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import axios, { CancelTokenSource } from "axios";
import cx from "classnames";
import { useFormik, FormikProvider, Field } from "formik";
import { Dictionary } from "lodash";
import PQueue from "p-queue";
import React, { SetStateAction, useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as RenameIcon } from "assets/pencil-icon.svg";
import { ReactComponent as UploadIcon } from "assets/upload-icon.svg";
import { ReactComponent as UploadShotIcon } from "assets/upload-shot.svg";
import { ReactComponent as XMark } from "assets/x-mark.svg";
import Guide from "components/Guide";
import { ShotCompact } from "models/Shot";

import styles from "./Shots.module.scss";
import { IntroGuides, STATUSES, ViewModes } from "../../constants/constants";
import { FILE_CHUNK_SIZE, MAX_REQUESTS_COUNT } from "../../constants/env";
import { LayerCompact } from "../../models/Layer";
import { Project } from "../../models/Project";
import { getLayers } from "../../services/LayerService";
import {
  getSignedUrlByProject,
  completeMultipartUpload,
  abortMultipartUpload,
} from "../../services/S3Service";
import { createShot, deleteShot, renameShot } from "../../services/ShotService";
import { formatDate } from "../../utils/helper";
import { InputField } from "../InputField";

const MAX_ALLOWED_FILES = 10;

export interface UploadInfo {
  [name: string]: {
    is_uploaded: boolean;
    total: number;
    loaded: number;
    upload_id?: string;
    sources: CancelTokenSource[];
  };
}

interface ShotsProps {
  shots: Dictionary<ShotCompact[]>;
  setShots: React.Dispatch<SetStateAction<Dictionary<ShotCompact[]>>>;
  shotsViewMode: ViewModes;
  project: Project;
  guides: string[];
  noMoreTips: (tip?: string) => void;
}

const Shots: React.FC<ShotsProps> = ({
  shots,
  setShots,
  shotsViewMode,
  project,
  guides,
  noMoreTips,
}) => {
  const { shotId, projectId } = useParams();
  const navigate = useNavigate();

  const [files, setFiles] = useState<File[]>([]);
  const [uploadInfo, setUploadInfo] = useState<UploadInfo>({});
  const [uploadStartTime, setUploadStartTime] = useState<Date>();
  const [signedUrlCancelTokenSource, setSignedUrlCancelTokenSource] = useState<
    Record<string, CancelTokenSource>
  >({});

  const [menuShot, setMenuShot] = useState<null | {
    id: number;
    name: string;
    created: Date;
  }>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRenameShotModal, setShowRenameShotModal] = useState(false);

  const renameShotFormik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      menuShot &&
        renameShot(menuShot.id, values.name)
          .then((res) => {
            setShots((prevShots) => {
              const date = formatDate(menuShot.created);
              const updatedShots = { ...prevShots };
              updatedShots[date] = updatedShots[date].map((s) => {
                if (s.id === res.id) {
                  return res;
                }
                return s;
              });
              return updatedShots;
            });
          })
          .finally(() => {
            setShowRenameShotModal(false);
          });
    },
  });

  const filesQueue = useRef(
    new PQueue({ concurrency: MAX_REQUESTS_COUNT }),
  ).current;

  const [hoveredShotId, setHoveredShotId] = useState<number>();

  const getStatusClassName = (status: string) => {
    switch (status) {
      case "IN_PROGRESS":
        return styles.inferencing;
      case "COMPLETED":
        return styles.statusCompleted;
      case "FAILED":
        return styles.statusFailed;
    }
    return "";
  };

  const startUpload = async (filesToUpload: File[]) => {
    return new Promise(async (onComplete, onFailed) => {
      setFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
      const newFiles = filesToUpload.filter(
        (file) => !files.some((f) => f.name === file.name),
      );
      const newUploadInfo: UploadInfo = {};
      newFiles.forEach((file) => {
        const shotName = file.name.substring(0, file.name.lastIndexOf("."));
        newUploadInfo[shotName] = {
          is_uploaded: false,
          total: file.size,
          loaded: 0,
          sources: [],
        };
      });
      setUploadInfo((prevInfo) => ({ ...prevInfo, ...newUploadInfo }));
      setUploadStartTime(new Date());

      const partsConcurrency =
        newFiles.length > MAX_REQUESTS_COUNT
          ? 1
          : MAX_REQUESTS_COUNT - newFiles.length + 1;

      newFiles.forEach((file) => {
        const shotName = file.name.substring(0, file.name.lastIndexOf("."));
        filesQueue.add(async () => {
          const cancelTokenSource = axios.CancelToken.source();
          setSignedUrlCancelTokenSource((prev) => ({
            ...prev,
            [shotName]: cancelTokenSource,
          }));
          const signedUrls =
            projectId && (await getSignedUrlByProject(projectId, file));
          if (!signedUrls) {
            return;
          }

          /* eslint-disable camelcase */
          const { urls, key, upload_id } = signedUrls;
          const partsQueue = new PQueue({ concurrency: partsConcurrency });
          // To store progress of file parts
          const uploaded: { [index: number]: number } = {};
          const parts: { eTag: string; partNumber: number }[] = [];

          let uploadAborted = false;
          await Promise.all(
            urls.map((url, index) =>
              partsQueue.add(async () => {
                const start = index * FILE_CHUNK_SIZE;
                const end = (index + 1) * FILE_CHUNK_SIZE;
                const blob =
                  index < urls.length
                    ? file.slice(start, end)
                    : file.slice(start);
                const tokenSource = axios.CancelToken.source();

                setUploadInfo((prevInfo) => ({
                  ...prevInfo,
                  [shotName]: {
                    ...prevInfo[shotName],
                    sources: [
                      ...(prevInfo[shotName]?.sources || []),
                      tokenSource,
                    ],
                  },
                }));

                const res = await axios
                  .put(url, blob, {
                    headers: { "Content-Type": file.type },
                    withCredentials: true,
                    cancelToken: tokenSource.token,
                    onUploadProgress: ({ total, loaded }) => {
                      uploaded[index] = loaded;
                      // Updating upload progress.
                      setUploadInfo((prevInfo) => ({
                        ...prevInfo,
                        [shotName]: {
                          ...prevInfo[shotName],
                          loaded: Object.values(uploaded).reduce(
                            (t, v) => t + v,
                            0,
                          ),
                        },
                      }));
                    },
                  })
                  .catch(async (err) => {
                    if (!uploadAborted) {
                      uploadAborted = true;
                      partsQueue.clear();
                      await abortMultipartUpload(upload_id, key);
                    }
                  });
                if (res) {
                  parts.push({ eTag: res.headers.etag, partNumber: index + 1 });
                  if (parts.length === urls.length) {
                    completeMultipartUpload(upload_id, key, parts).then((r) => {
                      setUploadInfo((prevInfo) => {
                        const updatedInfo = {
                          ...prevInfo,
                          [shotName]: {
                            ...prevInfo[shotName],
                            is_uploaded: true,
                          },
                        };
                        projectId &&
                          createShot(+projectId, file.name, r.Key).then(
                            (resp) => {
                              const date = formatDate(resp.created);
                              setShots((prevShots) => {
                                const updatedShots = { ...prevShots };
                                updatedShots[date] = updatedShots[date].map(
                                  (s) => {
                                    if (s.id === -1 && s.name === resp.name) {
                                      return resp;
                                    }
                                    return s;
                                  },
                                );
                                return updatedShots;
                              });
                              setFiles((fs) =>
                                fs.filter((f) => f.name !== file.name),
                              );
                            },
                          );
                        if (
                          Object.values(updatedInfo).length > 0 &&
                          Object.values(updatedInfo).every(
                            (v) => v.is_uploaded === true,
                          )
                        ) {
                          onComplete(updatedInfo);
                        }
                        return updatedInfo;
                      });
                    });
                  }
                }
              }),
            ),
          );
        });
      });
    });
  };

  const cancelOngoingUpload = async (name: string) => {
    const fileInfo = uploadInfo[name];
    if (fileInfo) {
      if (!fileInfo.is_uploaded && fileInfo.sources.length) {
        fileInfo.sources.forEach((source) => source.cancel("Cancelled"));
      }
      const signedUrlSource = signedUrlCancelTokenSource[name];
      if (signedUrlSource) {
        signedUrlSource.cancel("Cancelled");
      }
    }
    setUploadInfo((prevInfo) => {
      const { [name]: removed, ...rest } = prevInfo;
      return rest;
    });
    setSignedUrlCancelTokenSource((prevSource) => {
      const { [name]: removed, ...rest } = prevSource;
      return rest;
    });
    setFiles((fs) => {
      return fs.filter(
        (f) => f.name.substring(0, f.name.lastIndexOf(".")) !== name,
      );
    });
    setShots((prevShots) => {
      const date = formatDate(new Date());
      const updatedShots = { ...prevShots };
      if (updatedShots[date]) {
        updatedShots[date] = updatedShots[date].filter((s) => s.name !== name);
        if (updatedShots[date].length === 0) {
          delete updatedShots[date];
        }
      }
      return updatedShots;
    });
  };

  const isUploading = (id: number, shotName: string) => {
    return (
      id === -1 &&
      files.some((file) => {
        const nameWithoutExtension = file.name.substring(
          0,
          file.name.lastIndexOf("."),
        );
        return nameWithoutExtension === shotName;
      })
    );
  };

  const isClickable = (s: ShotCompact) => {
    return (
      !isUploading(s.id, s.name) &&
      s.shot_preview_image_url &&
      s.preprocessing_stage === "COMPLETED"
    );
  };

  const showSelectShotGuide = () => {
    return !!Object.values(shots)
      .flat()
      .find((shot) => isClickable(shot));
  };

  return (
    <Guide
      id={
        !Object.keys(shots).length
          ? IntroGuides.UploadShot
          : IntroGuides.SelectShot
      }
      guides={guides}
      open={showSelectShotGuide() || !Object.keys(shots).length}
      title={
        !Object.keys(shots).length
          ? "Add files from here"
          : "Select shot from here"
      }
      description={
        !Object.keys(shots).length
          ? "Either drag and drop files or click on upload shots button."
          : "Click on a shot to view in the player."
      }
      placement="right"
      noMoreTips={noMoreTips}
    >
      <div>
        <Dropzone
          accept={{
            "video/mp4": [".mp4"],
            "video/quicktime": [".mov"],
          }}
          maxFiles={MAX_ALLOWED_FILES}
          noClick
          onDrop={async (acceptedFiles) => {
            noMoreTips(IntroGuides.UploadShot);

            acceptedFiles.forEach((file) => {
              const date = formatDate(new Date());
              const shot: ShotCompact = {
                id: -1,
                name: file.name.substring(0, file.name.lastIndexOf(".")),
                first_frame_number: 0,
                frame_count: -1,
                latest_inference_status: "",
                created: new Date(),
                shot_preview_image_url: "",
                show_export_button: false,
                preprocessing_stage: "PROCESSING",
                fps: 0,
                start_time: 0,
              };

              setShots((prevShots) => {
                let updatedShots = { ...prevShots };
                if (updatedShots[date]) {
                  updatedShots[date].unshift(shot);
                } else {
                  updatedShots = {
                    [date]: [shot],
                    ...updatedShots,
                  };
                }
                return updatedShots;
              });
            });
            projectId && startUpload(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps, open, isDragActive }) => {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive && (
                  <div className={styles.mainContainer}>
                    <div className={styles.largeIconContainer}>
                      <UploadShotIcon />
                    </div>
                  </div>
                )}
                {!isDragActive && Object.keys(shots).length > 0 ? (
                  <>
                    <div className={styles.shots}>
                      {Object.keys(shots).map((createdDate) => (
                        <div key={createdDate} className={styles.shotsByDate}>
                          <div className={styles.dateContainer}>
                            <div className={styles.date}>{createdDate}</div>
                          </div>
                          {shots[createdDate].map((s, index) => (
                            <div
                              key={index}
                              className={cx(
                                styles.shotContainer,
                                getStatusClassName(s.latest_inference_status),
                                shotId &&
                                  +shotId === s.id &&
                                  styles.selectedShot,
                                isClickable(s) && styles.clickableShot,
                                shotId &&
                                  +shotId === s.id &&
                                  s.latest_inference_status ===
                                    STATUSES.IN_PROGRESS &&
                                  styles.selectedShotInferencing,
                              )}
                              onMouseEnter={() => {
                                setHoveredShotId(s.id);
                              }}
                              onMouseLeave={() => {
                                setHoveredShotId(undefined);
                              }}
                              onClick={() => {
                                if (isClickable(s)) {
                                  noMoreTips(IntroGuides.SelectShot);
                                  navigate(
                                    `/projects/${project.id}/shots/${s.id}`,
                                  );
                                }
                              }}
                              onContextMenu={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                                setMenuShot({
                                  id: s.id,
                                  name: s.name,
                                  created: s.created,
                                });
                                renameShotFormik.setFieldValue("name", s.name);
                              }}
                            >
                              {shotsViewMode === ViewModes.Preview && (
                                <>
                                  <div className={styles.shotThumbnail}>
                                    {isUploading(s.id, s.name) &&
                                      (hoveredShotId !== s.id ? (
                                        <div
                                          className={
                                            styles.shotProcessingContainer
                                          }
                                        >
                                          <p>Uploading...</p>
                                          <Box
                                            sx={{
                                              position: "relative",
                                              top: "20px",
                                              width: "calc(100% - 10px)",
                                              mt: 2,
                                              borderRadius: "5px",
                                              border: "1px solid #AEAEAE",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                position: "absolute",
                                                top: "-25px",
                                                left: `${Math.round(
                                                  (uploadInfo[s.name].loaded /
                                                    uploadInfo[s.name].total) *
                                                    100,
                                                )}%`,
                                                transform: "translateX(-50%)",
                                              }}
                                            >
                                              <Tooltip
                                                title={`${Math.round(
                                                  (uploadInfo[s.name].loaded /
                                                    uploadInfo[s.name].total) *
                                                    100,
                                                )}%`}
                                                arrow
                                                placement="top"
                                              >
                                                <Typography
                                                  variant="body2"
                                                  color="text.primary"
                                                  sx={{
                                                    background: "black",
                                                    padding: "2px 5px",
                                                    borderRadius: "4px",
                                                  }}
                                                >
                                                  {`${Math.round(
                                                    (uploadInfo[s.name].loaded /
                                                      uploadInfo[s.name]
                                                        .total) *
                                                      100,
                                                  )}%`}
                                                </Typography>
                                              </Tooltip>
                                            </Box>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (uploadInfo[s.name].loaded /
                                                  uploadInfo[s.name].total) *
                                                  100,
                                              )}
                                              sx={{
                                                height: "4px",
                                                borderRadius: "5px",
                                                "& .MuiLinearProgress-bar": {
                                                  backgroundColor: "#AEAEAE",
                                                  border: "1px solid #AEAEAE",
                                                },
                                                backgroundColor: "transparent",
                                              }}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.shotProcessingContainer
                                          }
                                        >
                                          <button
                                            type="button"
                                            className={
                                              styles.cancelUploadButton
                                            }
                                            onClick={() => {
                                              cancelOngoingUpload(s.name);
                                            }}
                                          >
                                            Cancel Upload
                                          </button>
                                        </div>
                                      ))}
                                    {!isUploading(s.id, s.name) &&
                                      !s.shot_preview_image_url && (
                                        <p>Fetching thumbnail...</p>
                                      )}
                                    {!isUploading(s.id, s.name) &&
                                      s.preprocessing_stage ===
                                        "GENERATING_H264_PREVIEW" &&
                                      s.shot_preview_image_url && (
                                        <div
                                          className={
                                            styles.shotProcessingContainer
                                          }
                                        >
                                          <p>Processing...</p>
                                          <div className={styles.loader}>
                                            <div className={styles.pipe} />
                                            <div className={styles.dashes} />
                                          </div>
                                        </div>
                                      )}
                                    {!isUploading(s.id, s.name) &&
                                      s.preprocessing_stage ===
                                        "EXTRACTING_FRAMES" &&
                                      s.shot_preview_image_url && (
                                        <div
                                          className={
                                            styles.shotProcessingContainer
                                          }
                                        >
                                          <p>Finalizing...</p>
                                          <div className={styles.loader}>
                                            <div className={styles.pipe} />
                                            <div className={styles.dashes} />
                                          </div>
                                        </div>
                                      )}
                                    {!isUploading(s.id, s.name) &&
                                      s.preprocessing_stage === "FAILED" && (
                                        <div
                                          className={
                                            styles.shotProcessingContainer
                                          }
                                        >
                                          <p>Processing Failed</p>
                                        </div>
                                      )}
                                    {s.shot_preview_image_url && (
                                      <img
                                        alt={s.shot_preview_image_url}
                                        className={styles.thumbnailImage}
                                        src={s.shot_preview_image_url}
                                      />
                                    )}
                                  </div>
                                  <div className={styles.shotDetails}>
                                    <div className={styles.name}>{s.name}</div>
                                  </div>
                                </>
                              )}
                              {shotsViewMode === ViewModes.List && (
                                <div className={styles.listShotContainer}>
                                  <div className={styles.listShotThumbnail}>
                                    {s.shot_preview_image_url && (
                                      <img
                                        src={s.shot_preview_image_url}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className={styles.listShotDetails}>
                                    <h5 className={styles.name}>{s.name}</h5>
                                    {isUploading(s.id, s.name) &&
                                      (hoveredShotId !== s.id ? (
                                        <div
                                          className={
                                            styles.listShotProcessingContainer
                                          }
                                        >
                                          <p>Uploading...</p>
                                          <p>
                                            {Math.round(
                                              (uploadInfo[s.name].loaded /
                                                uploadInfo[s.name].total) *
                                                100,
                                            )}
                                            %
                                          </p>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.cancelUploadListContainer
                                          }
                                        >
                                          <button
                                            type="button"
                                            className={
                                              styles.cancelUploadListButton
                                            }
                                            onClick={() => {
                                              cancelOngoingUpload(s.name);
                                            }}
                                          >
                                            Cancel Upload
                                          </button>
                                        </div>
                                      ))}
                                    {!isUploading(s.id, s.name) &&
                                      !s.shot_preview_image_url && (
                                        <div
                                          className={
                                            styles.listShotProcessingContainer
                                          }
                                        >
                                          <p>Fetching thumbnail...</p>
                                        </div>
                                      )}
                                    {!isUploading(s.id, s.name) &&
                                      s.preprocessing_stage ===
                                        "GENERATING_H264_PREVIEW" &&
                                      s.shot_preview_image_url && (
                                        <div
                                          className={
                                            styles.listShotProcessingContainer
                                          }
                                        >
                                          <p>Processing...</p>
                                          <div className={styles.listLoader}>
                                            <div className={styles.listPipe} />
                                            <div
                                              className={styles.listDashes}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    {!isUploading(s.id, s.name) &&
                                      s.preprocessing_stage ===
                                        "EXTRACTING_FRAMES" &&
                                      s.shot_preview_image_url && (
                                        <div
                                          className={
                                            styles.listShotProcessingContainer
                                          }
                                        >
                                          <p>Finalizing...</p>
                                          <div className={styles.listLoader}>
                                            <div className={styles.listPipe} />
                                            <div
                                              className={styles.listDashes}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    {!isUploading(s.id, s.name) &&
                                      s.preprocessing_stage === "FAILED" && (
                                        <div
                                          className={
                                            styles.listShotProcessingContainer
                                          }
                                        >
                                          <p>Processing Failed</p>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className={styles.shotsFooter}>
                      <button
                        type="button"
                        className={cx(styles.footerButton, styles.button)}
                        style={{ width: "100%" }}
                        onClick={open}
                      >
                        <UploadIcon style={{ marginRight: "4px" }} />
                        Upload shots
                      </button>
                    </div>
                  </>
                ) : (
                  <div className={styles.mainContainer}>
                    <div className={styles.iconContainer}>
                      <UploadShotIcon />
                    </div>
                    <h3>Drag and drop video files here</h3>
                    <button
                      type="button"
                      className={cx(styles.footerButton, styles.button)}
                      onClick={open}
                    >
                      + Upload shots
                    </button>
                    <div className={styles.description}>
                      <p>mp4 or mov files supported</p>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setMenuShot(null);
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#111113",
              borderRadius: "7px",
              border: "1px solid #CCCCDC1F",
              padding: "8px 6px",
              width: "184px",
              boxShadow: "0px 8px 24px 0px #010409",
            },
          }}
        >
          {menuShot && !isUploading(menuShot.id, menuShot.name) && (
            <>
              <MenuItem
                className={styles.menuItem}
                onClick={() => {
                  setAnchorEl(null);
                  setShowRenameShotModal(true);
                }}
              >
                <RenameIcon style={{ marginRight: "8px" }} />
                Rename
              </MenuItem>
              <MenuItem
                className={styles.menuItem}
                onClick={() => {
                  setAnchorEl(null);
                  setShowConfirmationModal(true);
                }}
              >
                <DeleteIcon style={{ marginRight: "8px" }} />
                Delete
              </MenuItem>
            </>
          )}
        </Menu>
        <Dialog
          open={showRenameShotModal}
          PaperProps={{
            className: "dialog",
          }}
        >
          <FormikProvider value={renameShotFormik}>
            <form
              className="modalContainer"
              onSubmit={renameShotFormik.handleSubmit}
            >
              <div className="modalHeader">
                <h3 className="modalTitle">Rename Shot</h3>
                <button
                  onClick={() => {
                    setShowRenameShotModal(false);
                  }}
                  type="button"
                  className="closeButton"
                >
                  <XMark />
                </button>
              </div>
              <div className="modalContent">
                <p className={styles.modalDesc}>Edit shot name</p>
                <Field
                  name="name"
                  component={InputField}
                  placeholder="Mission Impossible"
                  label="Shot name"
                />
              </div>
              <div className="modalActions">
                <Button type="submit" className="slapShotButton">
                  Update
                </Button>
              </div>
            </form>
          </FormikProvider>
        </Dialog>
        <Dialog
          open={showConfirmationModal}
          PaperProps={{
            className: "dialog",
          }}
        >
          <div className="modalContainer">
            <div className="modalHeader">
              <h3 className="modalTitle">Delete Shot</h3>
              <button
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
                type="button"
                className="closeButton"
              >
                <XMark />
              </button>
            </div>
            <div className={styles.confirmationText}>
              <p>
                Are you sure you want to delete this shot&nbsp;
                <b>{menuShot && menuShot.name}</b>
              </p>
            </div>
            <div className="modalActions">
              <Button
                type="button"
                className={styles.noBackgroundButton}
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className={styles.outlineButtonRed}
                onClick={() => {
                  menuShot &&
                    deleteShot(menuShot.id).then(() => {
                      setShots((prevShots) => {
                        const date = formatDate(menuShot.created);
                        const updatedShots = { ...prevShots };
                        if (updatedShots[date]) {
                          updatedShots[date] = updatedShots[date].filter(
                            (shot) => shot.id !== menuShot.id,
                          );
                          if (updatedShots[date].length === 0) {
                            delete updatedShots[date];
                          }
                        }
                        return updatedShots;
                      });
                    });
                  setShowConfirmationModal(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </Guide>
  );
};

export default Shots;
