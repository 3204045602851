import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import PlayButton from "components/VideoPlayer/PlayButton";
import VideoSlider from "components/VideoPlayer/Slider";
import "polyfill";
import { DEFAULT_FPS } from "constants/constants";
import { hexToRGB } from "utils/helper";

import styles from "./VideoPlayer.module.scss";

interface VideoPlayerProps {
  videoURL: string;
  totalFrames: number;
  firstFrame: number;
  onPlay?: () => void;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  fps: number;
  startTime: number;
  color?: string;
}

export interface VideoPlayerRefAttributes {
  pause: () => void;
  seekToFrame: (frame: number) => void;
  onPlay: () => void;
  setImageDatas: (datas: ImageBitmap[]) => void;
  setWidthHeight: (wh: [number, number]) => void;
  imageDatas: ImageBitmap[];
  canvasRef: React.RefObject<HTMLCanvasElement>;
  videoRef: React.RefObject<HTMLVideoElement>;
  currentFrame: number;
}

const VideoPlayer = forwardRef<VideoPlayerRefAttributes, VideoPlayerProps>(
  (
    {
      videoURL,
      totalFrames,
      firstFrame,
      onPlay,
      setIsLoading,
      fps,
      startTime,
      color,
    },
    ref,
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentFrame, setCurrentFrame] = useState(0);
    const [markStyles, setMarkStyles] = useState({});
    const [widthHeight, setWidthHeight] = useState<[number, number]>([0, 0]);
    const [imageDatas, setImageDatas] = useState<ImageBitmap[]>([]);
    const [marks, setMarks] = useState<{ value: number; label: string }[]>([]);
    const [showControls, setShowControls] = useState(false);
    const frameCallbackRef = useRef<number[]>([]);

    useImperativeHandle(ref, () => ({
      pause() {
        pause();
      },
      seekToFrame(frame) {
        seekToFrame(frame);
      },
      onPlay() {
        if (onPlay) onPlay();
        else handleOnPlay();
      },
      setImageDatas(datas) {
        setImageDatas([...datas]);
        render(datas);
      },
      setWidthHeight(wh) {
        setWidthHeight(wh);
      },
      videoRef,
      canvasRef,
      currentFrame,
      imageDatas,
    }));

    const _getFps = useCallback(() => {
      if (fps) {
        return fps > 999 ? DEFAULT_FPS : fps;
      }
      if (!videoRef.current?.duration) {
        return DEFAULT_FPS;
      }
      const { duration } = videoRef.current;
      return Math.round(totalFrames / duration);
    }, [fps, totalFrames]);

    const pause = () => {
      if (videoRef.current) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    };

    const play = () => {
      if (videoRef.current) {
        setIsPlaying(true);
        videoRef.current.play();
      }
    };

    const sliderChange = debounce((value) => {
      seekToFrame(+value);
    }, 500);

    const seekToFrame = useCallback(
      (frame: number) => {
        pause();
        setCurrentFrame(frame);
        const eVideo = videoRef.current;
        if (eVideo && eVideo.duration && totalFrames) {
          eVideo.currentTime = frame / _getFps() + 0.0001;
        }
      },
      [_getFps, totalFrames],
    );

    const handleKeyDown = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if (target.tagName === "INPUT") {
        return;
      }
      switch (e.key) {
        case "ArrowRight":
          pause();
          seekToFrame(Math.min(currentFrame + 1, totalFrames - 1));
          break;
        case "ArrowLeft":
          pause();
          seekToFrame(Math.max(currentFrame - 1, 0));
          break;
        case "k":
        case " ":
          if (isPlaying) {
            pause();
          } else {
            play();
          }
      }
    };

    const render = useCallback(
      (datas: ImageBitmap[], cf = currentFrame) => {
        const canvas = canvasRef.current as HTMLCanvasElement;
        if (!canvas) {
          return;
        }
        const ctx = canvas.getContext("2d");
        const sImgCanvasTemp = document.createElement("canvas");
        const mImgCanvasTemp = document.createElement("canvas");
        const sCtxTemp = sImgCanvasTemp.getContext("2d", {
          willReadFrequently: true,
        });
        const mCtxTemp = mImgCanvasTemp.getContext("2d", {
          willReadFrequently: true,
        });
        if (
          !ctx ||
          !sCtxTemp ||
          !mCtxTemp ||
          !videoRef.current ||
          !videoRef.current.videoWidth
        ) {
          return;
        }
        const w = widthHeight[0] || videoRef.current.videoWidth;
        const h = widthHeight[1] || videoRef.current.videoHeight;
        sImgCanvasTemp.width = w;
        sImgCanvasTemp.height = h;
        mImgCanvasTemp.width = w;
        mImgCanvasTemp.height = h;
        sCtxTemp.drawImage(videoRef.current, 0, 0, w, h);
        const sImgData = sCtxTemp.getImageData(0, 0, w, h);
        if (datas[cf]) {
          if (!videoRef.current) {
            return;
          }
          mCtxTemp.drawImage(datas[cf], 0, 0, w, h);
          const mImgData = mCtxTemp.getImageData(0, 0, w, h);
          const { r, g, b } = hexToRGB(color || "#FF0000");
          for (let y = 0; y < w * h * 4; y += 4) {
            if (
              mImgData.data[y] >= 128 &&
              mImgData.data[y + 1] === 0 &&
              mImgData.data[y + 2] === 0 &&
              mImgData.data[y + 3] === 255
            ) {
              sImgData.data[y] = sImgData.data[y] * 0.5 + r * 0.8;
              sImgData.data[y + 1] = sImgData.data[y + 1] * 0.5 + g * 0.8;
              sImgData.data[y + 2] = sImgData.data[y + 2] * 0.5 + b * 0.8;
              sImgData.data[y + 3] = 255;
            }
          }
        }
        ctx.putImageData(sImgData, 0, 0);
      },
      [color, currentFrame, widthHeight],
    );

    // Default behavior for onLoadedData event
    const handleOnPlay = useCallback(() => {
      if (!videoRef.current) {
        return;
      }
      const step = (now, metadata) => {
        if (!videoRef.current) {
          return;
        }
        const mediaTime =
          videoRef.current.currentTime < startTime ? 0 : metadata.mediaTime;
        const cf = Math.ceil(Math.floor(_getFps() * mediaTime * 10000) / 10000);
        setImageDatas((datas) => {
          render(datas, cf);
          return datas;
        });
        setCurrentFrame(cf);
        frameCallbackRef.current.forEach((id) =>
          videoRef.current?.cancelVideoFrameCallback(id),
        );
        frameCallbackRef.current = [
          videoRef.current?.requestVideoFrameCallback(step),
        ];
      };
      frameCallbackRef.current.forEach((id) =>
        videoRef.current?.cancelVideoFrameCallback(id),
      );
      frameCallbackRef.current = [
        videoRef.current?.requestVideoFrameCallback(step),
      ];
    }, [_getFps, render, startTime]);

    useEffect(() => {
      const segments = 20;
      const desiredMultiple = 5;
      const mrks: { value: number; label: string }[] = [];
      const splitValue =
        totalFrames < desiredMultiple
          ? 1
          : Math.max(
              1,
              Math.ceil(+(totalFrames / segments).toFixed() / desiredMultiple),
            ) * desiredMultiple;
      for (let i = 1; i <= totalFrames; i++) {
        mrks.push({
          value: i + firstFrame - 1,
          label: i % splitValue === 0 || i === 1 ? `${i + firstFrame - 1}` : "",
        });
      }
      setMarks(mrks);
      const style = {};
      for (let i = 1; i <= totalFrames / splitValue; i++) {
        style[`&[data-index="${i * splitValue - 1}"]`] = {
          backgroundColor: "#0B0B0F",
        };
      }
      setMarkStyles(style);
    }, [firstFrame, totalFrames]);

    return (
      <div className={styles.mainContainer}>
        <div className={styles.canvasContainer}>
          <canvas
            className={styles.previewImage}
            id="canvas"
            ref={canvasRef}
            width={widthHeight[0] || videoRef.current?.videoWidth}
            height={widthHeight[1] || videoRef.current?.videoHeight}
          />
          <video
            ref={videoRef}
            src={videoURL}
            muted
            crossOrigin="anonymous"
            loop
            preload="auto"
            onLoadedMetadata={() => {
              if (onPlay) onPlay();
              else handleOnPlay();
              if (setIsLoading) setIsLoading(false);
              setShowControls(true);
            }}
            style={{
              visibility: "hidden",
              width: "1px",
              height: "1px",
              position: "absolute",
            }}
            controls
          />
        </div>
        {showControls && (
          <div className={styles.playerControls}>
            <div className={styles.sliderPlayBtnCon}>
              <div className={styles.playBtn}>
                <PlayButton
                  onPause={pause}
                  onPlay={play}
                  disabled={false}
                  isPlaying={isPlaying}
                />
              </div>

              <div className={styles.sliderCon}>
                <VideoSlider
                  onChange={(e, value) => {
                    if (isPlaying) {
                      seekToFrame(+value - firstFrame);
                    } else {
                      sliderChange(+value - firstFrame);
                    }
                  }}
                  handleKeyDown={handleKeyDown}
                  totalFrames={totalFrames}
                  currentFrame={currentFrame + firstFrame}
                  firstFrame={firstFrame}
                  marks={marks}
                  markStyles={markStyles}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
VideoPlayer.displayName = "VideoPlayer";

export default VideoPlayer;
