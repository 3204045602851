import { Button } from "@mui/material";
import cx from "classnames";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as FolderHeartPurple } from "assets/folder-heart-purple.svg";
import { ReactComponent as Seperator } from "assets/line.svg";
import { SLAPSHOT_DOWNLOADS } from "constants/urls";

import styles from "./Downloads.module.scss";
import { Export } from "../../models/Export";
import { getExports, setLastDownload } from "../../services/ExportService";
import {
  ACTION_SUBSCRIBE_EXPORT_UPDATE,
  getRequestData,
  getSocket,
} from "../../services/WebSocketService";
import { getCreationTimeText, getUser } from "../../utils/helper";

interface ExportDetail extends Export, StatusDetails {}

const Downloads = () => {
  const user = getUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [exports, setExports] = useState<{ [id: number]: ExportDetail }>({});
  const [newDownloads, setNewDownloads] = useState(false);

  useEffect(() => {
    const socket = getSocket("export");
    if (!socket) {
      return () => {};
    }

    socket.onopen = (event) => {
      socket.send(getRequestData(ACTION_SUBSCRIBE_EXPORT_UPDATE));
    };

    socket.onmessage = (event) => {
      const e: Export = JSON.parse(event.data).data;
      setExports((v) => {
        v[e.id] = { ...e, ...getStatusDetails(e) };
        return { ...v };
      });
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    getExports()
      .then((results) => {
        const data = results.reduce((obj, item) => {
          obj[item.id] = { ...item, ...getStatusDetails(item) };
          return obj;
        }, {});

        setExports(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const newExport = Object.values(exports).findIndex(
      (value) => !value.is_downloaded && value.status === "COMPLETED",
    );
    setNewDownloads(newExport !== -1);
  }, [exports]);

  return (
    <div>
      <Button
        className={cx(
          styles.navButton,
          location.pathname === SLAPSHOT_DOWNLOADS && styles.navButtonActive,
        )}
        onClick={() => {
          navigate(SLAPSHOT_DOWNLOADS);
        }}
      >
        <div className={styles.DownloadsBtn}>
          <div>Exports</div>
          {newDownloads && (
            <div className={styles.redDot} style={{ marginLeft: 0 }} />
          )}
        </div>
      </Button>

      {location.pathname === SLAPSHOT_DOWNLOADS && (
        <div className={styles.DownloadsPage}>
          <div className={styles.header}>Exports</div>

          <div className={styles.exportsContainer}>
            {Object.values(exports)
              .sort((e, e2) => (e.created > e2.created ? -1 : 1))
              .map((e: ExportDetail) => {
                return (
                  <div className={styles.exportContainer} key={e.id}>
                    <div className={styles.exportContent}>
                      <FolderHeartPurple className={styles.projectIcon} />

                      <div className={styles.exportDetails}>
                        <p className={styles.projectName}>{e.shot_name}</p>

                        <p className={styles.annotations}>
                          {e.project.name}: &nbsp;
                          <span>{e.layers.join(", ")}</span>
                        </p>

                        <p className={styles.lastDownloadedAt}>
                          {`${getCreationTimeText(e.created, "Generated")}`}
                        </p>
                      </div>
                    </div>
                    <Button
                      disabled={e.disabled}
                      className={styles.downloadBtn}
                      onClick={() => {
                        handleDownload(e, setExports);
                      }}
                    >
                      <div className={styles.statusIconCon}>
                        <img
                          src={e.iconUrl}
                          alt=""
                          className={cx(e.style, styles.statusIcon)}
                        />
                      </div>

                      <Seperator className={styles.seperator} />

                      <div>{e.newStatus}</div>

                      {e.status === "COMPLETED" && !e.is_downloaded && (
                        <div className={styles.redDot} />
                      )}
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

interface StatusDetails {
  newStatus: string;
  iconUrl: string;
  style: string;
  disabled: boolean;
}

const getStatusDetails = (e: Export) => {
  let obj: StatusDetails = {
    newStatus: "Download",
    style: styles.statusDownload,
    disabled: false,
    iconUrl: "/download.svg",
  };

  switch (e.status) {
    case "FAILED":
      obj = {
        newStatus: "Failed",
        iconUrl: "/failed.gif",
        style: styles.statusFailed,
        disabled: true,
      };
      break;
    case "IN_PROGRESS":
      obj = {
        newStatus: "Processing...",
        style: styles.statusProcessing,
        disabled: true,
        iconUrl: "/processing.gif",
      };
      break;
  }
  return obj;
};

const handleDownload = async (
  e: ExportDetail,
  setExports: Dispatch<SetStateAction<{ [id: number]: ExportDetail }>>,
) => {
  const res = await setLastDownload(e.id);
  if (res?.url) {
    window.open(res.url, "_blank");
    e.is_downloaded = true;
  } else toast.info("Export cannot be downloaded");

  setExports((v) => {
    v[e.id] = { ...e };
    return { ...v };
  });
};

export default Downloads;
