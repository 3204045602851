import {
  getAPIService,
  postAPIService,
  deleteAPIService,
  putAPIService,
} from "./BaseServce";
import { Cutout, CutoutInProgress } from "../models/Cutout";

export const getCutouts = async (layerId: number) => {
  const res = await getAPIService("/cutouts", { layer_id: layerId });
  return res.data.results as Cutout[];
};

export const uploadCutouts = async (files: File[]) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("cutouts", new File([file], file.name));
  });
  const res = await postAPIService(`/upload-cutouts`, formData);
  return res.data;
};

export const getEditableCutout = async (layerId: number, frame: number) => {
  const res = await getAPIService("/cutout/edit", { layer_id: layerId, frame });
  return res.data as CutoutInProgress | null;
};

export const createCutout = async (
  frame: number,
  layerId: number,
  annotationId?: number,
) => {
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      layerId,
      frame,
      annotationId,
    }),
  );
  const res = await postAPIService(`/cutouts`, formData);
  return res.data as CutoutInProgress;
};

export const updateCutout = async (cutoutId: number, isUploading: boolean) => {
  const res = await putAPIService(`/cutouts/${cutoutId}`, {
    is_uploading: isUploading,
  });
  return res.data;
};

export const deleteCutout = async (cutoutId: number) => {
  const res = await deleteAPIService(`/cutouts/${cutoutId}`);
  return res.data;
};
