import { Avatar, Button, Dialog, Menu, MenuProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import cx from "classnames";
import React, { useState } from "react";

import { ReactComponent as PencilIcon } from "assets/pencil-icon.svg";
import { ReactComponent as XMark } from "assets/x-mark.svg";

import styles from "./InviteRow.module.scss";
import { Invite } from "../../models/Invite";
import { Workspace } from "../../models/Workspace";
import { revokeInvite } from "../../services/WorkspaceService";
import { getTextInitials } from "../../utils/helper";
import NotificationSnackbar from "../NotificationSnackbar";

interface InviteRowParams {
  invite: Invite;
  setWorkspace: (w: Workspace) => void;
}

const InviteRow: React.FC<InviteRowParams> = ({ invite, setWorkspace }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  return (
    <div key={invite.id} className={styles.userContainer}>
      <Avatar
        key={invite.id}
        className={styles.projectUserAvatar}
        {...{ children: getTextInitials(invite.email) }}
      />

      <div className={styles.userNameEmailContainer}>
        <p className={styles.userEmail}>{invite.email}</p>
      </div>
      <div className={styles.actions}>
        <Button
          onClick={() => {
            setShowConfirmationModal(true);
          }}
          className={cx(styles.actionMenuButton)}
        >
          Revoke Invite
        </Button>
      </div>
      <Dialog
        open={showConfirmationModal}
        PaperProps={{
          className: "dialog",
        }}
      >
        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="modalTitle">Revoke invite</h3>
            <button
              onClick={() => {
                setShowConfirmationModal(false);
              }}
              type="button"
              className="closeButton"
            >
              <XMark />
            </button>
          </div>
          <div className={styles.confirmationText}>
            <p>
              Are you sure you want revoke invite for <b>{invite.email}</b>?
            </p>
          </div>
          <div className="modalActions">
            <Button
              type="button"
              className={styles.noBackgroundButton}
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className={styles.outlineButtonRed}
              onClick={async () => {
                setShowConfirmationModal(false);
                await revokeInvite(invite.id).then((res) => {
                  setWorkspace(res);
                  setNotificationMessage(
                    `Invite for ${invite.email} has been revoked successfully.`,
                  );
                });
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>

      <NotificationSnackbar
        message={notificationMessage}
        onClose={() => setNotificationMessage("")}
      />
    </div>
  );
};

export default InviteRow;
