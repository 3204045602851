import { getAPIService, postAPIService } from "./BaseServce";

export const login = async (email: string, password: string) => {
  const res = await postAPIService("/auth/login", {
    email,
    password,
  });
  return res.data;
};

export const register = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  inviteCode?: string,
) => {
  const res = await postAPIService("/auth/registration", {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    invite_code: inviteCode,
  });
  return res.data;
};

export const forgotPassword = async (email: string) => {
  const res = await postAPIService("/auth/forgot-password", {
    email,
  });
  return res.data;
};

export const resetPassword = async (
  userIdentifier: string,
  token: string,
  password: string,
) => {
  const res = await postAPIService("/auth/reset-password", {
    user_identifier: userIdentifier,
    token,
    password,
  });
  return res.data;
};

export const validateInviteToken = async (token: string) => {
  const res = await getAPIService("/auth/validate-invite-token", {
    token,
  });
  return res.data;
};
