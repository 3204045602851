import { AutoFixHigh, ZoomIn } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  IconButton,
  Popover,
  Slider,
  Tooltip,
  Zoom,
} from "@mui/material";
import cx from "classnames";
import React, { useState, useEffect, useRef, useCallback } from "react";
import type { SVGProps } from "react";

import Guide from "components/Guide";
import { IntroGuides } from "constants/constants";
import { CutoutInProgress } from "models/Cutout";
import { hexToRGB } from "utils/helper";

import styles from "./EditMode.module.scss";
import KonvaCanvas, {
  KonvaCanvasRef,
  MODE_DRAW,
  MODE_ERASE,
  MODE_MAGIC,
  Region,
  TOOL_LINE,
  TOOL_PEN,
  TOOL_POLYGON,
} from "../KonvaCanvas/KonvaCanvas";

export const CiRedo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14 8h5V3m-.29 13.357a8 8 0 1 1-.19-8.991"
      />
    </svg>
  );
};
export const CiUndo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 8H5V3m.291 13.357a8 8 0 1 0 .188-8.991"
      />
    </svg>
  );
};

export const PhPolygon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M230.64 49.36a32 32 0 0 0-45.26 0a32 32 0 0 0-5.16 6.76L152 48.42a32 32 0 0 0-54.63-23.06a32.06 32.06 0 0 0-5.76 37.41L57.67 93.32a32.05 32.05 0 0 0-40.31 4.05a32 32 0 0 0 42.89 47.41l70 51.36a32 32 0 1 0 47.57-14.69l27.39-77.59q1.38.12 2.76.12a32 32 0 0 0 22.63-54.62Zm-122-12.69a16 16 0 1 1 0 22.64a16 16 0 0 1 .04-22.64Zm-80 94.65a16 16 0 0 1 0-22.64a16 16 0 1 1 0 22.64m142.65 88a16 16 0 0 1-22.63-22.63a16 16 0 1 1 22.63 22.63m-8.55-43.18a32 32 0 0 0-23 7.08l-70-51.36a32.17 32.17 0 0 0-1.34-26.65l33.95-30.55a32 32 0 0 0 45.47-10.81L176 71.56a32 32 0 0 0 14.12 27ZM219.3 83.3a16 16 0 1 1-22.6-22.62a16 16 0 0 1 22.63 22.63Z"
      />
    </svg>
  );
};

export const SolarPenBold = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114m9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13z"
      />
    </svg>
  );
};
export const UilLineAlt = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.71 3.29a1 1 0 0 0-1.42 0l-18 18a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l18-18a1 1 0 0 0 0-1.42"
      />
    </svg>
  );
};
export const PhEraserBold = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M216 204h-75l86.84-86.84a28 28 0 0 0 0-39.6l-41.41-41.37a28 28 0 0 0-39.6 0L28.19 154.82a28 28 0 0 0 0 39.6l30.06 30.07a12 12 0 0 0 8.49 3.51H216a12 12 0 0 0 0-24M163.8 53.16a4 4 0 0 1 5.66 0l41.38 41.38a4 4 0 0 1 0 5.65L160 151l-47-47ZM71.71 204l-26.55-26.55a4 4 0 0 1 0-5.65L96 121l47 47l-36 36Z"
      />
    </svg>
  );
};

export type Data = {
  width: number;
  height: number;
  file: File;
  img: HTMLImageElement;
};
export type Point = { x: number; y: number; label: number };
export type Mask = {
  data: Float32Array;
  width: number;
  height: number;
};

interface EditModeProps {
  data: Data;
  sourceImageData: string;
  isForReview: boolean;
  color: string;
  done: () => void;
  points: Point[];
  setPoints: (points: Point[]) => void;
  setMaskImage: (mask: Mask | null) => void;
  onHover: (points: Point[]) => void;
  maskImage: Mask | null;
  onClear: () => void;
  isLoadingMask: boolean;
  save: (maskImageData: string, coverImageData: string) => void;
  cutoutInProgress: CutoutInProgress | null;
  guides: string[];
  showExitGuide: boolean;
  noMoreTips: (tip?: string) => void;
  generateBaseMask: (maskImageURL: string) => void;
  baseMask: ImageData | null;
}

export interface EditModeRef {
  update: (
    maskInput: Uint8ClampedArray,
    format: "pixel" | "rgb",
    saveChanges: boolean,
  ) => void;

  selectMagicMode: () => void;
}

const OVERLAY_MODE = "Overlay";
const SOURCE_MODE = "Source";
const MATTE_MODE = "Matte";
const MAX_STROKE_WIDTH = 100;

const EditMode = React.forwardRef<EditModeRef, EditModeProps>(
  (
    {
      data,
      sourceImageData,
      isForReview,
      save,
      color,
      done,
      onHover,
      points,
      maskImage,
      baseMask,
      setPoints,
      onClear,
      generateBaseMask,
      setMaskImage,
      cutoutInProgress,
      guides,
      showExitGuide,
      noMoreTips,
      isLoadingMask,
    },
    ref,
  ) => {
    React.useImperativeHandle(ref, () => ({
      update(maskInput, format, saveChanges) {
        setUndoStack([]);
        setRedoStack([]);
        updateChanges(maskInput, format, saveChanges);
      },
      selectMagicMode() {
        selectMagicMode();
      },
    }));

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null,
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const maskRef = useRef<KonvaCanvasRef>(null);
    const overlayRef = useRef<KonvaCanvasRef>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [strokeWidth, setStrokeWidth] = useState(50);
    const [selectedTool, setSelectedTool] = useState(TOOL_PEN);
    const [selectedMode, setSelectedMode] = useState(MODE_MAGIC);
    const [selectedView, setSelectedView] = useState(OVERLAY_MODE);

    const [overlayModeBackground, setOverlayModeBackground] =
      useState<string>("");
    const [matteModeBackground, setMatteModeBackground] = useState<string>("");
    const [coverImageData, setCoverImageData] = useState<string>("");
    const [regions, setRegions] = useState<Region[]>([]);

    const [undoStack, setUndoStack] = useState<[string, string, string][]>([]);
    const [redoStack, setRedoStack] = useState<[string, string, string][]>([]);
    const MAX_STACK_SIZE = 5;

    const [clientWidthHeight, setClientWidthHeight] = useState([0, 0]);
    const [scale, setScale] = useState(1);
    const [zoomScale, setZoomScale] = useState(1);
    const [canPan, setCanPan] = useState(false);

    const changeView = useCallback(
      (newView: string) => {
        overlayRef.current?.resetZoom();
        maskRef.current?.resetZoom();
        switch (newView) {
          case SOURCE_MODE:
            if (selectedView === SOURCE_MODE) return;
            setSelectedView(SOURCE_MODE);
            break;
          case OVERLAY_MODE:
            if (selectedView === OVERLAY_MODE) return;
            setSelectedView(OVERLAY_MODE);
            break;
          case MATTE_MODE:
            if (selectedView === MATTE_MODE) return;
            setSelectedView(MATTE_MODE);
            break;
        }
      },
      [selectedView],
    );

    const increaseStrokeWidth = useCallback(() => {
      if (strokeWidth === MAX_STROKE_WIDTH) return;
      setStrokeWidth(strokeWidth + 1);
    }, [strokeWidth]);

    const decreaseStrokeWidth = useCallback(() => {
      if (strokeWidth === 1) return;
      setStrokeWidth(strokeWidth - 1);
    }, [strokeWidth]);

    const getCoordinates = useCallback(
      (point: { x: number; y: number }) => {
        return { x: point.x * scale, y: point.y * scale };
      },
      [scale],
    );

    const updateChanges = useCallback(
      (
        maskInput: Uint8ClampedArray,
        format: "pixel" | "rgb",
        saveChanges = false,
      ) => {
        const { width, height } = data;
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        const canvasMask = document.createElement("canvas");
        canvasMask.width = width;
        canvasMask.height = height;
        const ctxMask = canvasMask.getContext("2d");

        const canvasCover = document.createElement("canvas");
        canvasCover.width = width;
        canvasCover.height = height;
        const ctxCover = canvasCover.getContext("2d", {
          willReadFrequently: true,
        });

        if (!ctx || !ctxMask || !ctxCover) return;

        ctx.drawImage(data.img, 0, 0, width, height);
        const imageData = ctx.getImageData(0, 0, width, height);
        const maskData = ctxMask.getImageData(0, 0, width, height);

        ctxCover.fillStyle = color;
        ctxCover.fillRect(0, 0, width, height);
        const imageCoverData = ctxCover.getImageData(0, 0, width, height);
        const selection = {
          startX: width,
          EndX: 0,
          startY: height,
          EndY: 0,
        };

        const { r, g, b } = hexToRGB(color);
        for (let y = 0; y < width * height; y++) {
          const redIntensity =
            format === "pixel" ? maskInput[y] : maskInput[y * 4];
          if (redIntensity >= 128) {
            imageCoverData.data[4 * y] = imageData.data[4 * y];
            imageCoverData.data[4 * y + 1] = imageData.data[4 * y + 1];
            imageCoverData.data[4 * y + 2] = imageData.data[4 * y + 2];
            imageCoverData.data[4 * y + 3] = 255;

            const pixelX = y % width;
            const pixelY = Math.floor(y / width);

            selection.startX = Math.min(selection.startX, pixelX);
            selection.EndX = Math.max(selection.EndX, pixelX);
            selection.startY = Math.min(selection.startY, pixelY);
            selection.EndY = Math.max(selection.EndY, pixelY);

            imageData.data[4 * y] = imageData.data[4 * y] * 0.5 + r * 0.8;
            imageData.data[4 * y + 1] =
              imageData.data[4 * y + 1] * 0.5 + g * 0.8;
            imageData.data[4 * y + 2] =
              imageData.data[4 * y + 2] * 0.5 + b * 0.8;
            imageData.data[4 * y + 3] = 255;

            maskData.data[4 * y] = 255;
            maskData.data[4 * y + 1] = 0;
            maskData.data[4 * y + 2] = 0;
            maskData.data[4 * y + 3] = 255;
          } else {
            maskData.data[4 * y] = 0;
            maskData.data[4 * y + 1] = 0;
            maskData.data[4 * y + 2] = 0;
            maskData.data[4 * y + 3] = 255;
          }
        }

        ctx.putImageData(imageData, 0, 0);
        ctxMask.putImageData(maskData, 0, 0);

        if (points.length > 0) {
          ctx.globalAlpha = 0.9;
          ctx.shadowBlur = 20;
          ctx.shadowColor = "rgba(25, 25, 25, 0.8)";
          for (let i = 0; i < points.length; i++) {
            const point = points[i];
            ctx.beginPath();
            ctx.arc(point.x, point.y, 8, 0, 2 * Math.PI);
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            if (point.label === 1) {
              ctx.fillStyle = "rgba(101, 101, 236, 1)";
            } else {
              ctx.fillStyle = "rgba(255, 0, 0, 0.9)";
            }
            ctx.fill();
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(255, 255, 255, 1)";
            ctx.stroke();
            ctx.closePath();
          }
          ctx.shadowBlur = 0;
          ctx.shadowColor = "transparent";
        }

        ctxCover.putImageData(imageCoverData, 0, 0);
        const selectionData = ctxCover.getImageData(
          selection.startX,
          selection.startY,
          selection.EndX - selection.startX,
          selection.EndY - selection.startY,
        );
        canvasCover.width = selection.EndX - selection.startX;
        canvasCover.height = selection.EndY - selection.startY;
        ctxCover.putImageData(selectionData, 0, 0);

        const maskImageURL = canvasMask.toDataURL();
        const coverImageURL = canvasCover.toDataURL();

        let previousOverlay = "";
        let previousMask = "";
        let previousCover = "";

        setOverlayModeBackground((overlay) => {
          previousOverlay = overlay;
          return canvas.toDataURL();
        });
        setMatteModeBackground((matte) => {
          previousMask = matte;
          return maskImageURL;
        });
        setCoverImageData((cover) => {
          previousCover = cover;
          return coverImageURL;
        });
        if (saveChanges) {
          save(maskImageURL, coverImageURL);
          setUndoStack((v) => {
            const stack: [string, string, string][] = [
              ...v,
              [previousOverlay, previousMask, previousCover],
            ];
            return stack.length > MAX_STACK_SIZE
              ? stack.slice(-MAX_STACK_SIZE)
              : stack;
          });
          setRedoStack([]);
        }
      },
      [color, data, points, save],
    );

    const konvaUpdate = useCallback(() => {
      if (!maskRef.current) return;
      const { width, height } = data;

      const imgURL = maskRef.current.getDataURL();
      if (!imgURL) return;
      const image = new Image();

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        ctx?.drawImage(image, 0, 0, width, height);
        const imageData = ctx?.getImageData(0, 0, width, height);
        if (!imageData) return;

        updateChanges(imageData.data, "rgb", true);
        setRegions([]);
      };
      image.src = imgURL;
    }, [data, updateChanges]);

    const handleOnClear = () => {
      const maskInput = Array.from(
        { length: data.width * data.height },
        () => 0,
      );
      updateChanges(new Uint8ClampedArray(maskInput), "pixel", true);
      onClear();
    };

    const onMagicModeMouseMove = useCallback(
      (point: { x: number; y: number } | null) => {
        if (point) onHover([...points, { ...getCoordinates(point), label: 1 }]);
        else onHover([...points]);
      },
      [getCoordinates, onHover, points],
    );

    const onMagicModeClick = (point: { x: number; y: number }) => {
      if (points.length === 1 && !cutoutInProgress) return;
      save(matteModeBackground, coverImageData);
      setPoints([...points, { ...getCoordinates(point), label: 1 }]);
    };

    const calculateCanvasDimensions = (
      containerWidth,
      containerHeight,
      imageWidth,
      imageHeight,
    ) => {
      const imageAspectRatio = imageWidth / imageHeight;
      const containerAspectRatio = containerWidth / containerHeight;
      let fittedWidth;
      let fittedHeight;

      if (imageAspectRatio > containerAspectRatio) {
        fittedWidth = containerWidth;
        fittedHeight = (containerWidth / imageWidth) * imageHeight;
      } else {
        fittedHeight = containerHeight;
        fittedWidth = (containerHeight / imageHeight) * imageWidth;
      }

      return { width: fittedWidth, height: fittedHeight };
    };

    const resizeCanvas = useCallback(() => {
      if (containerRef.current) {
        const { width, height } = data;

        const dimensions = calculateCanvasDimensions(
          containerRef.current.clientWidth,
          window.innerHeight - 280,
          width,
          height,
        );
        setClientWidthHeight([dimensions.width, dimensions.height]);
        setScale(width / dimensions.width);
      }
    }, [data]);

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
          case "1":
            changeView(SOURCE_MODE);
            break;
          case "2":
            changeView(OVERLAY_MODE);
            break;
          case "3":
            changeView(MATTE_MODE);
            break;
          case "=":
          case "+":
            increaseStrokeWidth();
            break;
          case "-":
          case "_":
            decreaseStrokeWidth();
            break;
          case "Alt":
            setCanPan(true);
            break;
        }
      };

      const handleKeyUp = (e: KeyboardEvent) => {
        switch (e.key) {
          case "Alt":
            setCanPan(false);
            break;
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      };
    }, [changeView, decreaseStrokeWidth, increaseStrokeWidth]);

    useEffect(() => {
      window.addEventListener("resize", resizeCanvas);
      resizeCanvas();
      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }, [resizeCanvas]);

    useEffect(() => {
      if (selectedMode === MODE_MAGIC) {
        const maskInput: number[] = [];
        for (let y = 0; y < data.width * data.height; y++) {
          maskInput.push(
            (maskImage?.data[y] || 0) > 0 || (baseMask?.data[y * 4] || 0) >= 128
              ? 128
              : 0,
          );
        }
        updateChanges(new Uint8ClampedArray(maskInput), "pixel", false);
      }
    }, [baseMask, data, maskImage, selectedMode, updateChanges]);

    useEffect(() => {
      if (canPan && selectedMode === MODE_MAGIC) {
        onMagicModeMouseMove(null);
      }
    }, [canPan, onMagicModeMouseMove, selectedMode]);

    const showPaintOptions = () => {
      return [MATTE_MODE, OVERLAY_MODE].includes(selectedView);
    };

    const getCursorStyle = () => {
      if (selectedMode === MODE_MAGIC) {
        if (canPan) {
          return "grab";
        }
        return points.length === 1 && !cutoutInProgress ? "progress" : "auto";
      }

      return canPan ? "grab" : "none";
    };

    const selectMagicMode = useCallback(() => {
      setPoints([]);
      setMaskImage(null);
      setUndoStack([]);
      setRedoStack([]);
      setSelectedMode(MODE_MAGIC);
    }, [setMaskImage, setPoints]);

    return (
      <div className={cx(styles.container, isForReview && styles.isForReview)}>
        <div className={styles.actionButtons}>
          <div className={styles.actionStart}>
            <div className={styles.tools}>
              <Tooltip title="Magic Selector">
                <IconButton
                  disabled={isLoadingMask}
                  className={cx(
                    styles.toolButton,
                    styles.modeBtn,
                    selectedMode === MODE_MAGIC && styles.toolActive,
                  )}
                  onClick={() => {
                    generateBaseMask(matteModeBackground);
                    selectMagicMode();
                  }}
                >
                  <AutoFixHigh />
                </IconButton>
              </Tooltip>
              <Tooltip title="Draw">
                <IconButton
                  disabled={isLoadingMask}
                  className={cx(
                    styles.toolButton,
                    styles.modeBtn,
                    selectedMode === MODE_DRAW && styles.toolActive,
                  )}
                  onClick={() => setSelectedMode(MODE_DRAW)}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Erase">
                <IconButton
                  disabled={isLoadingMask}
                  className={cx(
                    styles.toolButton,
                    styles.modeBtn,
                    selectedMode === MODE_ERASE && styles.toolActive,
                  )}
                  onClick={() => setSelectedMode(MODE_ERASE)}
                >
                  <PhEraserBold />
                </IconButton>
              </Tooltip>
            </div>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "rgb(23, 23, 27)",
                  borderRadius: "10px",
                  border: "1px solid #CCCCDC1F",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={styles.toolPopover}>
                <Button
                  className={styles.toolPopoverButton}
                  onClick={() => {
                    setSelectedTool(TOOL_PEN);
                    handleClose();
                  }}
                >
                  <SolarPenBold /> Pen
                </Button>
                <Button
                  className={styles.toolPopoverButton}
                  onClick={() => {
                    setSelectedTool(TOOL_LINE);
                    handleClose();
                  }}
                >
                  <UilLineAlt /> Straight Line
                </Button>
                <Button
                  className={styles.toolPopoverButton}
                  onClick={() => {
                    setSelectedTool(TOOL_POLYGON);
                    handleClose();
                  }}
                >
                  <PhPolygon /> Polygon
                </Button>
              </div>
            </Popover>
            <div className={styles.tools}>
              <Tooltip title={selectedTool}>
                <IconButton className={styles.toolButton} onClick={handleClick}>
                  {selectedTool === TOOL_LINE && <UilLineAlt />}
                  {selectedTool === TOOL_PEN && <SolarPenBold />}
                  {selectedTool === TOOL_POLYGON && <PhPolygon />}
                  <KeyboardArrowDownIcon
                    style={{
                      padding: "0px 8px 0px 3px",
                      borderRight: "1px solid #aeaeae",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Slider
                min={1}
                max={MAX_STROKE_WIDTH}
                onChange={(e, v) => {
                  setStrokeWidth(v as number);
                }}
                style={{
                  width: "100px",
                  color: "#aeaeae",
                  height: "1px",
                  margin: "0px 10px",
                }}
                sx={{
                  ".MuiSlider-thumb": {
                    width: "8px",
                    height: "8px",
                  },
                  ".MuiSlider-rail": {
                    color: "#aeaeae",
                  },
                  ".MuiSlider-valueLabel": {
                    height: "6px",
                    width: "6px",
                    fontSize: "8px",
                    padding: "3px",
                    top: "20px",
                  },
                  ".MuiSlider-valueLabel:before": {
                    visibility: "hidden",
                  },
                }}
                valueLabelDisplay="auto"
                value={strokeWidth}
              />
            </div>
            <div className={styles.tools}>
              <Tooltip title="Zoom">
                <IconButton
                  className={styles.toolButton}
                  onClick={() => {
                    overlayRef.current?.resetZoom();
                    maskRef.current?.resetZoom();
                  }}
                >
                  <ZoomIn
                    style={{
                      padding: "0px 8px 0px 3px",
                      borderRight: "1px solid #aeaeae",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Slider
                min={1}
                step={0.001}
                max={5}
                onChange={(e, v) => {
                  setZoomScale(v as number);
                  overlayRef.current?.zoomToLastPosition(v as number);
                  maskRef.current?.zoomToLastPosition(v as number);
                }}
                style={{
                  width: "100px",
                  color: "#aeaeae",
                  height: "1px",
                  margin: "0px 10px",
                }}
                sx={{
                  ".MuiSlider-thumb": {
                    width: "8px",
                    height: "8px",
                  },
                  ".MuiSlider-rail": {
                    color: "#aeaeae",
                  },
                  ".MuiSlider-valueLabel": {
                    height: "6px",
                    width: "6px",
                    fontSize: "8px",
                    padding: "3px",
                    top: "20px",
                  },
                  ".MuiSlider-valueLabel:before": {
                    visibility: "hidden",
                  },
                }}
                valueLabelDisplay="off"
                value={zoomScale}
              />
            </div>
            <Tooltip title="Undo">
              <span>
                <IconButton
                  disabled={undoStack.length === 0}
                  onClick={() => {
                    setRedoStack((v) => {
                      const stack: [string, string, string][] = [
                        ...v,
                        [
                          overlayModeBackground,
                          matteModeBackground,
                          coverImageData,
                        ],
                      ];
                      return stack.length > MAX_STACK_SIZE
                        ? stack.slice(-MAX_STACK_SIZE)
                        : stack;
                    });
                    const [overBag, matBag, coverImage] = undoStack.pop() || [
                      "",
                      "",
                      "",
                    ];
                    setOverlayModeBackground(overBag);
                    setMatteModeBackground(matBag);
                    setCoverImageData(coverImage);
                    if (save) save(matBag, coverImage);
                    setUndoStack(undoStack);
                  }}
                  className={cx(
                    "slapShotButtonOutlineRound",
                    styles.actButtonRound,
                  )}
                >
                  <CiUndo />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Redo">
              <span>
                <IconButton
                  disabled={redoStack.length === 0}
                  onClick={() => {
                    setUndoStack((v) => {
                      const stack: [string, string, string][] = [
                        ...v,
                        [
                          overlayModeBackground,
                          matteModeBackground,
                          coverImageData,
                        ],
                      ];
                      return stack.length > MAX_STACK_SIZE
                        ? stack.slice(-MAX_STACK_SIZE)
                        : stack;
                    });
                    const [overBag, matBag, coverImage] = redoStack.pop() || [
                      "",
                      "",
                      "",
                    ];
                    setOverlayModeBackground(overBag);
                    setMatteModeBackground(matBag);
                    setCoverImageData(coverImage);
                    if (save) save(matBag, coverImage);
                    setRedoStack(redoStack);
                  }}
                  className={cx(
                    "slapShotButtonOutlineRound",
                    styles.actButtonRound,
                  )}
                >
                  <CiRedo />
                </IconButton>
              </span>
            </Tooltip>
            <Button
              disabled={!cutoutInProgress}
              onClick={handleOnClear}
              className={cx(styles.actionBtn, "slapShotButtonOutline")}
            >
              <CloseIcon fontSize="small" />
              Clear Selection
            </Button>
          </div>

          <div className={styles.actionEnd}>
            <div
              className={styles.viewTabs}
              style={{ visibility: !cutoutInProgress ? "hidden" : "visible" }}
            >
              <Button
                onClick={() => changeView(SOURCE_MODE)}
                size="small"
                className={cx(
                  styles.viewTab,
                  selectedView === SOURCE_MODE && styles.active,
                )}
              >
                {SOURCE_MODE}
              </Button>
              <Button
                onClick={() => changeView(OVERLAY_MODE)}
                size="small"
                className={cx(
                  styles.viewTab,
                  selectedView === OVERLAY_MODE && styles.active,
                )}
              >
                {OVERLAY_MODE}
              </Button>
              <Button
                onClick={() => changeView(MATTE_MODE)}
                size="small"
                className={cx(
                  styles.viewTab,
                  selectedView === MATTE_MODE && styles.active,
                )}
              >
                {MATTE_MODE}
              </Button>
            </div>
            <span>
              <Tooltip title="Exit annotation mode">
                <span>
                  <IconButton
                    className={cx(styles.closeBtn)}
                    onClick={() => {
                      noMoreTips(IntroGuides.ExitAnnotation);
                      done();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          </div>
        </div>
        <div className={styles.mainContainer} ref={containerRef}>
          <div
            className={styles.canvasContainer}
            style={{
              cursor: getCursorStyle(),
            }}
          >
            {selectedView === SOURCE_MODE && (
              <img
                width={`${clientWidthHeight[0]}px`}
                height={`${clientWidthHeight[1]}px`}
                src={sourceImageData}
                alt="Source"
              />
            )}
            {showPaintOptions() && (
              <div
                style={{
                  width: `${clientWidthHeight[0]}px`,
                  height: `${clientWidthHeight[1]}px`,
                }}
                className={styles.konvaContainer}
              >
                {selectedView === OVERLAY_MODE && (
                  <KonvaCanvas
                    ref={overlayRef}
                    strokeWidth={strokeWidth}
                    tool={selectedTool}
                    mode={selectedMode}
                    width={clientWidthHeight[0]}
                    height={clientWidthHeight[1]}
                    regions={regions}
                    setRegions={setRegions}
                    backgroundImage={overlayModeBackground}
                    isForReview={isForReview}
                    onUpdate={konvaUpdate}
                    color={color}
                    onMagicModeMouseMove={onMagicModeMouseMove}
                    onMagicModeClick={onMagicModeClick}
                    scale={zoomScale}
                    setScale={setZoomScale}
                    canPan={canPan}
                    setCanPan={setCanPan}
                  />
                )}
                <KonvaCanvas
                  ref={maskRef}
                  className={cx(
                    styles.maskImage,
                    selectedView === OVERLAY_MODE && styles.hidden,
                  )}
                  strokeWidth={strokeWidth}
                  tool={selectedTool}
                  mode={selectedMode}
                  width={clientWidthHeight[0]}
                  height={clientWidthHeight[1]}
                  regions={regions}
                  setRegions={setRegions}
                  backgroundImage={matteModeBackground}
                  isForReview={isForReview}
                  onUpdate={konvaUpdate}
                  color="red"
                  onMagicModeMouseMove={onMagicModeMouseMove}
                  onMagicModeClick={onMagicModeClick}
                  scale={zoomScale}
                  setScale={setZoomScale}
                  canPan={canPan}
                  setCanPan={setCanPan}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
EditMode.displayName = "EditMode";
export default EditMode;
