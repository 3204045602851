import AddIcon from "@mui/icons-material/Add";
import BackIcon from "@mui/icons-material/ArrowBack";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  IconButton,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import cx from "classnames";
import { groupBy, Dictionary } from "lodash";
import React, {
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as ExportIcon } from "assets/export-icon.svg";
import { ReactComponent as FolderHeartPurple } from "assets/folder-heart-purple.svg";
import { ReactComponent as List } from "assets/list.svg";
import { ReactComponent as Preview } from "assets/preview.svg";
import { ReactComponent as Close } from "assets/x-mark.svg";
import Guide from "components/Guide";
import Layers from "components/Layers";
import {
  IntroGuides,
  LAYER_COLORS,
  INTRO_GUIDES,
  STATUSES,
  ViewModes,
} from "constants/constants";
import { UserContext } from "layouts/SlapshotMainLayout";

import styles from "./ProjectPage.module.scss";
import DotElastic from "../../components/DotElastic";
import Loader from "../../components/Loader";
import ReviewPanel from "../../components/ReviewPanel";
import Shots from "../../components/Shots";
import VideoPreview from "../../components/VideoPreview";
import Workspace from "../../components/Workspace";
import { SLAPSHOT_DOWNLOADS, SLAPSHOT_HOME_URL } from "../../constants/urls";
import { Cutout, CutoutInProgress } from "../../models/Cutout";
import { LayerCompact, LayerReview } from "../../models/Layer";
import { Project } from "../../models/Project";
import { Shot, ShotCompact } from "../../models/Shot";
import { getCutouts } from "../../services/CutoutService";
import { createExport } from "../../services/ExportService";
import {
  createLayer,
  getLayerDetail,
  getLayerReview,
  getLayers,
} from "../../services/LayerService";
import { getProject } from "../../services/ProjectService";
import {
  getShotDetail,
  getShots,
  updateUseHq,
} from "../../services/ShotService";
import {
  ACTION_SUBSCRIBE_SHOT_UPDATE,
  ACTION_SUBSCRIBE_INFERENCE_UPDATE,
  getRequestData,
  getSocket,
} from "../../services/WebSocketService";
import { formatDate } from "../../utils/helper";

<FolderHeartPurple className={styles.projectIcon} />;

const EXR_MODE = "EXR";
const JPG_MODE = "JPG";
const MOV_MODE = "MOV";

const ProjectPage = () => {
  const user = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, shotId, layerId } = useParams();

  const [guides, setGuides] = useState<string[]>(
    JSON.parse(localStorage.getItem(INTRO_GUIDES) || "[]"),
  );

  const [project, setProject] = useState<Project | null>(null);
  const [isProjectLoading, setIsProjectLoading] = useState(true);

  const [shots, setShots] = useState<Dictionary<ShotCompact[]>>({});
  const [isShotsLoading, setIsShotsLoading] = useState(true);

  const [selectedShot, setSelectedShot] = useState<Shot | null>(null);
  const [isSelectedShotLoading, setIsSelectedShotLoading] = useState(false);

  const [layers, setLayers] = useState<LayerCompact[]>([]);
  const [isLayersLoading, setIsLayersLoading] = useState(false);

  const [cutouts, setCutouts] = useState<Cutout[]>([]);
  const [cutoutInProgress, setCutoutInProgress] =
    useState<CutoutInProgress | null>(null);

  const [selectedLayer, setSelectedLayer] = useState<LayerCompact | null>(null);
  const [reviewLayer, setReviewLayer] = useState<LayerReview | null>(null);
  const [isSelectedLayerLoading, setIsSelectedLayerLoading] = useState(false);

  const [imageData, setImageData] = useState<string | null>(null);
  const [selectedFrame, setSelectedFrame] = useState<number | null>(null);

  const [downloadLayer, setDownloadLayer] = useState<LayerCompact | null>(null);

  const [shotsViewMode, setShotsViewMode] = useState<ViewModes>(
    ViewModes.Preview,
  );

  const [addAnotation, setAddAnotation] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [fps, setFps] = useState(0);

  const [layersForExport, setLayersForExport] = useState<LayerCompact[]>([]);
  const [showExportPanel, setShowExportPanel] = useState(false);
  const exportModes = useMemo(
    () => user?.preferences?.exportModes || [EXR_MODE],
    [user?.preferences?.exportModes],
  );
  const [isExporting, setIsExporting] = useState(false);
  const [showExportMessage, setShowExportMessage] = useState(false);

  const inferenceSocket = useMemo(() => {
    if (!projectId) return null;
    return getSocket("inference-cutout");
  }, [projectId]);

  const toggleViewMode = (
    mode: ViewModes,
    setViewMode: React.Dispatch<SetStateAction<ViewModes>>,
  ) => {
    setViewMode(ViewModes[mode]);
  };

  const getStatusClassName = (status: string) => {
    switch (status) {
      case "IN_PROGRESS":
        return styles.statusInProgress;
      case "COMPLETED":
        return styles.statusCompleted;
      case "FAILED":
        return styles.statusFailed;
    }
    return "";
  };

  const changeExportMode = useCallback(
    (newMode: string) => {
      let newModes = exportModes;
      switch (newMode) {
        case EXR_MODE:
          newModes = [EXR_MODE];
          break;
        case JPG_MODE:
          newModes = [JPG_MODE];
          break;
        case MOV_MODE:
          newModes = [MOV_MODE];
          break;
      }
      user?.setPreferences({ ...user.preferences, exportModes: newModes });
    },
    [exportModes, user],
  );

  const handleNewLayer = async () => {
    if (!selectedShot) return;
    const layer = await createLayer(selectedShot.id);
    setLayers([layer, ...layers]);
    setSelectedLayer(layer);
    setCutoutInProgress(null);
    if (!isAnnotating() && project)
      navigate(
        `/projects/${project.id}/shots/${selectedShot.id}/layers/${layer.id}`,
      );
  };

  const handleGetCutouts = (id: number) => {
    getCutouts(id).then((result) => {
      let totalVersions = result.filter(
        (v) => v.inference?.prediction_url,
      ).length;

      setCutouts(
        result.map((c) => {
          if (c.inference?.prediction_url) {
            c.version = totalVersions;
            totalVersions--;
          }
          return c;
        }),
      );
    });
  };

  const handleGetReviewLayer = (id: number) => {
    axios
      .get("https://api.ipify.org/?format=json")
      .then((res: { data: { ip: string } }) => {
        getLayerReview(id, res.data.ip).then((result) => {
          setReviewLayer(result);
        });
      });
  };

  const handleInferenceLayerUpdates = useCallback(
    async (updatedLayerId) => {
      if (layers.findIndex((l) => l.id === updatedLayerId) === -1) return;

      getLayerDetail(updatedLayerId).then((layer) => {
        setLayers((oldLayers) =>
          [layer, ...oldLayers.filter((l) => l.id !== layer.id)].sort(
            (l1, l2) => l2.id - l1.id,
          ),
        );
      });

      if (!(layerId && +layerId === updatedLayerId)) return;

      handleGetCutouts(+layerId);
      handleGetReviewLayer(+layerId);
    },
    [layerId, layers],
  );

  // Subscribing to shot updates
  useEffect(() => {
    const socket = getSocket("shot");
    if (!socket) {
      return () => {};
    }
    socket.onopen = (event) => {
      projectId &&
        socket.send(
          getRequestData(ACTION_SUBSCRIBE_SHOT_UPDATE, {
            project_id: +projectId,
          }),
        );
    };

    socket.onmessage = (event) => {
      const e = JSON.parse(event.data).data;
      const newShots = { ...shots };
      const key = formatDate(e.created);
      const shotIndex = newShots[key].findIndex((shot) => shot.id === e.id);
      if (shotIndex !== -1) {
        newShots[key][shotIndex] = e;
        setShots(newShots);
      }
    };

    return () => {
      socket.close();
    };
  }, [projectId, shots]);

  // Subscribing to inference updates
  useEffect(() => {
    if (inferenceSocket && projectId)
      inferenceSocket.onopen = (event) => {
        inferenceSocket.send(
          getRequestData(ACTION_SUBSCRIBE_INFERENCE_UPDATE, {
            project_id: +projectId,
          }),
        );
      };
    return () => {
      inferenceSocket?.close();
    };
  }, [inferenceSocket, projectId]);

  useEffect(() => {
    if (inferenceSocket)
      inferenceSocket.onmessage = (event) => {
        const e = JSON.parse(event.data).data;
        const newShots = { ...shots };
        const key = formatDate(e.shot.created);
        const shotIndex = newShots[key].findIndex(
          (shot) => shot.id === e.shot.id,
        );
        if (shotIndex !== -1) {
          newShots[key][shotIndex] = e.shot;
          setShots(newShots);
        }

        handleInferenceLayerUpdates(e.layer_id);
      };
  }, [handleInferenceLayerUpdates, inferenceSocket, projectId, shots]);

  // Loading selected project...
  useEffect(() => {
    if (projectId) {
      getProject(+projectId)
        .then((result) => {
          setProject(result);
        })
        .finally(() => {
          setIsProjectLoading(false);
        });
      getShots(+projectId)
        .then((result) => {
          const shotsDict = groupBy(result, (shot) => formatDate(shot.created));
          setShots(shotsDict);
        })
        .finally(() => {
          setIsShotsLoading(false);
        });
    }
  }, [projectId]);

  // Loading selected shot...
  useEffect(() => {
    if (shotId) {
      setIsSelectedShotLoading(true);
      getShotDetail(+shotId)
        .then((result) => {
          setSelectedShot(result);
        })
        .finally(() => {
          setIsSelectedShotLoading(false);
        });

      setIsLayersLoading(true);
      getLayers(+shotId)
        .then((result) => {
          setLayers(result);
        })
        .finally(() => {
          setIsLayersLoading(false);
        });
    } else {
      setSelectedShot(null);
      setLayers([]);
    }
  }, [shotId]);

  // Loading selected layer...
  useEffect(() => {
    setSelectedFrame(null);
    setImageData(null);
    setAddAnotation(false);
    setReviewLayer(null);
    if (layerId) {
      setIsSelectedLayerLoading(true);
      handleGetReviewLayer(+layerId);
      getLayerDetail(+layerId)
        .then((result) => {
          setSelectedLayer(result);
        })
        .finally(() => {
          setIsSelectedLayerLoading(false);
        });
      handleGetCutouts(+layerId);
    } else {
      setSelectedLayer(null);
      setCutouts([]);
    }
  }, [layerId, selectedShot]);

  useEffect(() => {
    setLayersForExport(
      layers.filter(
        (layer) =>
          !!layer.predictions.find(
            (prediction) => prediction.status === STATUSES.COMPLETED,
          ),
      ),
    );
  }, [layers, showExportPanel]);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const isAnnotating = () => {
    return !!(imageData && selectedShot && selectedFrame);
  };

  const isReviewing = () => {
    return !!(selectedShot && layerId);
  };

  const noMoreTips = useCallback(
    (tip?: string) => {
      const newGuides = tip ? guides.filter((value) => value !== tip) : [];
      if (newGuides !== guides) {
        setGuides(newGuides);
        localStorage.setItem(INTRO_GUIDES, JSON.stringify(newGuides));
      }
    },
    [guides],
  );

  const exitAnnotationMode = () => {
    setImageData(null);
    setSelectedFrame(null);
    setAddAnotation(false);
    setCutoutInProgress(null);
    setSelectedLayer(null);
    if (layerId) {
      handleGetCutouts(+layerId);
    }
  };

  return (
    <div className={cx(styles.mainContainer)}>
      <Loader isLoading={isProjectLoading || isShotsLoading} />
      {isDownloading && (
        <div className={styles.hintContainer}>
          <h3>Downloading...</h3>
          <DotElastic />
        </div>
      )}
      {!isProjectLoading && !isShotsLoading && project && (
        <div>
          <div className={styles.navRow}>
            <div className={styles.startNav}>
              <IconButton
                className={styles.noBackgroundButtonSmall}
                onClick={() => {
                  if (layerId && selectedShot)
                    navigate(
                      `${SLAPSHOT_HOME_URL}projects/${project.id}/shots/${selectedShot.id}`,
                    );
                  else if (selectedShot && !isAnnotating())
                    navigate(`${SLAPSHOT_HOME_URL}projects/${project.id}`);
                  else if (!isAnnotating()) navigate(SLAPSHOT_HOME_URL);
                  if (isAnnotating()) {
                    setImageData(null);
                    setSelectedFrame(null);
                    setAddAnotation(false);
                  }
                }}
              >
                <BackIcon />
              </IconButton>
              <div className={styles.projectDetails}>
                <FolderHeartPurple />
                <div
                  onClick={() => {
                    if (isAnnotating()) {
                      setImageData(null);
                      setSelectedFrame(null);
                      setAddAnotation(false);
                    }
                    navigate(`${SLAPSHOT_HOME_URL}projects/${project.id}`);
                    setSelectedLayer(null);
                    setCutoutInProgress(null);
                  }}
                  className={cx(styles.projectNameBold, [
                    selectedShot && styles.greyProjectName,
                  ])}
                >
                  {project.name}
                </div>
                {selectedShot && (
                  <>
                    <div
                      style={{ color: "#FFFFFF80" }}
                      className={styles.projectNameBold}
                    >
                      |
                    </div>
                    <div
                      className={styles.projectNameBold}
                      onClick={() => {
                        if (isAnnotating()) {
                          setImageData(null);
                          setSelectedFrame(null);
                          setAddAnotation(false);
                        }
                        navigate(
                          `${SLAPSHOT_HOME_URL}projects/${project.id}/shots/${selectedShot.id}`,
                        );
                      }}
                    >
                      {selectedShot.name}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.shotsContainer}>
              <div className={styles.shotsHeader}>
                <h5>Shots</h5>
                <div className={styles.actionsContainer}>
                  <ToggleButtonGroup
                    value={shotsViewMode}
                    exclusive
                    onChange={(e, mode) =>
                      mode && toggleViewMode(mode, setShotsViewMode)
                    }
                    aria-label="View Mode"
                    size="small"
                  >
                    <ToggleButton
                      value="List"
                      title="list"
                      style={{ border: 0, padding: 0 }}
                    >
                      <List
                        className={cx(
                          styles.viewIcon,
                          shotsViewMode === "List" && styles.viewIconActive,
                        )}
                      />
                    </ToggleButton>
                    <ToggleButton
                      style={{ border: 0, padding: 0 }}
                      value="Preview"
                      title="Preview"
                    >
                      <Preview
                        className={cx(
                          styles.viewIcon,
                          shotsViewMode === "Preview" && styles.viewIconActive,
                        )}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <Shots
                shots={shots}
                setShots={setShots}
                shotsViewMode={shotsViewMode}
                project={project}
                guides={!selectedShot ? guides : []}
                noMoreTips={noMoreTips}
              />
            </div>

            <div className={styles.workspaceContainer}>
              {!isAnnotating() && (
                <div className={styles.workspaceHeader}>
                  <div>
                    {selectedShot &&
                      selectedShot.is_hq != null &&
                      shotsViewMode === ViewModes.Preview && (
                        <div>
                          <ThemeProvider theme={darkTheme}>
                            <label htmlFor="hqToggle">HQ</label>
                            <Switch
                              id="hqToggle"
                              className={styles.hqToggle}
                              checked={selectedShot.is_hq}
                              size="small"
                              color="primary"
                              onChange={(e) => {
                                setSelectedShot({
                                  ...selectedShot,
                                  is_hq: !selectedShot.is_hq,
                                });
                                updateUseHq(+selectedShot.id);
                              }}
                            />
                          </ThemeProvider>
                        </div>
                      )}
                  </div>
                  <div className={styles.actions}>
                    {!!layersForExport.length && !showExportPanel && (
                      <Button
                        className={styles.exportButton}
                        variant="contained"
                        onClick={() => {
                          setShowExportPanel(true);
                        }}
                        color="secondary"
                        size="small"
                        sx={{
                          ".MuiSvgIcon-root": {
                            width: "14px",
                            height: "14px",
                          },
                        }}
                      >
                        <ExportIcon style={{ marginRight: "8px" }} />
                        Export
                      </Button>
                    )}
                    {selectedShot && (
                      <Guide
                        id={IntroGuides.CreateAnnotation}
                        guides={guides}
                        open={!layers.length}
                        placement="left"
                        noMoreTips={noMoreTips}
                        title="Create your first annotation"
                        description="Select an optimal frame in the video and then click here to select an object."
                      >
                        <Button
                          className={cx(
                            styles.annotateButton,
                            "slapShotButton",
                          )}
                          variant="contained"
                          onClick={() => {
                            setAddAnotation(true);
                            if (layerId) {
                              const layer = layers.find(
                                (l) => l.id === +layerId,
                              );
                              if (layer) setSelectedLayer(layer);
                            }
                            noMoreTips(IntroGuides.CreateAnnotation);
                          }}
                          color="secondary"
                          size="small"
                          sx={{
                            ".MuiSvgIcon-root": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                        >
                          <AddIcon />
                          Add Annotation
                        </Button>
                      </Guide>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.workspaceArea}>
                {isSelectedShotLoading && !!selectedShot && (
                  <div className={styles.loaderContainer}>
                    <DotElastic />
                  </div>
                )}
                {!imageData &&
                  !isSelectedShotLoading &&
                  selectedShot &&
                  !layerId && (
                    <VideoPreview
                      videoURL={selectedShot.video_url}
                      frameCount={selectedShot.frame_count}
                      firstFrame={selectedShot.first_frame_number}
                      setImageData={(d) => {
                        setImageData(d);
                      }}
                      setSelectedFrame={setSelectedFrame}
                      addAnotation={addAnotation}
                      fps={selectedShot.fps}
                      startTime={selectedShot.start_time}
                    />
                  )}
                {!selectedShot && (
                  <div className={styles.emptyPlayer}>
                    <span className={styles.selectShot}>
                      Select a shot from the “Shots” panel on the left to view
                      in the player.
                    </span>
                  </div>
                )}

                {!isAnnotating() && selectedShot && layerId && shotId && (
                  <ReviewPanel
                    cutouts={cutouts}
                    layerId={+layerId}
                    shotId={+shotId}
                    videoURL={selectedShot.video_url}
                    layers={layers}
                    fps={selectedShot.fps}
                    onStoreCutout={() => {
                      getLayers(+shotId).then((ls) => setLayers(ls));
                    }}
                    addAnotation={addAnotation}
                    setImageData={(d) => {
                      setImageData(d);
                    }}
                    setSelectedFrame={setSelectedFrame}
                    layer={reviewLayer}
                    guides={guides}
                    noMoreTips={noMoreTips}
                  />
                )}

                {imageData &&
                  selectedShot &&
                  selectedFrame &&
                  isAnnotating() && (
                    <Workspace
                      imageData={imageData}
                      layers={layers}
                      frame={selectedFrame}
                      shotId={selectedShot?.id}
                      selectedLayer={selectedLayer}
                      onBack={exitAnnotationMode}
                      setCutoutInProgress={setCutoutInProgress}
                      cutoutInProgress={cutoutInProgress}
                      setSelectedLayer={setSelectedLayer}
                      setLayers={setLayers}
                      color={
                        selectedLayer?.color ||
                        LAYER_COLORS[layers.length % LAYER_COLORS.length]
                      }
                      guides={guides}
                      noMoreTips={noMoreTips}
                    />
                  )}
              </div>
            </div>
            {!showExportPanel || isAnnotating() ? (
              <Layers
                handleLayerClick={(l) => {
                  if (isAnnotating()) {
                    if (l.id !== selectedLayer?.id) {
                      setSelectedLayer(l);
                      setCutoutInProgress(null);
                    }
                  } else if (selectedShot)
                    navigate(
                      `/projects/${project.id}/shots/${selectedShot.id}/layers/${l.id}`,
                    );
                }}
                handleNewLayer={handleNewLayer}
                isAnnotating={isAnnotating()}
                layerId={layerId}
                layers={layers}
                selectedLayer={selectedLayer}
                setLayers={setLayers}
                setSelectedLayer={setSelectedLayer}
                setCutoutInProgress={setCutoutInProgress}
                selectedShot={selectedShot}
                guides={guides}
                noMoreTips={noMoreTips}
                project={project}
                exitAnnotationMode={exitAnnotationMode}
                setShots={setShots}
              />
            ) : (
              <div className={styles.shotsContainer}>
                <div className={styles.shotsHeader}>
                  <h5>Export</h5>
                  <button
                    type="button"
                    className={styles.closeExportButton}
                    onClick={() => {
                      setShowExportPanel(false);
                    }}
                  >
                    <Close />
                  </button>
                </div>
                <div className={styles.shotsForExport}>
                  {layersForExport.map((l) => (
                    <div key={l.id} className={styles.shotExport}>
                      <div className={styles.layerExportDetail}>
                        {l.cover_image_url ? (
                          <img
                            alt={l.cover_image_url}
                            className={styles.exportThumbnail}
                            src={l.cover_image_url}
                          />
                        ) : (
                          <AspectRatioIcon className={styles.exportThumbnail} />
                        )}
                        <p>{l.name}</p>
                      </div>
                      <CloseIcon
                        className={styles.removeShot}
                        onClick={() => {
                          setLayersForExport((exportLayers) =>
                            exportLayers.filter((layer) => layer.id !== l.id),
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.exportFooter}>
                  <div className={styles.exportOptions}>
                    <div className={styles.viewTabs}>
                      <Button
                        onClick={() => changeExportMode(EXR_MODE)}
                        size="small"
                        className={cx(
                          styles.viewTab,
                          exportModes.includes(EXR_MODE) && styles.active,
                        )}
                      >
                        {EXR_MODE}
                      </Button>
                      <div>
                        <Button
                          onClick={() => changeExportMode(JPG_MODE)}
                          size="small"
                          className={cx(
                            styles.viewTab,
                            exportModes.includes(JPG_MODE) && styles.active,
                          )}
                        >
                          {JPG_MODE}
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() => changeExportMode(MOV_MODE)}
                          size="small"
                          className={cx(
                            styles.viewTab,
                            exportModes.includes(MOV_MODE) && styles.active,
                          )}
                        >
                          {MOV_MODE}
                        </Button>
                      </div>
                    </div>
                    <p>
                      All the layers inside the shot will be exported as a
                      package
                    </p>
                  </div>
                  <Button
                    disabled={isExporting}
                    onClick={async () => {
                      setIsExporting(true);
                      const layerIds = layersForExport.map((layer) => layer.id);
                      const { id } = project;
                      await createExport(+id, layerIds, exportModes);
                      setIsExporting(false);
                      setShowExportMessage(true);
                    }}
                    className={styles.primaryButton}
                  >
                    Export
                  </Button>
                </div>
              </div>
            )}
          </div>

          <Snackbar
            open={showExportMessage}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={(
              event?: React.SyntheticEvent | Event,
              reason?: string,
            ) => {
              if (reason === "clickaway") {
                return;
              }
              setShowExportMessage(false);
              setShowExportPanel(false);
            }}
          >
            <div className={styles.exportNotification}>
              <div className={styles.notificationLoader}>
                <img
                  src="/loading.gif"
                  className={styles.notificationLoadingGif}
                  alt=""
                />
              </div>
              <div className={styles.exportNotificationDetails}>
                <h3>Generating the package...</h3>
                <p>
                  Your package generation is in process.
                  <br />
                  You can download it from the Exports page.
                </p>
                <Button
                  className={styles.downloadsButton}
                  onClick={() => {
                    navigate(SLAPSHOT_DOWNLOADS);
                  }}
                >
                  Go to Exports&nbsp;
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.9987 0.833496L4.1762 1.656L7.4312 4.91683H0.332031V6.0835H7.4312L4.1762 9.34433L4.9987 10.1668L9.66536 5.50016L4.9987 0.833496Z"
                      fill="#6565EC"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </Snackbar>
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
