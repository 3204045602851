import { Backdrop, CircularProgress } from "@mui/material";
import React, { SetStateAction, useEffect, useRef, useState } from "react";

import DotElastic from "components/DotElastic";
import VideoPlayer, { VideoPlayerRefAttributes } from "components/VideoPlayer";

import { captureSnapshot } from "./video_preview_utils";
import styles from "./VideoPreview.module.scss";

interface VideoPreviewParams {
  videoURL: string;
  firstFrame: number;
  frameCount: number;
  setImageData: React.Dispatch<SetStateAction<string | null>>;
  setSelectedFrame: React.Dispatch<SetStateAction<number | null>>;
  addAnotation: boolean;
  fps: number;
  startTime: number;
}

const VideoPreview: React.FC<VideoPreviewParams> = ({
  videoURL,
  frameCount,
  firstFrame,
  setImageData,
  setSelectedFrame,
  addAnotation,
  fps,
  startTime,
}) => {
  const playerRef = useRef<VideoPlayerRefAttributes>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (addAnotation) {
      const eVideo = playerRef.current?.videoRef.current;
      if (eVideo) {
        setImageData(captureSnapshot(eVideo).toDataURL("image/jpeg", 1.0));
        setSelectedFrame(playerRef.current.currentFrame + firstFrame);
      }
    }
  }, [addAnotation, firstFrame, setImageData, setSelectedFrame]);

  return (
    <div className={styles.container}>
      <div className={styles.videoContainer}>
        <VideoPlayer
          ref={playerRef}
          videoURL={videoURL}
          totalFrames={frameCount}
          firstFrame={firstFrame}
          setIsLoading={setIsLoading}
          fps={fps}
          startTime={startTime}
        />
        {isLoading && (
          <div className={styles.loaderContainer}>
            <DotElastic />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPreview;
