export const NOT_FOUND_URL = "/404";
export const INTERNAL_SERVER_ERROR_URL = "/500";
export const GENERIC_ERROR_URL = "/error";

export const ADMIN_URL = "/admin/slapshot/";

export const SLAPSHOT_AUTH = "/auth";

export const SLAPSHOT_LOGIN_URL = `${SLAPSHOT_AUTH}/login`;
export const SLAPSHOT_FORGOT_PASSWORD_URL = `${SLAPSHOT_AUTH}/forgot-password`;
export const SLAPSHOT_RESET_PASSWORD_URL = `${SLAPSHOT_AUTH}/reset-password/:userIdentifier/:token`;

export const SLAPSHOT_REGISTER_URL = `${SLAPSHOT_AUTH}/register`;

export const SLAPSHOT_HOME_URL = "/";
export const SLAPSHOT_PROFILE_URL = `${SLAPSHOT_HOME_URL}profile`;

// export const SLAPSHOT_PROJECTS_URL = `${SLAPSHOT_HOME_URL}projects`;

export const SLAPSHOT_WORKSPACES_URL = `${SLAPSHOT_HOME_URL}workspaces`;

export const SLAPSHOT_LAYER_URL = `${SLAPSHOT_HOME_URL}projects/:projectId/shots/:shotId/layers/:layerId`;

export const SLAPSHOT_SHOT_URL = `${SLAPSHOT_HOME_URL}projects/:projectId/shots/:shotId`;

export const SLAPSHOT_PROJECT_URL = `${SLAPSHOT_HOME_URL}projects/:projectId`;

export const SLAPSHOT_DOWNLOADS = `${SLAPSHOT_HOME_URL}downloads`;

export const SLAPSHOT_SETTINGS = `${SLAPSHOT_HOME_URL}settings`;

export const SLAPSHOT_INVITE_USER = `${SLAPSHOT_HOME_URL}invite-member`;
export const SLAPSHOT_INVITE_ACCEPT = `${SLAPSHOT_HOME_URL}invite-accept`;
