/* eslint-disable react/no-danger */
import { Snackbar } from "@mui/material";
import React from "react";

import { ReactComponent as CheckBlueIcon } from "assets/check-blue.svg";

import styles from "./NotificationSnackbar.module.scss";

interface NotificationSnackbarParams {
  message: string;
  onClose: () => void;
}

const NotificationSnackbar: React.FC<NotificationSnackbarParams> = ({
  message,
  onClose,
}) => {
  if (!message) {
    return null;
  }
  return (
    <Snackbar
      open={!!message}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={(event?: React.SyntheticEvent | Event, reason?: string) => {
        onClose();
      }}
    >
      <div className={styles.notificationContainer}>
        <CheckBlueIcon />
        <div
          className={styles.notificationContent}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
    </Snackbar>
  );
};

export default NotificationSnackbar;
