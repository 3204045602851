import { useState, useEffect, useCallback } from "react";

export const useClickHandler = (onSingleClick, onDoubleClick, delay = 300) => {
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleClick = useCallback(() => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      if (onDoubleClick) onDoubleClick();
    } else {
      const timeout = setTimeout(() => {
        setClickTimeout(null);
        if (onSingleClick) onSingleClick();
      }, delay);
      setClickTimeout(timeout);
    }
  }, [clickTimeout, onSingleClick, onDoubleClick, delay]);

  useEffect(() => {
    return () => {
      if (clickTimeout) clearTimeout(clickTimeout);
    };
  }, [clickTimeout]);

  return handleClick;
};
