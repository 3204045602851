import CloseIcon from "@mui/icons-material/CloseRounded";
import { Dialog, IconButton } from "@mui/material";
import { at } from "lodash";
import React, { useState } from "react";

import styles from "./UploadAnnotations.module.scss";
import { uploadCutouts } from "../../services/CutoutService";
import UploadAnnotationsField from "../UploadAnnotationsField";

interface UploadAnnotationsParams {
  open: boolean;
  onClose: () => void;
}
const UploadAnnotations: React.FC<UploadAnnotationsParams> = ({
  open,
  onClose,
}) => {
  const [isUploadStarted, setIsUploadStarted] = useState<boolean>(false);
  const [isUploadCompleted, setIsUploadCompleted] = useState<boolean>(false);

  const [response, setResponse] = useState<
    { errors: { key: string }; messages: { key: string } } | undefined
  >();

  const uploadFiles = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    setIsUploadStarted(true);
    uploadCutouts(files)
      .then((res) => {
        setResponse({
          errors: res.errors,
          messages: res.messages,
        });
      })
      .finally(() => {
        setIsUploadStarted(false);
        setIsUploadCompleted(true);
      });
  };

  return (
    <Dialog
      open={open}
      fullScreen
      PaperProps={{
        style: {
          background: `radial-gradient(
    60.87% 87.66% at 50% 50%,
    #202248 0%,
    #111113 50%,
    #111113 100%
  )`,
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.actionButtons}>
          <IconButton
            disabled={isUploadStarted}
            size="large"
            style={{ color: "white" }}
            onClick={() => {
              setIsUploadStarted(false);
              setIsUploadCompleted(false);
              setResponse(undefined);
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <UploadAnnotationsField
          isUploadStarted={isUploadStarted || isUploadCompleted}
          isUploadCompleted={isUploadCompleted}
          className={styles.uploadAnnotationsField}
          onSelect={uploadFiles}
        />
        {response?.messages &&
          Object.entries(response?.messages).length > 0 && (
            <div className={styles.messages}>
              <h2>
                <u>Success:</u>
              </h2>
              {Object.entries(response.messages).map((msg) => {
                return (
                  <p key={msg[0]}>
                    <b>{msg[0]}</b> : {msg[1]}
                  </p>
                );
              })}
            </div>
          )}
        {response?.errors && Object.entries(response?.errors).length > 0 && (
          <div className={styles.errors}>
            <h2>
              <u>Errors:</u>
            </h2>
            {Object.entries(response.errors).map((error) => {
              return (
                <p key={error[0]}>
                  <b>{error[0]}</b> : {error[1]}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default UploadAnnotations;
