import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";
import { SLAPSHOT_WORKSPACES_URL } from "../../constants/urls";
import { acceptInvite } from "../../services/WorkspaceService";

import "react-multi-email/dist/style.css";

const InviteAcceptPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      acceptInvite(token).then((res) => {
        navigate(SLAPSHOT_WORKSPACES_URL);
        setIsLoading(false);
      });
    }
  }, [location.search, navigate]);

  return (
    <div>
      <Loader isLoading={isLoading} />
    </div>
  );
};

export default InviteAcceptPage;
