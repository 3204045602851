import AddIcon from "@mui/icons-material/Add";
import {
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  Backdrop,
} from "@mui/material";
import cx from "classnames";
import { Dictionary } from "lodash";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as LayersIcon } from "assets/layers.svg";
import { ReactComponent as List } from "assets/list.svg";
import { ReactComponent as RenameIcon } from "assets/pencil-icon.svg";
import { ReactComponent as Preview } from "assets/preview.svg";
import { ReactComponent as XMark } from "assets/x-mark.svg";
import Guide from "components/Guide";
import Layer from "components/Layers/Layer";
import { IntroGuides, ViewModes } from "constants/constants";
import { SLAPSHOT_HOME_URL } from "constants/urls";
import { CutoutInProgress } from "models/Cutout";
import { LayerCompact } from "models/Layer";
import { Project } from "models/Project";
import { Shot, ShotCompact } from "models/Shot";
import { deleteLayer, getLayers } from "services/LayerService";
import {
  canRunInference,
  getShotDetail,
  runInference,
} from "services/ShotService";
import {
  ACTION_SUBSCRIBE_CUTOUT_UPDATE,
  getRequestData,
  getSocket,
} from "services/WebSocketService";
import { formatDate } from "utils/helper";

import styles from "./Layers.module.scss";

interface LayersProps {
  isAnnotating: boolean;
  layerId: string | undefined;
  selectedLayer: LayerCompact | null;
  setSelectedLayer: React.Dispatch<React.SetStateAction<LayerCompact | null>>;
  setCutoutInProgress: React.Dispatch<
    React.SetStateAction<CutoutInProgress | null>
  >;
  setLayers: React.Dispatch<React.SetStateAction<LayerCompact[]>>;
  layers: LayerCompact[];
  handleLayerClick: (layer: LayerCompact) => void;
  handleNewLayer: () => void;
  selectedShot: Shot | null;
  guides: string[];
  noMoreTips: (tip?: string) => void;
  exitAnnotationMode: () => void;
  project: Project;
  setShots: React.Dispatch<SetStateAction<Dictionary<ShotCompact[]>>>;
}

const Layers: React.FC<LayersProps> = ({
  isAnnotating,
  layerId,
  selectedLayer,
  setSelectedLayer,
  setCutoutInProgress,
  setLayers,
  layers,
  handleLayerClick,
  handleNewLayer,
  selectedShot,
  guides,
  noMoreTips,
  exitAnnotationMode,
  project,
  setShots,
}) => {
  const [layerAnchorEl, setLayerAnchorEl] = useState<null | HTMLElement>(null);
  const [showRenameLayerModal, setShowRenameLayerModal] = useState(false);
  const [showLayerDeleteModal, setShowLayerDeleteModal] = useState(false);
  const layerInputEl = useRef<HTMLInputElement | null>(null);
  const [layersViewMode, setLayersViewMode] = useState<ViewModes>(
    ViewModes.Preview,
  );
  const [isInferenceDisabled, setIsInferenceDisabled] = useState(true);
  const [isInferencing, setIsInferencing] = useState(false);

  const cutoutSocket = useMemo(() => {
    if (!selectedShot) return null;
    return getSocket("cutout");
  }, [selectedShot]);

  const toggleViewMode = (
    mode: ViewModes,
    setViewMode: React.Dispatch<SetStateAction<ViewModes>>,
  ) => {
    setViewMode(ViewModes[mode]);
  };

  const navigate = useNavigate();

  const handleRunInference = async () => {
    if (!selectedShot) return;
    noMoreTips(IntroGuides.RunInference);

    setIsInferenceDisabled(true);
    setIsInferencing(true);

    if (isAnnotating) {
      exitAnnotationMode();
    }

    runInference(selectedShot.id)
      .then(() => {
        getLayers(selectedShot.id).then((result) => {
          setLayers(result);
        });
        getShotDetail(selectedShot.id).then((shot) => {
          setShots((oldShots) => {
            const newShots = { ...oldShots };
            const key = formatDate(shot.created);
            const shotIndex = newShots[key].findIndex((s) => s.id === shot.id);
            if (shotIndex !== -1) {
              newShots[key][shotIndex] = shot;
              return newShots;
            }
            return oldShots;
          });
        });
      })
      .catch((error) => toast.error(error.message))
      .finally(() => {
        setTimeout(() => {
          setIsInferencing(false);
        }, 6000);
      });
  };

  const handleCanRunInference = useCallback(() => {
    if (!selectedShot) return;
    canRunInference(selectedShot.id)
      .then((res) => {
        setIsInferenceDisabled(!res.can_run_inference);
      })
      .catch((error) => toast.error(error.message));
  }, [selectedShot]);

  useEffect(() => {
    if (showRenameLayerModal && layerInputEl.current)
      layerInputEl.current.focus();
  }, [showRenameLayerModal]);

  // Subscribing to cutout updates
  useEffect(() => {
    if (cutoutSocket && selectedShot)
      cutoutSocket.onopen = () => {
        cutoutSocket.send(
          getRequestData(ACTION_SUBSCRIBE_CUTOUT_UPDATE, {
            shot_id: selectedShot.id,
          }),
        );
      };
    return () => {
      cutoutSocket?.close();
    };
  }, [cutoutSocket, selectedShot]);
  useEffect(() => {
    if (cutoutSocket) {
      cutoutSocket.onmessage = () => {
        handleCanRunInference();
      };
    }
  }, [cutoutSocket, handleCanRunInference]);

  useEffect(() => {
    handleCanRunInference();
  }, [handleCanRunInference]);

  const canDeleteSelectedLayer = () => {
    return selectedLayer?.latest_inference_cutout_status === null;
  };

  return (
    <>
      <div className={styles.layersContainer}>
        <div className={styles.layersHeader}>
          <h5>Layers</h5>
          <div className={styles.actionsContainer}>
            <ToggleButtonGroup
              value={layersViewMode}
              exclusive
              onChange={(e, mode) =>
                mode && toggleViewMode(mode, setLayersViewMode)
              }
              aria-label="View Mode"
              size="small"
            >
              <ToggleButton
                value="List"
                title="list"
                style={{ padding: 0, border: 0 }}
              >
                <List
                  className={cx(
                    styles.viewIcon,
                    layersViewMode === "List" && styles.viewIconActive,
                  )}
                />
              </ToggleButton>
              <ToggleButton
                value="Preview"
                title="Preview"
                style={{ padding: 0, border: 0 }}
              >
                <Preview
                  className={cx(
                    styles.viewIcon,
                    layersViewMode === "Preview" && styles.viewIconActive,
                  )}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={styles.layersBody}>
          <Menu
            id="basic-menu"
            anchorEl={layerAnchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!layerAnchorEl}
            onClose={() => {
              setLayerAnchorEl(null);
              setSelectedLayer(null);
              setCutoutInProgress(null);
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#111113",
                borderRadius: "7px",
                border: "1px solid #CCCCDC1F",
                padding: "8px 6px",
                width: "184px",
                boxShadow: "0px 8px 24px 0px #010409",
              },
            }}
          >
            <MenuItem
              className={styles.menuItem}
              id="LayerRenameBtn"
              onClick={() => {
                setLayerAnchorEl(null);
                setShowRenameLayerModal(true);
              }}
            >
              <RenameIcon style={{ marginRight: "8px" }} />
              Rename
            </MenuItem>
            {canDeleteSelectedLayer() && (
              <MenuItem
                className={styles.menuItem}
                onClick={() => {
                  setLayerAnchorEl(null);
                  setShowLayerDeleteModal(true);
                }}
              >
                <DeleteIcon style={{ marginRight: "8px" }} />
                Delete
              </MenuItem>
            )}
          </Menu>
          {layers.length > 0 && (
            <Button
              onClick={handleNewLayer}
              className={styles.newLayer}
              sx={{
                ".MuiSvgIcon-root": {
                  width: "16px",
                  height: "16px",
                  marginRight: "4px",
                },
              }}
            >
              <AddIcon />
              New Layer
            </Button>
          )}
          <div className={styles.layersScrollCon}>
            <div className={styles.layers}>
              {!layers.length && (
                <div className={styles.noLayer}>
                  <div className={styles.layersIconCon}>
                    <LayersIcon />
                  </div>
                  <div className={styles.layersHint}>
                    To start, click any object in the video.
                  </div>
                </div>
              )}
              {layers.map((l, index) => (
                <Guide
                  id={IntroGuides.FirstLayer}
                  guides={guides}
                  key={l.id}
                  open={
                    index === layers.length - 1 &&
                    !layerId &&
                    !isAnnotating &&
                    !isInferencing
                  }
                  title="You added your first layer!"
                  description="Click to view in the player, double click to rename this layer, right click to view more options."
                  placement="left"
                  noMoreTips={noMoreTips}
                >
                  <div>
                    <Layer
                      layer={l}
                      key={l.id}
                      isAnnotating={isAnnotating}
                      layerId={layerId}
                      layerInputEl={layerInputEl}
                      layers={layers}
                      layersViewMode={layersViewMode}
                      selectedLayer={selectedLayer}
                      setLayerAnchorEl={setLayerAnchorEl}
                      setLayers={setLayers}
                      setSelectedLayer={setSelectedLayer}
                      setCutoutInProgress={setCutoutInProgress}
                      setShowRenameLayerModal={setShowRenameLayerModal}
                      showRenameLayerModal={showRenameLayerModal}
                      handleSingleClick={() => {
                        noMoreTips(IntroGuides.FirstLayer);
                        handleLayerClick(l);
                      }}
                    />
                  </div>
                </Guide>
              ))}
              <div />
            </div>
          </div>
          {layers.length > 0 && (
            <Guide
              id={IntroGuides.RunInference}
              guides={guides}
              open={!isInferenceDisabled}
              title="Click to Run Inference"
              description="Click here to activate Slapshot's AI and begin prediction of the selected objects."
              placement="top"
              noMoreTips={noMoreTips}
            >
              <Button
                disabled={isInferenceDisabled}
                className={styles.inferenceBtn}
                onClick={handleRunInference}
              >
                <AiIcon />
                <span>Run Inference </span>
              </Button>
            </Guide>
          )}
        </div>
      </div>

      <Backdrop open={isInferencing}>
        <div className={styles.inferencingCon}>
          <div>
            <img className={styles.gif} src="/loading.gif" alt="loading" />
          </div>
          <div className={styles.textCon}>
            <div className={styles.heading}>
              AI is inferencing the layers...
            </div>
            <div className={styles.subHeading}>
              You can continue with your other tasks in the meanwhile.
            </div>
          </div>
        </div>
      </Backdrop>

      <Dialog
        open={showLayerDeleteModal}
        PaperProps={{
          className: "dialog",
        }}
      >
        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="modalTitle">Delete Layer</h3>
            <button
              onClick={() => {
                setShowLayerDeleteModal(false);
              }}
              type="button"
              className="closeButton"
            >
              <XMark />
            </button>
          </div>
          <div className={styles.confirmationText}>
            <p>
              Are you sure you want to delete this layer&nbsp;
              <b>{selectedLayer?.name}</b>&nbsp;?
            </p>
          </div>
          <div className="modalActions">
            <Button
              type="button"
              className={styles.noBackgroundButton}
              onClick={() => {
                setShowLayerDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className={styles.outlineButtonRed}
              onClick={() => {
                selectedLayer &&
                  deleteLayer(selectedLayer.id)
                    .then(() => {
                      if (
                        layerId &&
                        +layerId === selectedLayer.id &&
                        selectedShot
                      )
                        navigate(
                          `${SLAPSHOT_HOME_URL}projects/${project.id}/shots/${selectedShot.id}`,
                        );
                      setLayers((prevLayers) =>
                        prevLayers.filter((l) => l.id !== selectedLayer.id),
                      );
                    })
                    .catch((err) => {
                      toast.error(err.data);
                    })
                    .finally(() => {
                      setSelectedLayer(null);
                      setCutoutInProgress(null);
                      setShowLayerDeleteModal(false);
                    });
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const AiIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="aiIconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#6565ec" }} />
          <stop offset="18.5%" style={{ stopColor: "#009efa" }} />
          <stop offset="39%" style={{ stopColor: "#00c9a7" }} />
          <stop offset="59%" style={{ stopColor: "#ffc75f" }} />
          <stop offset="79.5%" style={{ stopColor: "#ff8066" }} />
          <stop offset="100%" style={{ stopColor: "#d65db1" }} />
        </linearGradient>
      </defs>
      <g clipPath="url(#clip0_1692_12226)">
        <path
          d="M12.6667 5.99959L13.5 4.16626L15.3333 3.33293L13.5 2.49959L12.6667 0.66626L11.8333 2.49959L10 3.33293L11.8333 4.16626L12.6667 5.99959Z"
          className={styles.aiIcon}
        />
        <path
          d="M12.6667 9.99927L11.8333 11.8326L10 12.6659L11.8333 13.4993L12.6667 15.3326L13.5 13.4993L15.3333 12.6659L13.5 11.8326L12.6667 9.99927Z"
          className={styles.aiIcon}
        />
        <path
          d="M7.66602 6.33268L5.99935 2.66602L4.33268 6.33268L0.666016 7.99935L4.33268 9.66602L5.99935 13.3327L7.66602 9.66602L11.3327 7.99935L7.66602 6.33268ZM6.65935 8.65935L5.99935 10.1127L5.33935 8.65935L3.88602 7.99935L5.33935 7.33935L5.99935 5.88602L6.65935 7.33935L8.11268 7.99935L6.65935 8.65935Z"
          className={styles.aiIcon}
        />
      </g>
      <defs>
        <clipPath id="clip0_1692_12226">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Layers;
