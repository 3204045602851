export const captureSnapshot = (video: HTMLVideoElement) => {
  const w = video.videoWidth;
  const h = video.videoHeight;
  const canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(video, 0, 0, w, h);
  return canvas;
};
