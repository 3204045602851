import axios, { ResponseType } from "axios";

import { AUTH_TOKEN, STATUS_CODE } from "../constants/constants";
import { API_URL } from "../constants/env";

export const requestObj = axios.create({
  baseURL: API_URL,
});
requestObj.interceptors.request.use((config) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (!token) {
    return config;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

requestObj.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === STATUS_CODE.UNAUTHORIZED) {
      localStorage.clear();
    }
    throw error;
  },
);

export const postAPIService = (
  url: string,
  body?: object,
  responseType?: ResponseType,
) => {
  return requestObj
    .post(url, body, { responseType })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data;
    });
};

export const putAPIService = (url: string, body?: object) => {
  return requestObj
    .put(url, body)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response;
    });
};

export const getAPIService = (
  url: string,
  params?: object,
  withCredentials?: boolean,
) => {
  return requestObj
    .get(url, {
      params,
      withCredentials,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response;
    });
};

export const deleteAPIService = (url: string, data?: object) => {
  return requestObj
    .delete(url, { data })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response;
    });
};
