import {
  getAPIService,
  postAPIService,
  deleteAPIService,
  putAPIService,
} from "./BaseServce";
import { Shot, ShotCompact } from "../models/Shot";

export const getShots = async (projectId: number) => {
  const res = await getAPIService("/shots", { project_id: projectId });
  return res.data.results as ShotCompact[];
};

export const createShot = async (
  projectId: number,
  fileName: string,
  footagePath: string,
) => {
  const res = await postAPIService(`/shots`, {
    project_id: projectId,
    file_name: fileName,
    footage_path: footagePath,
  });
  return res.data as ShotCompact;
};

export const getShotDetail = async (shotId: number) => {
  const res = await getAPIService(`/shots/${shotId}`, {});
  return res.data as Shot;
};

export const renameShot = async (id: number, name: string) => {
  const res = await putAPIService(`/shots/${id}`, { name });
  return res.data;
};

export const deleteShot = async (id: number) => {
  const res = await deleteAPIService(`/shots/${id}`);
  return res.data;
};

export const updateUseHq = async (shotId: number) => {
  const res = await postAPIService(`shots/${shotId}/use-hq`, {});
  return res.data;
};

export const runInference = async (id: number) => {
  const res = await postAPIService(`/shots/${id}/run-inference`);
  return res.data;
};

export const canRunInference = async (id: number) => {
  const res = await getAPIService(`/shots/${id}/run-inference`);
  return res.data;
};
