if (
  typeof HTMLVideoElement !== "undefined" &&
  !("requestVideoFrameCallback" in HTMLVideoElement.prototype) &&
  "getVideoPlaybackQuality" in HTMLVideoElement.prototype
) {
  HTMLVideoElement.prototype._rvfcpolyfillmap = {};
  HTMLVideoElement.prototype.requestVideoFrameCallback = function rvfc(
    callback,
  ) {
    const handle = performance.now();
    const quality = this.getVideoPlaybackQuality();
    let baseline =
      this.mozPaintedFrames ||
      this.mozPresentedFrames ||
      quality.totalVideoFrames - quality.droppedVideoFrames;
    const check = function (old, now) {
      const newquality = this.getVideoPlaybackQuality();
      const presentedFrames =
        this.mozPaintedFrames ||
        this.mozPresentedFrames ||
        newquality.totalVideoFrames - newquality.droppedVideoFrames;
      if ((!presentedFrames || presentedFrames !== baseline) && !this.seeking) {
        const processingDuration =
          this.mozFrameDelay ||
          newquality.totalFrameDelay - quality.totalFrameDelay ||
          0;
        const timediff = now - old; // HighRes diff
        callback(now, {
          presentationTime: now + processingDuration * 1000,
          expectedDisplayTime: now + timediff,
          width: this.videoWidth,
          height: this.videoHeight,
          mediaTime: Math.max(0, this.currentTime || 0) - timediff / 1000,
          presentedFrames,
          processingDuration,
        });
        delete this._rvfcpolyfillmap[handle];
      } else {
        baseline = 0;
        this._rvfcpolyfillmap[handle] = requestAnimationFrame(() => {
          const newer = performance.now();
          check(now, newer);
        });
      }
    }.bind(this);
    this._rvfcpolyfillmap[handle] = requestAnimationFrame(() => {
      const newer = performance.now();
      check(handle, newer);
    });
    return handle;
  };

  HTMLVideoElement.prototype.cancelVideoFrameCallback = function cvfc(handle) {
    cancelAnimationFrame(this._rvfcpolyfillmap[handle]);
    delete this._rvfcpolyfillmap[handle];
  };
}
