import { Button, Checkbox } from "@mui/material";
import { useFormik, Field, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as RightArrowIcon } from "assets/right-arrow.svg";
import { InputField } from "components/InputField";
import {
  IntroGuides,
  INTRO_GUIDES,
  AUTH_TOKEN,
  AUTH_USER,
} from "constants/constants";

import styles from "./SlapshotRegisterPage.module.scss";
import { SLAPSHOT_HOME_URL, SLAPSHOT_LOGIN_URL } from "../../constants/urls";
import { register, validateInviteToken } from "../../services/LoginService";

const SlapshotRegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] =
    useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      inviteCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string(),
      email: Yup.string()
        .email("Invalid email")
        .required("Email id is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/^(?![0-9]*$)/, "Password cannot be entirely numeric")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Passwords must match")
        .required("Please retype you password."),
      inviteCode: Yup.string(),
    }),
    onSubmit: async (values, { setStatus }) => {
      setIsLoading(true);
      const res = await register(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.inviteCode,
      )
        .catch((err) => {
          setStatus(err.detail);
        })
        .finally(() => {
          setIsLoading(false);
          localStorage.setItem(
            INTRO_GUIDES,
            JSON.stringify(Object.values(IntroGuides)),
          );
        });
      localStorage.setItem(AUTH_TOKEN, res.token);
      localStorage.setItem(AUTH_USER, JSON.stringify(res.data));
      navigate(SLAPSHOT_HOME_URL);
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token && !formik.values.inviteCode) {
      validateInviteToken(token).then((res) => {
        formik.setValues((v) => {
          return { ...v, email: res.email, inviteCode: token };
        });
      });
    }
  }, [formik, location.search]);

  const isDisabled = () => {
    return (
      isLoading ||
      !!formik.errors.firstName ||
      !!formik.errors.email ||
      !!formik.errors.password ||
      !!formik.errors.confirmPassword ||
      !!formik.errors.inviteCode ||
      !agreeToTermsAndConditions
    );
  };

  return (
    <FormikProvider value={formik}>
      <form className={styles.registerContainer} onSubmit={formik.handleSubmit}>
        <div className={styles.welcomeContainer}>
          <h3>
            Register on <span>Slapshot</span>
          </h3>
          <p>Sign up to create your account</p>
        </div>
        <div className={styles.formContainer}>
          {formik.status && (
            <div className={styles.formError}>{formik.status}</div>
          )}
          <div className={styles.fieldRow}>
            <Field name="firstName" component={InputField} label="First Name" />
            <Field name="lastName" component={InputField} label="Last Name" />
          </div>
          <Field name="email" component={InputField} label="Email address" />
          <Field
            name="password"
            type="password"
            component={InputField}
            placeholder="* * * * * * * * *"
            label="Password"
          />
          <Field
            name="confirmPassword"
            type="password"
            component={InputField}
            label="Confirm Password"
          />
        </div>
        <div className={styles.actions}>
          <div className={styles.termsContainer}>
            <Checkbox
              name="termsAndConditions"
              checked={agreeToTermsAndConditions}
              onChange={() => {
                setAgreeToTermsAndConditions(!agreeToTermsAndConditions);
              }}
              className={styles.checkbox}
            />
            <p>
              I agree to Slapshot's&nbsp;
              <a
                href="https://ssai-public-assets.s3.us-east-2.amazonaws.com/admin-public-media/agreements/Slapshot+-+Terms+and+Conditions+-+Oct+5th+2024.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
          <Button
            type="submit"
            disabled={isDisabled()}
            className="slapshotAuthButton"
          >
            Sign up
            <RightArrowIcon style={{ marginLeft: "12px" }} />
          </Button>
        </div>
        <div className={styles.loginContainer}>
          <p>Already have an account?</p>
          <Link to={SLAPSHOT_LOGIN_URL} className={styles.login}>
            Sign in here
          </Link>
        </div>
      </form>
    </FormikProvider>
  );
};

export default SlapshotRegisterPage;
