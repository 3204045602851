import { Button, ClickAwayListener, Slide } from "@mui/material";
import cx from "classnames";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as KeyBindIcon } from "assets/cmd-k.svg";
import { ReactComponent as FolderHeartPurple } from "assets/folder-heart-purple.svg";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import Loader from "components/Loader";
import { Project } from "models/Project";
import { getProjects } from "services/ProjectService";
import { formatLongText } from "utils";

import styles from "./ProjectSearchBar.module.scss";

const ProjectSearchBar = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const fetchProjects = useRef(
    debounce((term) => {
      getProjects(true, term).then((results) => setProjects(results));
    }, 500),
  ).current;

  const onSearch = (event) => {
    const term = event.target.value.trim();
    if (term !== searchTerm) {
      setSearchTerm(term);
      setIsLoading(true);
    }
  };

  const openModal = () => {
    fetchProjects(searchTerm);
    setIsLoading(true);
    setShowModal(true);
    inputRef.current?.focus();
  };

  const closeModal = () => {
    setShowModal(false);
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.blur();
    }
    setProjects(null);
    setSearchTerm("");
  };

  window.addEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
      openModal();
    } else if (showModal && e.key === "Escape") closeModal();
  });

  useEffect(() => {
    if (inputRef.current === document.activeElement) {
      fetchProjects.cancel();
      fetchProjects(searchTerm);
    }
  }, [fetchProjects, searchTerm]);

  useEffect(() => {
    if (projects) setIsLoading(false);
  }, [projects]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={cx(styles.searchBar, [showModal && styles.searchBarOnFocus])}
        onClick={openModal}
      >
        <SearchIcon />
        <input
          type="text"
          placeholder="Search Project"
          className={styles.input}
          onChange={onSearch}
          ref={inputRef}
        />
        <KeyBindIcon />
      </div>
      {showModal && (
        <ClickAwayListener
          onClickAway={(e) => {
            if (!containerRef.current?.contains(e.target as Node)) closeModal();
          }}
        >
          <div className={styles.results}>
            <Loader isLoading={isLoading} size={20} />

            {!isLoading && !projects?.length && (
              <div className={styles.notFound}>No projects found</div>
            )}

            {!isLoading && !!projects?.length && (
              <>
                {!searchTerm && (
                  <div className={styles.recentText}>Recently visited</div>
                )}
                <div className={styles.scrollCon}>
                  {projects.map((project) => {
                    return (
                      <Button
                        className={styles.option}
                        key={project.id}
                        onClick={() => {
                          closeModal();
                          navigate(`projects/${project.id}`);
                        }}
                      >
                        <div className={styles.optionCon}>
                          <FolderHeartPurple className={styles.projectIcon} />

                          <div className={styles.optionSubCon}>
                            <span className={styles.optionName}>
                              {formatLongText(project.name, 20)}
                            </span>

                            <span className={styles.shotsCount}>
                              {`${project.shots_count} ${
                                project.shots_count === 1 ? "Shot" : "Shots"
                              }`}
                            </span>
                          </div>
                        </div>
                      </Button>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ProjectSearchBar;
