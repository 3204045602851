import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import { Button, Popover } from "@mui/material";
import cx from "classnames";
import _ from "lodash";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  SetStateAction,
} from "react";

import DotElastic from "components/DotElastic";
import Guide from "components/Guide";
import VideoPlayer, { VideoPlayerRefAttributes } from "components/VideoPlayer";
import { captureSnapshot } from "components/VideoPreview/video_preview_utils";
import { IntroGuides, STATUSES } from "constants/constants";
import { formatLongText } from "utils";

import styles from "./ReviewPanel.module.scss";
import { Cutout } from "../../models/Cutout";
import { LayerCompact, LayerReview } from "../../models/Layer";

interface ReviewPanelParams {
  layerId: number;
  shotId: number;
  cutouts: Cutout[];
  onStoreCutout: () => void;
  layers: LayerCompact[];
  videoURL: string;
  fps: number;
  setImageData: React.Dispatch<SetStateAction<string | null>>;
  setSelectedFrame: React.Dispatch<SetStateAction<number | null>>;
  addAnotation: boolean;
  layer: LayerReview | null;
  guides: string[];
  noMoreTips: (tip?: string) => void;
}

const ReviewPanel: React.FC<ReviewPanelParams> = ({
  layerId,
  layer,
  cutouts,
  videoURL,
  fps,
  setImageData,
  setSelectedFrame,
  addAnotation,
  guides,
  noMoreTips,
}) => {
  const totalFrames = layer?.shot.frame_count || 0;
  const [isProcessing, setIsProcessing] = useState(false);

  const [sortedCutouts, setSortedCutouts] = useState<Cutout[]>([]);
  const [selectedCutout, setSelectedCutout] = useState<Cutout | null>(null);
  const [markerPositions, setMarkerPositions] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const playerRef = useRef<VideoPlayerRefAttributes>(null);

  const worker: Worker = useMemo(
    () => new Worker(new URL("../../worker.ts", import.meta.url)),
    [],
  );

  useEffect(() => {
    worker.onmessage = (ev) => {
      if (!layer || !selectedCutout) return;
      const { id, results, indexes, width, height } = ev.data;
      if (selectedCutout?.id === id) {
        const datas = playerRef.current?.imageDatas || [];
        if (datas.length === 0) {
          playerRef.current?.setWidthHeight([width, height]);
          playerRef.current?.seekToFrame(
            selectedCutout.frame - layer.shot.first_frame_number,
          );
        }
        results.forEach((bitMap, index) => {
          datas[indexes[index]] = bitMap;
        });
        playerRef.current?.setImageDatas(datas);
      }
    };
  }, [selectedCutout, layer, worker]);

  useEffect(() => {
    if (!layer || !selectedCutout || isLoading) {
      return;
    }

    if (selectedCutout.inference?.status === STATUSES.COMPLETED) {
      playerRef.current?.setImageDatas([]);
      worker.postMessage({
        startIndex: 1,
        endIndex: layer.review_urls.length,
        reviewUrls: layer.review_urls,
        statuses: layer.statuses,
        selectedCutout,
        firstFrame: layer.shot.first_frame_number,
      });
    } else if (selectedCutout.image_url) {
      const datas: ImageBitmap[] = [];
      fetch(selectedCutout.image_url)
        .then((response) => response.blob())
        .then((blob) => createImageBitmap(blob))
        .then((imageBitmap) => {
          datas[selectedCutout.frame - layer.shot.first_frame_number] =
            imageBitmap;
          playerRef.current?.seekToFrame(
            selectedCutout.frame - layer.shot.first_frame_number,
          );
          playerRef.current?.setImageDatas(datas);
        });
    }
  }, [worker, layer, selectedCutout, isLoading]);

  const getStatusIcon = (id: number) => {
    if (layer?.statuses[id] === "FAILED") {
      return <ErrorIcon fontSize="small" color="error" />;
    }
    if (layer?.statuses[id] === "IN_PROGRESS") {
      return <PendingIcon fontSize="small" color="warning" />;
    }
    if (layer?.statuses[id] === "COMPLETED") {
      return <CheckCircleIcon fontSize="small" color="success" />;
    }
    return <div />;
  };

  useEffect(() => {
    const filteredCutouts = _.uniqBy(
      cutouts.sort((c1, c2) => {
        if (c1.inference && c2.inference) {
          return c1.inference?.created > c2.inference?.created ? -1 : 1;
        }
        return 0;
      }),
      "frame",
    );
    setSortedCutouts(filteredCutouts);
    setSelectedCutout(filteredCutouts.length ? filteredCutouts[0] : null);
  }, [cutouts]);

  useEffect(() => {
    playerRef.current?.setImageDatas([]);
    playerRef.current?.pause();
  }, [layerId]);

  useEffect(() => {
    if (addAnotation && layer) {
      const eVideo = playerRef.current?.videoRef.current;
      if (eVideo) {
        setImageData(captureSnapshot(eVideo).toDataURL("image/jpeg", 1.0));
        setSelectedFrame(
          playerRef.current.currentFrame + layer.shot.first_frame_number,
        );
      }
    }
  }, [addAnotation, layer, setImageData, setSelectedFrame]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoverCutout, setHoverCutout] = React.useState<Cutout | null>(null);

  const handleCutoutPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    c: Cutout,
  ) => {
    setAnchorEl(event.currentTarget);
    setHoverCutout(c);
    noMoreTips(IntroGuides.FirstCutout);
  };

  const handleCutoutPopoverClose = () => {
    setAnchorEl(null);
    setHoverCutout(null);
  };

  const getMarkerPositions = useCallback(() => {
    setSortedCutouts((sCutouts) => {
      if (!sCutouts.length || !layer) {
        return sCutouts;
      }
      const cMarks = sCutouts
        .map((cutOut) =>
          document.querySelector(
            `.MuiSlider-mark[data-index='${
              cutOut.frame - layer.shot.first_frame_number
            }']`,
          ),
        )
        .filter((mark) => !!mark);
      if (cMarks.length !== sCutouts.length) {
        setTimeout(getMarkerPositions, 1000);
        return sCutouts;
      }
      setMarkerPositions(
        cMarks.map((cMark) => cMark?.getBoundingClientRect().left || 0),
      );
      return sCutouts;
    });
  }, [layer]);

  useEffect(() => {
    if (getMarkerPositions) {
      getMarkerPositions();
      window.addEventListener("resize", getMarkerPositions);
      return () => {
        window.removeEventListener("resize", getMarkerPositions);
      };
    }
    return () => {};
  }, [getMarkerPositions, sortedCutouts]);

  useEffect(() => {
    setIsLoading(true);
  }, [layer]);

  return (
    <div className={styles.container}>
      {!layer && (
        <div className={styles.hintContainer}>
          <h4>Loading results...</h4>
          <DotElastic />
        </div>
      )}
      {layer && (
        <div className={styles.mainContainer}>
          <VideoPlayer
            ref={playerRef}
            videoURL={videoURL}
            totalFrames={totalFrames}
            firstFrame={layer.shot.first_frame_number}
            setIsLoading={setIsLoading}
            fps={fps}
            startTime={layer.shot.start_time}
            color={layer.color}
          />
          {!isLoading && (
            <div className={styles.cutouts}>
              <div className={styles.cutOutLayerCon}>
                <div className={styles.cutOutLayerName}>
                  {formatLongText(layer.name, 4)}
                </div>
                <div
                  className={styles.cutOutLayerLine}
                  style={{ backgroundColor: layer.color }}
                />
              </div>
              {sortedCutouts.map((c, index) => {
                if (markerPositions[index])
                  return (
                    <div
                      key={c.id}
                      style={{
                        left: `${markerPositions[index] - 6}px`,
                      }}
                      className={styles.markerCon}
                    >
                      <Guide
                        id={IntroGuides.FirstCutout}
                        guides={guides}
                        open={index === 0 && !hoverCutout}
                        title="New annotation added!"
                        description="Hover on this frame layer to view your annotation."
                        placement="top"
                        noMoreTips={noMoreTips}
                      >
                        <div />
                      </Guide>
                      <Button
                        disabled={isProcessing}
                        onMouseEnter={(e) => handleCutoutPopoverOpen(e, c)}
                        onMouseLeave={handleCutoutPopoverClose}
                        style={{
                          backgroundColor: layer.color,
                        }}
                        onClick={() => {
                          playerRef.current?.setImageDatas([]);
                          if (selectedCutout && selectedCutout.id === c.id) {
                            setSelectedCutout(null);
                          } else {
                            setSelectedCutout(c);
                          }
                          playerRef.current?.seekToFrame(
                            c.frame - layer.shot.first_frame_number,
                          );
                        }}
                        variant="contained"
                        size="small"
                        className={cx(
                          styles.cutoutIcon,
                          c.id === selectedCutout?.id &&
                            styles.cutoutIconActive,
                        )}
                      />
                    </div>
                  );
                return null;
              })}
            </div>
          )}

          <Popover
            sx={{
              pointerEvents: "none",
              "& .MuiPaper-root": {
                backgroundColor: "#111113",
                borderRadius: "0px",
                border: "1px solid #CCCCDC1F",
                padding: "8px",
              },
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handleCutoutPopoverClose}
            disableRestoreFocus
          >
            <div className={styles.popoverButtons}>
              {getStatusIcon(hoverCutout?.inference?.id || -1)}
              <p>{hoverCutout && hoverCutout?.frame}</p>
            </div>
            <div className={styles.hoverImage}>
              {hoverCutout?.image_url && (
                <img
                  className={styles.hoverImage}
                  src={hoverCutout.image_url}
                  alt="cutout"
                  crossOrigin="anonymous"
                  onError={(
                    e: React.SyntheticEvent<HTMLImageElement, Event>,
                  ) => {
                    e.currentTarget.style.display = "none";
                  }}
                />
              )}
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default ReviewPanel;
