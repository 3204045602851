import { motion } from "framer-motion";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Line, Image } from "react-konva";
import useImage from "use-image";

import styles from "./KonvaCanvas.module.scss";

import KonvaEventObject = Konva.KonvaEventObject;

export const TOOL_PEN = "Pen";
export const TOOL_LINE = "Line";

export const TOOL_POLYGON = "Polygon";

export const MODE_DRAW = "draw";
export const MODE_ERASE = "erase";
export const MODE_MAGIC = "magic";

export interface Region {
  points: Vector2d[];
  tool: string;
  strokeWidth: number;
  mode: string;
}
interface KonvaCanvasParams {
  strokeWidth: number;
  tool: string;
  mode: string;
  width: number;
  height: number;
  setRegions: (regions: Region[]) => void;
  regions: Region[];
  backgroundImage: string;
  className?: string;
  isForReview: boolean;
  onUpdate: () => void;
  color: string;
  onMagicModeMouseMove: (point: { x: number; y: number } | null) => void;
  onMagicModeClick: (point: { x: number; y: number }) => void;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  canPan: boolean;
  setCanPan: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface KonvaCanvasRef {
  getDataURL: () => string | null;
  resetZoom: () => void;
  zoomToLastPosition: (zoomScale: number) => void;
}

const KonvaCanvas = React.forwardRef<KonvaCanvasRef, KonvaCanvasParams>(
  (props, ref) => {
    const {
      strokeWidth,
      tool,
      mode,
      width,
      height,
      setRegions,
      regions,
      backgroundImage,
      className,
      isForReview,
      onUpdate,
      color,
      onMagicModeMouseMove,
      onMagicModeClick,
      setScale,
      scale,
      canPan,
      setCanPan,
    } = props;
    const stageRef = React.useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const [image] = useImage(backgroundImage, "anonymous");
    const imageRef = useRef<Konva.Image>(null);

    const [mousePosition, setMousePosition] = useState({ x: -50, y: -50 });
    const [zoomAnchor, setZoomAnchor] = useState({
      x: width / 2,
      y: height / 2,
    });

    const getRelativePointerPosition = (node: Konva.Stage) => {
      const transform = node.getAbsoluteTransform().copy();
      transform.invert();
      const pos = node.getStage().getPointerPosition();
      if (!pos) {
        return null;
      }
      return transform.point(pos);
    };

    React.useImperativeHandle(ref, () => ({
      getDataURL: (): string | null => {
        if (!stageRef || !stageRef.current) {
          return null;
        }
        (stageRef.current as Konva.Stage).position({ x: 0, y: 0 });
        (stageRef.current as Konva.Stage).scale({ x: 1, y: 1 });
        return (stageRef.current as Konva.Stage).toDataURL({ pixelRatio: 4 });
      },

      resetZoom: (): void => {
        if (!stageRef || !stageRef.current) {
          return;
        }
        (stageRef.current as Konva.Stage).position({ x: 0, y: 0 });
        (stageRef.current as Konva.Stage).scale({ x: 1, y: 1 });
        setScale(1);
        setZoomAnchor({ x: width / 2, y: height / 2 });
      },

      zoomToLastPosition: (zoomScale: number): void => {
        if (!stageRef.current) return;
        const stage = stageRef.current as Konva.Stage;
        stage.scale({ x: zoomScale, y: zoomScale });
        stage.position({
          x: zoomAnchor.x * (1 - zoomScale),
          y: zoomAnchor.y * (1 - zoomScale),
        });
      },
    }));

    const onWheel = (e: KonvaEventObject<WheelEvent>) => {
      e.evt.preventDefault();
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }

      const pointer = stage.getPointerPosition();
      if (!pointer) {
        return;
      }
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      setZoomAnchor({ x: mousePointTo.x, y: mousePointTo.y });

      if (mode === MODE_MAGIC) {
        onMagicModeMouseMove(null);
      }

      const scaleBy = 1 + Math.abs(e.evt.deltaY) * 0.001;
      let direction = e.evt.deltaY < 0 ? 1 : -1;
      if (e.evt.ctrlKey) {
        direction = -direction;
      }
      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      if (newScale < 1) {
        setScale(1);
        stage.scale({ x: 1, y: 1 });
        stage.position({ x: 0, y: 0 });
        return;
      }

      setScale(newScale);
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    };

    const onMouseMove = (
      e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
    ) => {
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }

      const pos = stage.getPointerPosition();
      if (!pos) {
        return;
      }
      const lastPos = mousePosition;
      setMousePosition({
        x: pos.x,
        y: pos.y,
      });

      if (mode === MODE_MAGIC && !isDragging && !canPan) {
        const point = getRelativePointerPosition(stage);
        if (!point) return;
        onMagicModeMouseMove(point);
        return;
      }

      if (isDragging) {
        const stagePos = stage.position();
        const newStagePos = {
          x: stagePos.x + (pos.x - lastPos.x),
          y: stagePos.y + (pos.y - lastPos.y),
          scale: stage.scaleX(),
        };

        // Restrict dragging to boundary of image
        if (newStagePos.x / newStagePos.scale > 0) newStagePos.x = 0;
        else if (newStagePos.x < width * (1 - newStagePos.scale))
          newStagePos.x = width * (1 - newStagePos.scale);

        if (newStagePos.y / newStagePos.scale > 0) newStagePos.y = 0;
        else if (newStagePos.y < height * (1 - newStagePos.scale))
          newStagePos.y = height * (1 - newStagePos.scale);

        stage.position({ x: newStagePos.x, y: newStagePos.y });
      } else if (mode !== MODE_MAGIC) {
        const point = getRelativePointerPosition(stage);
        if (!point) {
          return;
        }
        if (!isDrawing) {
          return;
        }
        const lastRegion = { ...regions[regions.length - 1] };
        if (!lastRegion.points) return;
        lastRegion.points = lastRegion.points.concat([
          { x: point.x, y: point.y },
        ]);
        regions.splice(regions.length - 1, 1);
        setRegions(regions.concat([lastRegion]));
      }
    };

    const onMouseDown = (
      e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
    ) => {
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }

      if (canPan || (e.evt instanceof MouseEvent && e.evt.button === 1)) {
        setCanPan(true);
        setIsDragging(true);

        if (mode === MODE_MAGIC) {
          onMagicModeMouseMove(null);
        }
        return;
      }

      if (mode === MODE_MAGIC) return;

      setIsDrawing(true);
      const point = getRelativePointerPosition(stage);
      if (!point) {
        return;
      }
      const region = {
        points: [{ x: point.x, y: point.y }],
        strokeWidth,
        tool,
        mode,
      };
      setRegions(regions.concat([region]));
    };

    const onMouseLeave = () => {
      if (isDragging) {
        setIsDragging(false);
        setCanPan(false);
      }

      setMousePosition({
        x: -50,
        y: -50,
      });

      if (mode === MODE_MAGIC) {
        onMagicModeMouseMove(null);
        return;
      }

      if (regions.length > 0) {
        setTimeout(() => {
          onUpdate();
        });
      }

      setIsDrawing(false);
    };

    const onMouseUp = (
      e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
    ) => {
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }

      if (isDragging) {
        setIsDragging(false);
        if (e.evt instanceof MouseEvent && e.evt.button === 1) setCanPan(false);

        const center = {
          x: (width / 2 - stage.x()) / stage.scaleX(),
          y: (height / 2 - stage.y()) / stage.scaleY(),
        };
        setZoomAnchor({ x: center.x, y: center.y });
        return;
      }

      const point = getRelativePointerPosition(stage);
      if (!point) {
        return;
      }

      if (mode === MODE_MAGIC) {
        onMagicModeClick(point);
        return;
      }

      if (!isDrawing) return;
      const lastRegion = { ...regions[regions.length - 1] };
      if (lastRegion.tool === TOOL_LINE) {
        lastRegion.points = [
          { x: lastRegion.points[0].x, y: lastRegion.points[0].y },
          { x: point.x, y: point.y },
        ];
        regions.splice(regions.length - 1, 1);
        setRegions(regions.concat([lastRegion]));
      }
      if (regions.length === 1 && regions[0].points.length === 1) {
        regions[0].points = [regions[0].points[0], regions[0].points[0]];
        setRegions([...regions]);
      }
      setIsDrawing(false);
      setTimeout(() => {
        onUpdate();
      });
    };

    const getFillColor = (region: Region) => {
      if (region.tool !== TOOL_POLYGON) {
        return " ";
      }
      return region.mode === MODE_DRAW ? color : "black";
    };

    useEffect(() => {
      setZoomAnchor({ x: width / 2, y: height / 2 });
    }, [height, width]);

    useEffect(() => {
      if (image && imageRef.current) {
        imageRef.current.image(image);
        imageRef.current.getLayer()?.batchDraw();
      }
    }, [image]);

    return (
      <>
        <Stage
          className={className}
          ref={stageRef}
          width={width}
          height={height}
          onWheel={onWheel}
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          onMouseLeave={onMouseLeave}
          onTouchMove={onMouseMove}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchEnd={onMouseUp}
          onContextMenu={(e) => {
            e.evt.preventDefault();
          }}
        >
          <Layer imageSmoothingEnabled={false}>
            <Image
              width={width}
              height={height}
              ref={imageRef}
              image={undefined}
            />
          </Layer>
          {mode !== MODE_MAGIC && (
            <Layer imageSmoothingEnabled={false}>
              {regions.map((region, index) => {
                return (
                  <Line
                    key={index}
                    points={region.points.flatMap((p) => [p.x, p.y])}
                    stroke={region.mode === MODE_DRAW ? color : "black"}
                    strokeWidth={region.strokeWidth}
                    lineCap="round"
                    lineJoin="round"
                    closed={region.tool === TOOL_POLYGON}
                    fill={getFillColor(region)}
                  />
                );
              })}
            </Layer>
          )}
        </Stage>
        {mode !== MODE_MAGIC && !canPan && (
          <motion.div
            className={styles.cursor}
            variants={{
              default: {
                x: mousePosition.x - (strokeWidth * scale) / 2,
                y: mousePosition.y - (strokeWidth * scale) / 2,
                width: strokeWidth * scale,
                height: strokeWidth * scale,
                backgroundColor: mode === MODE_DRAW ? color : "black",
                visibility: mousePosition.x < 0 ? "hidden" : "visible",
              },
            }}
            animate="default"
            transition={{
              type: "tween",
              ease: "linear",
              duration: 0,
            }}
          />
        )}
      </>
    );
  },
);
KonvaCanvas.displayName = "KonvaCanvas";
export default KonvaCanvas;
