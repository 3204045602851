import { postAPIService } from "./BaseServce";

export const getSignedUrlByProject = async (projectId: string, file: File) => {
  const res = await postAPIService("/s3_signed_url_project", {
    project_id: projectId,
    file: {
      name: file.name,
      size: file.size,
    },
  });
  return res.data;
};

export const completeMultipartUpload = async (
  uploadId: string,
  key: string,
  parts,
) => {
  const res = await postAPIService("/complete_multipart_upload", {
    upload_id: uploadId,
    key,
    parts,
  });
  return res.data;
};

export const abortMultipartUpload = async (uploadId: string, key: string) => {
  const res = await postAPIService("/abort_multipart_upload", {
    upload_id: uploadId,
    key,
  });
  return res.data;
};
