import { datadogRum } from "@datadog/browser-rum";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/dist/ReactToastify.css";
import { getEnvName, EnvName } from "constants/env";

import {
  SLAPSHOT_LOGIN_URL,
  SLAPSHOT_REGISTER_URL,
  SLAPSHOT_FORGOT_PASSWORD_URL,
  SLAPSHOT_AUTH,
  SLAPSHOT_HOME_URL,
  SLAPSHOT_PROFILE_URL,
  SLAPSHOT_RESET_PASSWORD_URL,
  SLAPSHOT_SHOT_URL,
  SLAPSHOT_LAYER_URL,
  SLAPSHOT_PROJECT_URL,
  SLAPSHOT_DOWNLOADS,
  SLAPSHOT_WORKSPACES_URL,
  SLAPSHOT_INVITE_USER,
  SLAPSHOT_INVITE_ACCEPT,
} from "./constants/urls";
import SlapshotAuthLayout from "./layouts/SlapshotAuthLayout";
import SlapshotMainLayout from "./layouts/SlapshotMainLayout";
import SlapshotPasswordLayout from "./layouts/SlapshotPasswordLayout";
import InviteAccept from "./pages/InviteAccept";
import InviteUser from "./pages/InviteUser";
import ProfilePage from "./pages/NewProfile";
import ProjectPage from "./pages/Project";
import SlapshotForgotPasswordPage from "./pages/SlapshotForgotPassword";
import SlapshotLoginPage from "./pages/SlapshotLogin";
import SlapshotRegisterPage from "./pages/SlapshotRegister";
import SlapshotResetPasswordPage from "./pages/SlapshotResetPassword";
import WorkspacesPage from "./pages/Workspaces";

let env = "local";
switch (getEnvName()) {
  case EnvName.PRODUCTION:
    env = "production";
    break;
  case EnvName.STAGING:
    env = "staging";
    break;
}
datadogRum.init({
  applicationId: "e5ede1ab-ebbb-459e-b2ba-9636030332c4",
  clientToken: "pub3c0aeb9273dd3ba0e7b2d87fc7c067fe",
  env,
  site: "us5.datadoghq.com",
  service: "slapshot",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const Root = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={SLAPSHOT_AUTH} element={<SlapshotAuthLayout />}>
          <Route path={SLAPSHOT_LOGIN_URL} element={<SlapshotLoginPage />} />
          <Route
            path={SLAPSHOT_REGISTER_URL}
            element={<SlapshotRegisterPage />}
          />
        </Route>
        <Route path={SLAPSHOT_AUTH} element={<SlapshotPasswordLayout />}>
          <Route
            path={SLAPSHOT_FORGOT_PASSWORD_URL}
            element={<SlapshotForgotPasswordPage />}
          />
          <Route
            path={SLAPSHOT_RESET_PASSWORD_URL}
            element={<SlapshotResetPasswordPage />}
          />
        </Route>

        <Route path={SLAPSHOT_HOME_URL} element={<SlapshotMainLayout />}>
          <Route index element={<WorkspacesPage />} />
          <Route path={SLAPSHOT_DOWNLOADS} element={null} />
          <Route path={SLAPSHOT_PROFILE_URL} element={<ProfilePage />} />
          <Route path={SLAPSHOT_LAYER_URL} element={<ProjectPage />} />
          <Route path={SLAPSHOT_SHOT_URL} element={<ProjectPage />} />
          <Route path={SLAPSHOT_PROJECT_URL} element={<ProjectPage />} />
          <Route path={SLAPSHOT_WORKSPACES_URL} element={<WorkspacesPage />} />
          <Route path={SLAPSHOT_INVITE_USER} element={<InviteUser />} />
          <Route path={SLAPSHOT_INVITE_ACCEPT} element={<InviteAccept />} />
          <Route path="*" element={<WorkspacesPage />} />
        </Route>
      </Routes>
      <ToastContainer
        position="bottom-right"
        theme="dark"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        style={{ zIndex: 99999 }}
      />
    </BrowserRouter>
  );
};

export default Root;
