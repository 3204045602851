import {
  AutocompleteGetTagProps,
  useAutocomplete,
} from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Menu, MenuProps } from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "#CCCCDC",
    background: "#111113",
    padding: "5px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      borderRadius: "4px",
      fontSize: "14px",
      gap: "10px",
      "&:hover": {
        backgroundColor: "#222226",
      },
    },
  },
}));

const AutoCompleteInputWrapper = styled("div")(
  ({ theme }) => `
  border: 1px solid #2C2C2C;
  background-color: #16181E;
  border-radius: 6px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  color: #FFFFFFCC;

  &.focused {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: #16181E;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    color: #FFFFFFCC;
    font-size: 14px;
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

const ListItem = (props: TagProps) => {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
};

const StyledTag = styled(ListItem)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #16181E;
  border: 1px solid #FFFFFF4D;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  color: #FFFFFFCC;
  font-size: 14px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
    color: #FFFFFFCC !important;
  }
`,
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 480px;
  margin: 2px 0 0;
  padding: 10px;
  position: absolute;
  list-style: none;
  background-color: #16181E;
  overflow: auto;
  max-height: 250px;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  color: #CCCCDC;
  font-size: 14px;
  gap: 10px;
  & li {
    padding: 5px 12px;
    display: flex;
    margin: 5px;
    font-weight: 300;
    border-radius: 6px;
    & span {
      flex-grow: 1;
    }
    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #222226;
    font-weight: 500;
    border-radius: 6px;
    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: #222226;
    cursor: pointer;
    & svg {
      color: currentColor;
    }
  }
`,
);

export interface ProjectOptionType {
  id: number;
  name: string;
  shots_count: number;
  shot_thumbnails: string[];
}

interface ProjectAutoCompleteParams {
  projects: ProjectOptionType[];
  values: ProjectOptionType[];
  setValues: (values: ProjectOptionType[]) => void;
  className?: string;
}

const ProjectAutoComplete: React.FC<ProjectAutoCompleteParams> = ({
  projects,
  values,
  setValues,
  className,
}) => {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    value: values,
    onChange: (e, v) => {
      setValues(v);
    },
    multiple: true,
    options: projects,
    getOptionLabel: (option) => option.name,
  });

  return (
    <div className={className}>
      <div {...getRootProps()}>
        <AutoCompleteInputWrapper
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
        >
          {value.map((option: ProjectOptionType, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <StyledTag key={key} {...tagProps} label={option.name} />;
          })}
          <input placeholder="Add projects..." {...getInputProps()} />
        </AutoCompleteInputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof projects).map((option, index) => {
            const { key, ...optionProps } = getOptionProps({
              option,
              index,
            });
            return (
              <li key={key} {...optionProps}>
                <span>{option.name}</span>
                <CheckIcon fontSize="small" />
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </div>
  );
};

export default ProjectAutoComplete;
