export enum EnvName {
  LOCAL,
  STAGING,
  PRODUCTION,
}

export const getEnvName = () => {
  const domainParts = window.location.hostname.split(".");
  const domainSuffix = domainParts[domainParts.length - 1];
  if (domainSuffix === "ai") {
    return EnvName.PRODUCTION;
  }
  if (domainSuffix === "work" || domainSuffix === "dev") {
    return EnvName.STAGING;
  }
  return EnvName.LOCAL;
};

export const getApiUrl = () => {
  const domainParts = window.location.hostname.split(".");
  const domainSuffix = domainParts[domainParts.length - 1];
  const domainPrefix = domainParts[0];
  if (domainPrefix === "local") {
    return "https://local.slapshot.work:5000/api";
  }

  if (domainSuffix === "ai") {
    return "https://app.slapshot.ai/api";
  }
  if (domainSuffix === "work") {
    return "https://app.slapshot.work/api";
  }
  if (domainSuffix === "dev") {
    return "https://app.hotrock.dev/api";
  }
  return "http://127.0.0.1:5000/api";
};

export const getWebSocketUrl = () => {
  const domainParts = window.location.hostname.split(".");
  const domainSuffix = domainParts[domainParts.length - 1];
  const domainPrefix = domainParts[0];
  if (domainPrefix === "local") {
    return "wss://local.slapshot.work:5000/ws";
  }

  if (domainSuffix === "ai") {
    return "wss://app.slapshot.ai/ws";
  }
  if (domainSuffix === "work") {
    return "wss://app.slapshot.work/ws";
  }
  if (domainSuffix === "dev") {
    return "wss://app.hotrock.dev/ws";
  }
  return "ws://127.0.0.1:5000/ws";
};

// NOTE: this can be used to enable features only locally or in staging, e.g.
// export const FLAG_ENABLE_INVOICES = ENV === EnvName.LOCAL || ENV === EnvName.STAGING;
export const ENV = getEnvName();
export const API_URL = getApiUrl();
export const WEBSOCKET_URL = getWebSocketUrl();

export const SLAPSHOT_EARLY_ACCESS_URL =
  "https://www.slapshot.ai/early-access/";
export const SLAPSHOT_SUPPORT_URL = "https://support.slapshot.ai/en/support";

// IMPORTANT: Must match FILE_CHUNK_SIZE in slapshot/settings.py
export const FILE_CHUNK_SIZE = 10 * 1024 * 1024;
export const MAX_REQUESTS_COUNT = 5;
