import { Dictionary } from "lodash";

import { ShotCompact } from "models/Shot";

import { AUTH_USER } from "../constants/constants";

export const getUser = () => {
  const user = localStorage.getItem(AUTH_USER);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const getInitials = () => {
  const user = getUser();
  if (!user) {
    return "";
  }
  return user.name.split(" ").map((v: string) => v[0]);
};

export const getFirstName = () => {
  const user = getUser();
  if (!user) {
    return "";
  }
  return user.name.split(" ")[0];
};

export const getStatusString = (status: string) => {
  switch (status) {
    case "IN_PROGRESS":
      return "In Progress";
    case "COMPLETED":
      return "Completed";
    case "FAILED":
      return "Failed";
  }
  return "";
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "IN_PROGRESS":
      return "orange";
    case "COMPLETED":
      return "green";
    case "FAILED":
      return "red";
  }
  return "";
};

export const imageURLToDataURL = (
  imageURL: string,
  callback: (dataURL: string) => void,
): void => {
  fetch(imageURL)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          callback(reader.result);
        }
      };
      reader.readAsDataURL(blob);
    })
    .catch((error) => console.error("Error fetching image:", error));
};

export const formatDate = (dateString: string | Date) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year:
      date.getFullYear() !== new Date().getFullYear() ? "numeric" : undefined,
  });
};

export const mergeDict = (
  obj1: Dictionary<ShotCompact[]>,
  obj2: Dictionary<ShotCompact[]>,
): Dictionary<ShotCompact[]> => {
  const resultDict: Dictionary<ShotCompact[]> = { ...obj1 };

  for (const key of Object.keys(obj2)) {
    if (Object.hasOwn(resultDict, key)) {
      resultDict[key] = [...obj2[key], ...resultDict[key]];
    } else {
      resultDict[key] = obj2[key];
    }
  }
  return resultDict;
};

export const getCreationTimeText = (created: Date, text = "Created") => {
  const currentTime = new Date();
  const diff = currentTime.getTime() - new Date(created).getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Rough estimation, not precise
  const years = Math.floor(days / 365); // Rough estimation, not precise

  let creationText = "";
  if (seconds < 60) {
    creationText = `${text} a few seconds ago`;
  } else if (minutes < 60) {
    creationText = `${text} ${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    creationText = `${text} ${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (days < 7) {
    creationText = `${text} ${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (weeks < 4 || months === 0) {
    creationText = `${text} ${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    creationText = `${text} ${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    creationText = `${text} ${years} year${years !== 1 ? "s" : ""} ago`;
  }

  return creationText;
};

export const hexToRGB = (hexColor: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

export const arrayToObjectById = (arr) => {
  return arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
};

export const getTextInitials = (text: string) => {
  return text.split(" ").map((v: string) => v[0].toUpperCase());
};
