import { getAPIService, postAPIService } from "./BaseServce";

export const generateAPIKey = async () => {
  const res = await postAPIService("/api-keys");
  return res.data;
};

export const changePassword = async (password: string, newPassword: string) => {
  const res = await postAPIService("/auth/change-password", {
    password,
    new_password: newPassword,
  });
  return res.data;
};

export const getUserInfo = async () => {
  const res = await getAPIService("/user-info");
  return res.data;
};

export const getUserPreferences = async () => {
  const res = await getAPIService("/user-preferences");
  return res.data;
};

export const updateUserPreferences = async (preferences) => {
  const res = await postAPIService("/user-preferences", {
    preferences: JSON.stringify({ ...preferences }),
  });
  return res.data;
};

export const updateUserInfo = async (values) => {
  const res = await postAPIService("/user-info", {
    name: values.name,
    last_name: values.lastName,
    email: values.emailId,
    phone: values.contactNumber,
    timezone: values.timezone,
  });
  return res.data;
};

export const getUserProfilePicture = async () => {
  const res = await getAPIService("/profile-picture");
  return res.data;
};

export const updateProfilePicture = async (formData) => {
  const res = await postAPIService("/profile-picture", formData);
  return res.data;
};
