import React from "react";
import { Link, Outlet } from "react-router-dom";

import { ReactComponent as BackIcon } from "assets/back-icon.svg";
import Logo from "assets/logo.png";

import styles from "./SlapshotPasswordLayout.module.scss";
import { SLAPSHOT_LOGIN_URL } from "../../constants/urls";

const SlapshotPasswordLayout = () => {
  return (
    <div className="slapshotBackground">
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <img src={Logo} alt="Slapshot" className={styles.logo} />
        </div>
        <div className={styles.innerContainer}>
          <Link to={SLAPSHOT_LOGIN_URL} className={styles.backLink}>
            <BackIcon />
            Back
          </Link>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SlapshotPasswordLayout;
