import { Button } from "@mui/material";
import { useFormik, Field, FormikProvider } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as RightArrowIcon } from "assets/right-arrow.svg";
import { InputField } from "components/InputField";
import {
  SLAPSHOT_FORGOT_PASSWORD_URL,
  SLAPSHOT_HOME_URL,
  SLAPSHOT_REGISTER_URL,
} from "constants/urls";

import styles from "./SlapshotLoginPage.module.scss";
import { AUTH_TOKEN, AUTH_USER } from "../../constants/constants";
import { login } from "../../services/LoginService";

const SlapshotLoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Email id is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, { setStatus }) => {
      setIsLoading(true);
      const res = await login(values.email, values.password)
        .catch((err) => {
          setStatus(err.detail);
        })
        .finally(() => {
          setIsLoading(false);
        });
      localStorage.setItem(AUTH_TOKEN, res.token);
      localStorage.setItem(AUTH_USER, JSON.stringify(res.data));
      navigate(SLAPSHOT_HOME_URL);
    },
  });

  const isDisabled = () => {
    return isLoading || !!formik.errors.email || !!formik.errors.password;
  };

  return (
    <FormikProvider value={formik}>
      <form className={styles.loginContainer} onSubmit={formik.handleSubmit}>
        <div className={styles.welcomeContainer}>
          <h3>
            Welcome to <span>Slapshot</span>
          </h3>
          <p>Sign in to access your workspace</p>
        </div>
        <div className={styles.formContainer}>
          {formik.status && (
            <div className={styles.formError}>{formik.status}</div>
          )}
          <div className={styles.inputContainer}>
            <Field
              name="email"
              component={InputField}
              placeholder="Andrew@example.com"
              label="Username"
            />
          </div>
          <div className={styles.inputContainer}>
            <Field
              name="password"
              component={InputField}
              placeholder="* * * * * * * * *"
              label="Password"
              type="password"
            />
            <Link
              className={styles.forgotPassword}
              to={SLAPSHOT_FORGOT_PASSWORD_URL}
            >
              Forgot password?
            </Link>
          </div>
        </div>
        <Button
          type="submit"
          disabled={isDisabled()}
          className="slapshotAuthButton"
        >
          Sign in
          <RightArrowIcon style={{ marginLeft: "12px" }} />
        </Button>
        <div className={styles.registerContainer}>
          <p>New to Slapshot?</p>
          <Link to={SLAPSHOT_REGISTER_URL} className={styles.register}>
            Register here
          </Link>
        </div>
      </form>
    </FormikProvider>
  );
};

export default SlapshotLoginPage;
