import _, { debounce } from "lodash";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import EditMode, { EditModeRef, Data, Mask, Point } from "components/EditMode";
import Guide from "components/Guide";
import { AUTH_TOKEN, IntroGuides } from "constants/constants";
import { API_URL } from "constants/env";
import {
  createCutout,
  getEditableCutout,
  updateCutout,
} from "services/CutoutService";
import { createLayer } from "services/LayerService";

import styles from "./Workspace.module.scss";
import { CutoutInProgress } from "../../models/Cutout";
import { LayerCompact } from "../../models/Layer";
import { PredictionResponse } from "../../models/Prediction";
import { postAPIService } from "../../services/BaseServce";
import {
  ACTION_SUBSCRIBE_ANNOTATION_UPDATE,
  getRequestData,
  getSocket,
} from "../../services/WebSocketService";
import { handleImageScale } from "../../utils/sam";

interface WorkspaceParams {
  imageData: string;
  layers: LayerCompact[];
  frame: number;
  shotId: number;
  onBack: () => void;
  selectedLayer: LayerCompact | null;
  setSelectedLayer: React.Dispatch<React.SetStateAction<LayerCompact | null>>;
  setLayers: React.Dispatch<React.SetStateAction<LayerCompact[]>>;
  setCutoutInProgress: React.Dispatch<
    React.SetStateAction<CutoutInProgress | null>
  >;
  cutoutInProgress: CutoutInProgress | null;
  color: string;
  guides: string[];
  noMoreTips: (tip?: string) => void;
}

const Workspace: React.FC<WorkspaceParams> = ({
  imageData,
  layers,
  frame,
  shotId,
  onBack,
  selectedLayer,
  setSelectedLayer,
  setLayers,
  setCutoutInProgress,
  cutoutInProgress,
  color,
  guides,
  noMoreTips,
}) => {
  const [data, setData] = useState<Data | null>(null);
  const [points, setPoints] = useState<Point[]>([]);
  const [processing, setProcessing] = useState<boolean>(true);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [isLoadingMask, setIsLoadingMask] = useState(false);
  const [scale, setScale] = useState<number>(1);
  const [annotationId, setAnnotationId] = useState<number>();
  const [openSocket, setOpenSocket] = useState(false);
  const [maskImage, setMaskImage] = useState<Mask | null>(null);
  const [baseMask, setBaseMask] = useState<ImageData | null>(null);
  const [analysisCompleted, setAnalysisCompleted] = useState(false);

  const editModeRef = useRef<EditModeRef>(null);

  const worker: Worker = useMemo(() => {
    const instance = new Worker(
      new URL("../../uploadCutoutWorker.ts", import.meta.url),
    );
    instance.postMessage({
      type: "init",
      data: {
        baseUrl: API_URL,
        token: localStorage.getItem(AUTH_TOKEN),
      },
    });
    return instance;
  }, []);

  const samWorker: Worker = useMemo(() => {
    const instance = new Worker(new URL("../../samWorker.ts", import.meta.url));
    return instance;
  }, []);

  const processEmbedding = useCallback(
    (res: PredictionResponse) => {
      if (res.data.image_embeddings_url) {
        samWorker.postMessage({
          type: "processEmbedding",
          data: { res: JSON.stringify(res) },
        });
      } else if (res.data.data.error) {
        setAnnotationId(undefined);
        setProcessing(false);
      }
    },
    [samWorker],
  );

  // Stopping polling when embedding is generated...
  useEffect(() => {
    if (!data) {
      setAnnotationId(undefined);
      return;
    }
    const fromData = new FormData();
    fromData.append("image", new File([data.file], "image.png"));
    fromData.append("shot_id", shotId.toString());
    fromData.append("frame", frame.toString());
    postAPIService("/annotations", fromData).then((res: PredictionResponse) => {
      setAnnotationId(res.data.id);
      if (res.data.image_embeddings_url) {
        processEmbedding(res);
      } else {
        setOpenSocket(true);
      }
    });
  }, [data, shotId, frame, processEmbedding]);

  useEffect(() => {
    if (annotationId && openSocket) {
      const socket = getSocket("annotation");
      if (!socket) {
        return () => {};
      }
      socket.onopen = (event) => {
        socket.send(
          getRequestData(ACTION_SUBSCRIBE_ANNOTATION_UPDATE, {
            id: annotationId,
          }),
        );
      };
      socket.onmessage = (event) => {
        const res = JSON.parse(event.data) as PredictionResponse;
        processEmbedding(res);
      };
      return () => {
        socket.close();
        setOpenSocket(false);
      };
    }
    return () => {};
  }, [annotationId, openSocket, processEmbedding]);

  useEffect(() => {
    fetch(imageData)
      .then((it) => it.blob())
      .then((blob) => {
        const file = new File([blob], "file.jpeg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        const img = new Image();
        img.src = imageData;
        img.onload = () => {
          const { height, width, samScale } = handleImageScale(img);
          samWorker.postMessage({
            type: "modelScale",
            data: {
              modelScale: {
                height,
                width,
                samScale,
              },
            },
          });
          img.width = width;
          img.height = height;
          setData({
            width,
            height,
            file,
            img,
          });
        };
      });
  }, [imageData, samWorker]);

  const process = useRef(
    debounce((ps: Point[]) => {
      samWorker.postMessage({ type: "process", data: { points: ps } });
    }, 100),
  ).current;

  const getMask = useCallback(
    (ps: Point[]) => {
      process.cancel();
      process(ps);
    },
    [process],
  );

  const uploadCutout = useRef(
    debounce(
      async (
        cutout: CutoutInProgress,
        maskImageData: string,
        coverImageData: string,
      ) => {
        await updateCutout(cutout.id, true);
        worker.postMessage({
          type: "upload",
          data: {
            cutout,
            imageData,
            maskImageData,
            coverImageData,
          },
        });
      },
      500,
    ),
  ).current;

  const handleSave = useCallback(
    async (maskImageData: string, coverImageData: string) => {
      noMoreTips(IntroGuides.HoverObject);

      const layer =
        selectedLayer || (await createLayer(shotId).then((result) => result));
      if (!layer) return;

      layer.cover_image_url = coverImageData;
      if (!selectedLayer) setSelectedLayer(layer);
      setLayers((oldLayers) =>
        [layer, ...oldLayers.filter((l) => l.id !== layer.id)].sort(
          (l1, l2) => l2.id - l1.id,
        ),
      );

      if (cutoutInProgress) {
        uploadCutout.cancel();
        uploadCutout(cutoutInProgress, maskImageData, coverImageData);
      } else {
        createCutout(frame, layer.id, annotationId).then((cutout) => {
          if (cutout.cover_img_presigned_url && cutout.mask_img_presigned_url) {
            setCutoutInProgress(cutout);
            uploadCutout.cancel();
            uploadCutout(cutout, maskImageData, coverImageData);
          }
        });
      }
    },
    [
      annotationId,
      cutoutInProgress,
      frame,
      noMoreTips,
      selectedLayer,
      setCutoutInProgress,
      setLayers,
      setSelectedLayer,
      shotId,
      uploadCutout,
    ],
  );

  const generateBaseMask = useCallback(
    (maskImageURL: string, updateCanvas = false) => {
      if (!data) return;
      const canvasMask = document.createElement("canvas");
      const { width, height } = data;
      canvasMask.width = width;
      canvasMask.height = height;
      const ctxMask = canvasMask.getContext("2d");
      if (!ctxMask) return;
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = () => {
        ctxMask.drawImage(image, 0, 0, width, height);
        const maskData = ctxMask.getImageData(0, 0, width, height);
        setBaseMask(maskData);
        if (updateCanvas)
          editModeRef.current?.update(maskData.data, "rgb", false);
      };
      image.src = maskImageURL;
    },
    [data],
  );

  useEffect(() => {
    if (!cutoutInProgress && data) {
      setPoints([]);
      getMask([]);
      setMaskImage(null);
      setBaseMask(null);
      const maskInput = Array.from(
        { length: data.width * data.height },
        () => 0,
      );
      editModeRef.current?.update(
        new Uint8ClampedArray(maskInput),
        "pixel",
        false,
      );
      editModeRef.current?.selectMagicMode();
    }
  }, [cutoutInProgress, data, getMask]);

  useEffect(() => {
    if (selectedLayer && frame) {
      getEditableCutout(selectedLayer.id, frame).then((cutout) => {
        if (!cutout?.image_url) return;
        generateBaseMask(cutout.image_url, true);
        if (cutout.mask_img_presigned_url && cutout.cover_img_presigned_url)
          setCutoutInProgress(cutout);
      });
    }
  }, [frame, generateBaseMask, selectedLayer, setCutoutInProgress]);

  useEffect(() => {
    return () => {
      if (worker) {
        worker.postMessage({ type: "close" });
      }
    };
  }, [worker]);

  useEffect(() => {
    return () => {
      setCutoutInProgress(null);
      setSelectedLayer(null);
    };
  }, [setCutoutInProgress, setSelectedLayer]);

  useEffect(() => {
    samWorker.onmessage = (e) => {
      const { type, body } = e.data;
      switch (type) {
        case "maskImage":
          setMaskImage(body.maskImage);
          break;
        case "model":
          setIsModelLoaded(body.isModelLoaded);
          break;
        case "processing":
          setProcessing(body.processing);
          break;
        case "loadingMask":
          setIsLoadingMask(body.loadingMask);
          break;
      }
    };
    return () => {
      samWorker.postMessage({ type: "close" });
    };
  }, [samWorker]);

  useEffect(() => {
    if (!processing && isModelLoaded) {
      setAnalysisCompleted(true);
      setTimeout(() => {
        setAnalysisCompleted(false);
      }, 1000);
    }
  }, [processing, isModelLoaded]);

  return (
    <div className={styles.container}>
      {data && (processing || !isModelLoaded || analysisCompleted) && (
        <div className={styles.hintContainer}>
          <div className={styles.hintContent}>
            <div className={styles.gifCon}>
              {analysisCompleted ? (
                <img src="/done.gif" className={styles.doneGif} alt="" />
              ) : (
                <img src="/loading.gif" className={styles.loadingGif} alt="" />
              )}
            </div>
            <div className={styles.hintText}>
              <p className={styles.hintHeader}>Analysing this frame...</p>
              <p className={styles.hintDesc}>
                Shouldn't be too long. We should be ready to go in around 10
                seconds
              </p>
            </div>
          </div>
        </div>
      )}
      <Guide
        id={IntroGuides.HoverObject}
        guides={guides}
        open={
          !layers.length && !(processing || !isModelLoaded || analysisCompleted)
        }
        placement="right"
        noMoreTips={noMoreTips}
        title="Hover and select objects"
        description="Select objects by hovering and clicking on the areas you would like to add to the annotation."
      >
        <div className={styles.guideTip} />
      </Guide>
      {data && (
        <EditMode
          ref={editModeRef}
          data={data}
          sourceImageData={imageData}
          maskImage={maskImage}
          setMaskImage={setMaskImage}
          isForReview={false}
          save={handleSave}
          color={color}
          done={onBack}
          onClear={() => {
            setPoints([]);
            setMaskImage(null);
            setBaseMask(null);
          }}
          points={points}
          onHover={getMask}
          setPoints={(ps) => {
            setPoints(ps);
            getMask(ps);
          }}
          isLoadingMask={isLoadingMask}
          cutoutInProgress={cutoutInProgress}
          guides={guides}
          showExitGuide={layers.length === 1}
          noMoreTips={noMoreTips}
          generateBaseMask={generateBaseMask}
          baseMask={baseMask}
        />
      )}
    </div>
  );
};

export default Workspace;
