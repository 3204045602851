import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Button,
  Dialog,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import cx from "classnames";
import React, { useState } from "react";

import { ReactComponent as AddUserIcon } from "assets/add-user.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as PencilIcon } from "assets/pencil-icon.svg";
import { ReactComponent as XMark } from "assets/x-mark.svg";

import styles from "./MemberRow.module.scss";
import { UserDetail } from "../../models/User";
import { Workspace } from "../../models/Workspace";
import {
  changeAccessWorkspace,
  revokeAccessWorkspace,
} from "../../services/WorkspaceService";
import { getTextInitials } from "../../utils/helper";
import NotificationSnackbar from "../NotificationSnackbar";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "#CCCCDC",
    background: "#111113",
    padding: "5px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      borderRadius: "4px",
      fontSize: "14px",
      gap: "10px",
      "&:hover": {
        backgroundColor: "#222226",
      },
    },
  },
}));

interface MemberRowParams {
  user: UserDetail;
  workspace: Workspace;
  setWorkspace: (w: Workspace) => void;
  setSelectedUser: (u: UserDetail) => void;
}

const MemberRow: React.FC<MemberRowParams> = ({
  user,
  workspace,
  setWorkspace,
  setSelectedUser,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const openMenu = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  return (
    <div key={user.id} className={styles.userContainer}>
      {user.profile_picture_url ? (
        <img
          src={user.profile_picture_url}
          alt={user.profile_picture_url}
          className={styles.profilePic}
        />
      ) : (
        <Avatar
          key={user.id}
          className={styles.projectUserAvatar}
          {...{ children: getTextInitials(user.name) }}
        />
      )}

      <div className={styles.userNameEmailContainer}>
        <h3 className={styles.userName}>{user.name}</h3>
        <p className={styles.userEmail}>{user.email}</p>
      </div>
      <p className={styles.userAccessLevelContainer}>
        {workspace?.creator.id === user.id && "Owner"}
        {workspace?.creator.id !== user.id &&
          (user.access_level === "ADMIN" ? "Admin" : "Team member")}
      </p>
      {workspace?.creator.id !== user.id && (
        <div className={styles.actions}>
          <div>
            <Button
              id="actions-menu-button"
              aria-controls={openMenu ? "actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleOpenMenu}
              className={styles.actionMenuButton}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {user.access_level === "ADMIN" ? "Make member" : "Make admin"}
            </Button>
            <StyledMenu
              id="actions-menu"
              MenuListProps={{
                "aria-labelledby": "actions-menu-button",
              }}
              anchorEl={menuAnchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
            >
              {user.access_level !== "ADMIN" && (
                <MenuItem
                  onClick={async () => {
                    await changeAccessWorkspace(
                      workspace?.id,
                      user.id,
                      "ADMIN",
                    ).then((res) => {
                      setWorkspace(res);
                      setNotificationMessage(
                        `Access for ${user.name} has been changed to Admin.`,
                      );
                    });
                    handleCloseMenu();
                  }}
                  disableRipple
                >
                  <AddUserIcon />
                  Make admin
                </MenuItem>
              )}

              {user.access_level === "ADMIN" && (
                <MenuItem
                  onClick={async () => {
                    await changeAccessWorkspace(
                      workspace?.id,
                      user.id,
                      "TEAM_MEMBER",
                    ).then((res) => {
                      setWorkspace(res);
                      setNotificationMessage(
                        `Access for ${user.name} has been changed to Team Member.`,
                      );
                    });
                    handleCloseMenu();
                  }}
                  disableRipple
                >
                  <AddUserIcon className={styles.icon} />
                  Make team member
                </MenuItem>
              )}

              <MenuItem
                onClick={async () => {
                  setShowConfirmationModal(true);
                  handleCloseMenu();
                }}
                disableRipple
              >
                <DeleteIcon className={styles.icon} />
                Remove access
              </MenuItem>
            </StyledMenu>
          </div>
          <Button
            onClick={() => {
              setSelectedUser(user);
            }}
            className={cx(styles.actionMenuButton, styles.projectCount)}
            endIcon={<PencilIcon />}
          >
            {user.project_ids?.length} Projects
          </Button>
        </div>
      )}
      <Dialog
        open={showConfirmationModal}
        PaperProps={{
          className: "dialog",
        }}
      >
        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="modalTitle">Remove team member</h3>
            <button
              onClick={() => {
                setShowConfirmationModal(false);
              }}
              type="button"
              className="closeButton"
            >
              <XMark />
            </button>
          </div>
          <div className={styles.confirmationText}>
            <p>
              Are you sure you want <b>{user.name}</b> to immediately lose
              direct access to this?
            </p>
          </div>
          <div className="modalActions">
            <Button
              type="button"
              className={styles.noBackgroundButton}
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className={styles.outlineButtonRed}
              onClick={async () => {
                setShowConfirmationModal(false);
                await revokeAccessWorkspace(workspace?.id, user.id).then(
                  (res) => {
                    setWorkspace(res);
                    setNotificationMessage(
                      `Access for ${user.name} has been removed successfully.`,
                    );
                  },
                );
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>

      <NotificationSnackbar
        message={notificationMessage}
        onClose={() => setNotificationMessage("")}
      />
    </div>
  );
};

export default MemberRow;
