import { Slider } from "@mui/material";
import React, { useEffect } from "react";

interface SliderProps {
  totalFrames: number;
  currentFrame: number;
  firstFrame: number;
  onChange: (e, value) => void;
  marks: { value: number; label: string }[];
  markStyles: object;
  handleKeyDown?: (e) => void;
}

const VideoSlider: React.FC<SliderProps> = ({
  totalFrames,
  currentFrame,
  firstFrame,
  markStyles,
  marks,
  onChange,
  handleKeyDown,
}) => {
  useEffect(() => {
    if (handleKeyDown) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
    return () => {};
  }, [handleKeyDown]);

  return (
    <Slider
      style={{ marginBottom: "0" }}
      sx={{
        ".Mui-active": {
          boxShadow: "0px 0px 0px 10px rgba(101, 101, 236, 0.3) !important",
        },
        ".Mui-focusVisible": {
          boxShadow: "0px 0px 0px 8px rgba(101, 101, 236, 0.1)",
        },
        ".MuiSlider-markLabel": {
          color: "#AEAEAECC",
          fontSize: "10px",
          top: "-6px",
          fontWeight: "500",
          fontFamily: "Inter",
        },
        ".MuiSlider-thumb": {
          width: "0",
          height: "0",
        },
        ".MuiSlider-thumb:after": {
          content: "none",
        },
        ".MuiSlider-thumb:hover": {
          boxShadow: "0px 0px 0px 8px rgba(101, 101, 236, 0.3) !important",
        },
        ".MuiSlider-mark": {
          backgroundColor: "#1E2129",
          borderRadius: "0px",
          width: "1px",
          height: "13px",
          bottom: "0",
          ...markStyles,
          '&[data-index="0"]': {
            backgroundColor: "#0B0B0F",
          },
        },
        ".MuiSlider-mark[data-index='0']::before": {
          visibility: "hidden",
        },
        ".MuiSlider-rail:before": {
          backgroundColor: "#0B0B0F",
          position: "absolute",
          height: "15px",
          width: "100%",
          bottom: "13px",
          left: "0",
          content: '""',
        },
        ".MuiSlider-rail": {
          height: "13px",
          background:
            "linear-gradient(90deg, rgba(38,41,49,1) 65%, rgba(31,31,41,1) 80%)",
          opacity: 1,
          borderRadius: 0,
          paddingRight: "40px",
          marginLeft: "-20px",
        },
        ".MuiSlider-track": {
          visibility: "hidden",
        },
        ".MuiSlider-valueLabel:before": { content: "none" },
        ".MuiSlider-valueLabel": {
          backgroundColor: "#6565EC",
          top: "6px",
          height: "13px",
          padding: "0px",
          width: "2px",
          borderRadius: "0px",
        },
        ".MuiSlider-valueLabelLabel": {
          height: "14px",
          width: `${(totalFrames + firstFrame - 1).toString().length * 7}px`,
          textAlign: "center",
          backgroundColor: "#6565EC",
          bottom: "13px",
          borderRadius: "2px",
          color: "white",
          fontSize: "10px",
          fontWeight: "500",
          fontFamily: "Inter",
          padding: "0 2px",
          position: "absolute",
          transform: "translate(-50%, 0)",
        },
        width: "calc(100% - 40px)",
      }}
      key={`slider-${Math.random()}`}
      min={firstFrame}
      valueLabelDisplay="on"
      max={totalFrames + firstFrame - 1}
      onChange={onChange}
      defaultValue={currentFrame}
      marks={marks}
    />
  );
};

export default VideoSlider;
