import { Button } from "@mui/material";
import { useFormik, Field, FormikProvider } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as LeftArrowIcon } from "assets/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "assets/right-arrow.svg";
import { InputField } from "components/InputField";
import { SLAPSHOT_LOGIN_URL } from "constants/urls";
import { forgotPassword } from "services/LoginService";

import styles from "./SlapshotForgotPassword.module.scss";

const SlapshotForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCompletion, setShowCompletion] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Email id is required"),
    }),
    onSubmit: async (values, { setStatus }) => {
      setIsLoading(true);
      forgotPassword(values.email)
        .then(() => {
          setIsLoading(false);
          setShowCompletion(true);
        })
        .catch((res) => {
          setIsLoading(false);
          setStatus(res.detail);
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      {showCompletion ? (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div className={styles.textContainer}>
              <h3>Verification link sent</h3>
              <p>
                We have shared a verification link to
                <br />
                <span>{formik.values.email} </span>
                with instructions to reset your
                <br />
                password
              </p>
            </div>
          </div>
          <Link to={SLAPSHOT_LOGIN_URL} className={styles.button}>
            <LeftArrowIcon style={{ marginRight: "12px" }} />
            Back to login
          </Link>
        </div>
      ) : (
        <form className={styles.container} onSubmit={formik.handleSubmit}>
          <div className={styles.innerContainer}>
            <div className={styles.textContainer}>
              <h3>Reset Password</h3>
              <p>
                We will send a verification link to your email to reset
                <br />
                password and sign in again
              </p>
            </div>
            <div>
              <Field
                name="email"
                component={InputField}
                inputClassName={styles.input}
                label="Email address"
                placeholder="Zoya@example.com"
              />
              {formik.status && !formik.errors.email && (
                <div className={styles.formError}>{formik.status}</div>
              )}
            </div>
          </div>
          <Button
            type="submit"
            className="slapshotAuthButton"
            disabled={isLoading || !formik.values.email}
          >
            Send
            <RightArrowIcon style={{ marginLeft: "12px" }} />
          </Button>
        </form>
      )}
    </FormikProvider>
  );
};

export default SlapshotForgotPasswordPage;
