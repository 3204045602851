import { Notification } from "models/Notification";

import { getAPIService, putAPIService } from "./BaseServce";

export const getNotifications = async (unreadOnly: boolean, page: number) => {
  const res = await getAPIService("/notifications", {
    unread_only: unreadOnly,
    page,
  });
  return res.data;
};

export const markAllNotificationsAsRead = async () => {
  const res = await putAPIService("/notifications", {});
  return res.data as Notification[];
};

export const markNotificationAsRead = async (id) => {
  const res = await putAPIService(`/notifications/${id}`, {});
  return res.data;
};
