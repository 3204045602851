/* eslint-disable  react/no-unstable-nested-components  */
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as AddUserIcon } from "assets/add-user.svg";
import { ReactComponent as BackIcon } from "assets/back-icon.svg";
import { ReactComponent as ProjectIcon } from "assets/project-icon.svg";

import styles from "./InviteUserPage.module.scss";
import InviteRow from "../../components/InviteRow";
import Loader from "../../components/Loader";
import MemberRow from "../../components/MemberRow";
import NotificationSnackbar from "../../components/NotificationSnackbar";
import ProjectAutoComplete, {
  ProjectOptionType,
} from "../../components/ProjectAutoComplete";
import { StyledTab, StyledTabs } from "../../components/StyledTab";
import { SLAPSHOT_WORKSPACES_URL } from "../../constants/urls";
import { UserDetail } from "../../models/User";
import { Workspace } from "../../models/Workspace";
import {
  getWorkspaceDetail,
  inviteMemberWorkspace,
  updateProjectAccess,
} from "../../services/WorkspaceService";

import "react-multi-email/dist/style.css";

const InviteUserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [workspace, setWorkspace] = useState<Workspace | null>(null);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projects, setProjects] = useState<ProjectOptionType[]>([]);
  const [projectsValue, setProjectsValue] = useState<ProjectOptionType[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [emailFocus, setEmailFocus] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<UserDetail | null>(null);
  const [userProjects, setUserProjects] = useState<ProjectOptionType[]>([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const workspaceId = params.get("workspaceId");
    const pId = params.get("projectId");
    setProjectId(pId ? +pId : null);
    if (workspaceId) {
      getWorkspaceDetail(+workspaceId).then((res) => {
        setWorkspace(res);
        setProjects(res?.projects as ProjectOptionType[]);
        if (pId) {
          setProjectsValue(
            res.projects.filter((p) => p.id === +pId) as ProjectOptionType[],
          );
        }
        setIsLoading(false);
      });
    }
  }, [location.search]);

  return (
    <div>
      <Loader isLoading={isLoading} />
      {!isLoading && workspace && (
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <h3 className={styles.title}>{workspace?.name || "Workspace"}</h3>
          </div>
          <div className={styles.innerContainer}>
            {!selectedUser && (
              <div className={styles.workingContainer}>
                <Link to={SLAPSHOT_WORKSPACES_URL} className={styles.backLink}>
                  <BackIcon className={styles.backIcon} />
                  Back
                </Link>
                <h2 className={styles.label}>Invite team members</h2>
                <div className={styles.addPeople}>
                  <ReactMultiEmail
                    placeholder="Add people..."
                    emails={emails}
                    onChange={(es: string[]) => {
                      setEmails(es);
                    }}
                    className={cx(
                      styles.multiEmail,
                      emailFocus ? styles.focused : "",
                    )}
                    autoFocus
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div key={index} className={styles.emailTag}>
                          <div>{email}</div>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />

                  <button
                    type="button"
                    className={styles.inviteUserButton}
                    disabled={emails.length === 0 || projectsValue.length === 0}
                    onClick={async () => {
                      await inviteMemberWorkspace(
                        workspace?.id,
                        emails,
                        projectsValue.map((p) => p.id),
                      ).then((res) => {
                        setNotificationMessage(
                          `Invitation sent to <b>${emails.join(", ")}</b> for projects <b>${projectsValue.map((p) => p.name).join(", ")}</b>.`,
                        );
                        setProjectsValue([]);
                        setEmails([]);
                        setWorkspace(res);
                      });
                    }}
                  >
                    <AddUserIcon /> Invite
                  </button>
                </div>

                <h5 className={styles.projectsLabel}>
                  Select projects (Optional)
                </h5>

                <ProjectAutoComplete
                  projects={projects}
                  values={projectsValue}
                  setValues={setProjectsValue}
                />
                <Box>
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                  >
                    <StyledTab
                      label={`Team members (${workspace?.users.length})`}
                    />
                    <StyledTab
                      sx={{ marginLeft: "20px" }}
                      label="Pending Invites"
                    />
                  </StyledTabs>
                </Box>

                {value === 0 &&
                  workspace?.users.map((user) => {
                    return (
                      <MemberRow
                        key={user.id}
                        user={user}
                        workspace={workspace}
                        setWorkspace={setWorkspace}
                        setSelectedUser={(u) => {
                          setSelectedUser(u);
                          setUserProjects(
                            projects.filter((p) =>
                              u.project_ids?.includes(p.id),
                            ),
                          );
                        }}
                      />
                    );
                  })}

                {value === 1 &&
                  workspace?.pending_invites.map((invite) => {
                    return (
                      <InviteRow
                        key={invite.id}
                        invite={invite}
                        setWorkspace={setWorkspace}
                      />
                    );
                  })}
              </div>
            )}
            {selectedUser && (
              <div className={styles.workingContainer}>
                <p
                  onClick={() => setSelectedUser(null)}
                  className={styles.backLink}
                >
                  <BackIcon className={styles.backIcon} />
                  Back
                </p>
                <h2 className={styles.label}>
                  Edit project access for {selectedUser.name}
                </h2>

                <div className={styles.projectAutoComplete}>
                  <ProjectAutoComplete
                    projects={projects}
                    values={userProjects}
                    setValues={setUserProjects}
                    className={styles.autocomplete}
                  />

                  <button
                    type="button"
                    className={styles.inviteUserButton}
                    onClick={async () => {
                      await updateProjectAccess(
                        workspace?.id,
                        selectedUser?.id,
                        userProjects.map((p) => p.id),
                      ).then((res) => {
                        setNotificationMessage(
                          `Project access has been updated for <b>${selectedUser?.name}</b>.`,
                        );
                        setWorkspace(res);
                      });
                    }}
                  >
                    Save
                  </button>
                </div>

                <h3 className={styles.teamMembersLabel}>
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#6565EC",
                      },
                    }}
                    onChange={(e, v) => {
                      if (!v) {
                        setUserProjects([]);
                      } else {
                        setUserProjects(projects as ProjectOptionType[]);
                      }
                    }}
                    checked={projects.length === userProjects.length}
                  />
                  Selected ({userProjects.length})
                </h3>

                <div className={styles.projectContainer}>
                  {projects.map((p) => {
                    return (
                      <div key={p.id} className={styles.projectBlock}>
                        <Checkbox
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#6565EC",
                            },
                          }}
                          onChange={(e, v) => {
                            if (!v) {
                              setUserProjects(
                                userProjects.filter((up) => up.id !== p.id),
                              );
                            } else {
                              setUserProjects([p, ...userProjects]);
                            }
                          }}
                          checked={!!userProjects.find((up) => up.id === p.id)}
                        />
                        {p.shot_thumbnails.length > 0 ? (
                          <img
                            alt={p.shot_thumbnails[0]}
                            className={styles.projectThumbnailImage}
                            src={p.shot_thumbnails[0]}
                          />
                        ) : (
                          <ProjectIcon className={styles.projectThumbnail} />
                        )}
                        <div className={styles.projectInfo}>
                          <h5>{p.name}</h5>
                          <p style={{ marginTop: "5px" }}>
                            {p.shots_count} assets
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <NotificationSnackbar
        message={notificationMessage}
        onClose={() => {
          setNotificationMessage("");
        }}
      />
    </div>
  );
};

export default InviteUserPage;
