import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Avatar, Button, Select, MenuItem } from "@mui/material";
import cx from "classnames";
import { useFormik, Field, FormikProvider } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as CheckIcon } from "assets/check-icon.svg";
import { ReactComponent as HotspringIcon } from "assets/hotspring.svg";
import { ReactComponent as LogoutIcon } from "assets/logout-icon.svg";
import { ReactComponent as PrivacyIcon } from "assets/privacy-security.svg";
import { ReactComponent as ProfileIcon } from "assets/profile-icon.svg";

import styles from "./ProfilePage.module.scss";
import { InputField } from "../../components/InputField";
import { SLAPSHOT_LOGIN_URL } from "../../constants/urls";
import {
  generateAPIKey,
  changePassword,
  updateUserInfo,
  getUserInfo,
  updateProfilePicture,
} from "../../services/UserService";
import { getInitials, getUser } from "../../utils/helper";

export interface UserInfo {
  name: string;
  last_name: string;
  email: string;
  phone: string;
  profile_picture_url: string;
}

const ProfilePage = () => {
  const user = getUser();
  const navigate = useNavigate();

  const [apiKey, setApiKey] = useState("");

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [tabIndex, setTabIndex] = useState<number | null>(1);
  const handleChange = (tab: number) => {
    setTabIndex(tab);
  };

  const timezones = moment.tz.names();

  const formatTimezone = (timezone) => {
    const dt = moment.tz(timezone);
    const offset = dt.utcOffset();
    const sign = offset >= 0 ? "+" : "-";
    const absOffset = Math.abs(offset);
    const hours = String(Math.floor(absOffset / 60)).padStart(2, "0");
    const minutes = String(absOffset % 60).padStart(2, "0");
    const formattedOffset = `UTC ${sign}${hours}:${minutes}`;

    return `${formattedOffset} - ${timezone}`;
  };

  const changePasswordFormik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Current password is required."),
      newPassword: Yup.string().required("Please choose a new password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Please retype your password"),
    }),
    onSubmit: async (values, { setStatus, resetForm }) => {
      changePassword(values.password, values.newPassword).then((res) => {
        resetForm();
        setStatus(res);
      });
    },
  });

  const updateProfileFormik = useFormik({
    initialValues: {
      name: userInfo?.name || "",
      lastName: userInfo?.last_name || "",
      contactNumber: userInfo?.phone || "",
      emailId: userInfo?.email || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("First name is required"),
      lastName: Yup.string().nullable(),
      contactNumber: Yup.string().nullable(),
      emailId: Yup.string().email().required("Email Id is required"),
    }),
    onSubmit: async (values, { setValues }) => {
      updateUserInfo(values).then((res) => {
        setUserInfo(res);
        updateFormValues(res);
      });
    },
  });

  const updateFormValues = useCallback(
    (values: UserInfo) => {
      updateProfileFormik.setValues({
        name: values.name || "",
        lastName: values.last_name || "",
        contactNumber: values.phone || "",
        emailId: values.email || "",
      });
    },
    [updateProfileFormik],
  );

  useEffect(() => {
    if (!userInfo) {
      getUserInfo().then((res) => {
        setUserInfo(res);
        updateFormValues(res);
      });
    }
  }, [updateFormValues, userInfo]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.sidebar}>
        <div className={styles.sidebarContent}>
          <div className={styles.profileInfo}>
            {userInfo && userInfo.profile_picture_url ? (
              <img
                src={userInfo.profile_picture_url}
                className={styles.sidebarProfile}
                alt={userInfo.profile_picture_url}
              />
            ) : (
              <Avatar
                className={styles.avatarButton}
                {...{ children: getInitials() }}
                sx={{ bgcolor: "purple", width: 46, height: 46 }}
              />
            )}
            <div className={styles.userDetails}>
              <h3>{user.name}</h3>
              {/* TODO: fetch role from db */}
              <p>Super admin</p>
            </div>
          </div>
          <div className={styles.accountSettings}>
            <h5>ACCOUNT SETTINGS</h5>
            <div className={styles.settingsMenu}>
              <Button
                className={cx(
                  styles.menuButton,
                  tabIndex === 1 && styles.selectedMenuButton,
                )}
                onClick={() => {
                  handleChange(1);
                }}
              >
                <ProfileIcon className={styles.menuButtonIcon} />
                Profile
              </Button>
              <Button
                className={cx(
                  styles.menuButton,
                  tabIndex === 4 && styles.selectedMenuButton,
                )}
                onClick={() => {
                  handleChange(4);
                }}
              >
                <PrivacyIcon className={styles.menuButtonIcon} />
                Privacy & Security
              </Button>
            </div>
          </div>
          <div className={styles.accountSettings}>
            <h5>INTEGRATIONS</h5>
            {user.email.substring(user.email.lastIndexOf("@")) ===
              "@thehotspring.com" && (
              <Button
                className={cx(
                  styles.menuButton,
                  tabIndex === 6 && styles.selectedMenuButton,
                )}
                onClick={() => {
                  handleChange(6);
                }}
              >
                <HotspringIcon className={styles.menuButtonIcon} />
                Hotspring
              </Button>
            )}
          </div>
        </div>
        <div className={styles.sidebarFooter}>
          <Button
            className={styles.logoutButton}
            onClick={async () => {
              localStorage.clear();
              navigate(SLAPSHOT_LOGIN_URL);
            }}
          >
            <LogoutIcon className={styles.menuButtonIcon} />
            Log out
          </Button>
        </div>
      </div>
      <div className={styles.container}>
        {tabIndex === 1 && (
          <div className={styles.tabPanal}>
            <div className={styles.contentHeader}>
              <h3>Profile Information</h3>
            </div>
            <div className={styles.innerContainer}>
              <FormikProvider value={updateProfileFormik}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateProfileFormik.handleSubmit();
                  }}
                  className={styles.profileFormContainer}
                >
                  <div className={styles.avatarRow}>
                    <h5>Avatar</h5>
                    <div className={styles.avatarContainer}>
                      {userInfo && userInfo.profile_picture_url ? (
                        <img
                          src={userInfo.profile_picture_url}
                          className={styles.profileImage}
                          alt={userInfo.profile_picture_url}
                        />
                      ) : (
                        <Avatar
                          className={styles.avatarButton}
                          {...{ children: getInitials() }}
                          sx={{ bgcolor: "purple", width: 70, height: 70 }}
                        />
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          const file = event.target.files?.[0];
                          if (file) {
                            const formData = new FormData();
                            formData.append("profile_picture", file);
                            updateProfilePicture(formData).then((res) => {
                              setUserInfo(res);
                            });
                          }
                        }}
                      />
                      <Button
                        className="noBackgroundButton"
                        onClick={() => {
                          fileInputRef.current?.click();
                        }}
                      >
                        Upload Photo
                      </Button>
                    </div>
                  </div>
                  <div className={styles.formRow}>
                    <Field
                      name="name"
                      component={InputField}
                      inputClassName={styles.profileInput}
                      label="First Name"
                      placeholder="Ex: Jon"
                    />
                    <Field
                      name="lastName"
                      component={InputField}
                      inputClassName={styles.profileInput}
                      label="Last Name"
                    />
                  </div>
                  <div className={styles.formRow}>
                    <Field
                      name="contactNumber"
                      component={InputField}
                      inputClassName={styles.profileInput}
                      label="Contact Number"
                      placeholder="Ex:+91 9848022338"
                    />
                    <Field
                      name="emailId"
                      component={InputField}
                      inputClassName={styles.profileInput}
                      label="Email Id"
                      placeholder="John@example.com"
                    />
                  </div>
                  <div className={styles.formActions}>
                    <Button
                      className="noBackgroundButton"
                      onClick={() => {
                        userInfo && updateFormValues(userInfo);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="slapShotButton"
                      onClick={() => {
                        updateProfileFormik.handleSubmit();
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
        )}
        {tabIndex === 4 && (
          <div className={styles.tabPanal}>
            <div className={styles.contentHeader}>
              <h3>Privacy & Security</h3>
            </div>
            <div className={styles.innerContainer}>
              <FormikProvider value={changePasswordFormik}>
                <form
                  className={styles.changePasswordForm}
                  onSubmit={(e) => {
                    e.preventDefault();
                    changePasswordFormik.handleSubmit();
                  }}
                >
                  {changePasswordFormik.status && (
                    <p className={styles.formStatus}>
                      <CheckIcon style={{ marginRight: "8px" }} />
                      Successfully changed the password
                    </p>
                  )}
                  <Field
                    name="password"
                    inputClassName={styles.profileInput}
                    component={InputField}
                    label="Old password"
                    type="password"
                  />
                  <Field
                    name="newPassword"
                    inputClassName={styles.profileInput}
                    component={InputField}
                    label="New password"
                    type="password"
                  />
                  <Field
                    name="confirmPassword"
                    inputClassName={styles.profileInput}
                    component={InputField}
                    label="Confirm Password"
                    type="password"
                  />
                  <div className={styles.formActions}>
                    <Button
                      className="noBackgroundButton"
                      onClick={() => {
                        changePasswordFormik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="slapShotButton"
                      onClick={() => {
                        changePasswordFormik.handleSubmit();
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
        )}
        {tabIndex === 6 && (
          <div className={styles.tabPanal}>
            <div className={styles.contentHeader}>
              <h3>Integrations</h3>
            </div>
            <div className={styles.generateAPIKeyContainer}>
              <div className={styles.apiFieldContainer}>
                <h5>Generate API Key</h5>
                <div className={styles.apiKeyField}>{apiKey}</div>
                <button
                  type="button"
                  className={styles.copyButton}
                  onClick={async () => {
                    await navigator.clipboard.writeText(apiKey);
                  }}
                >
                  <ContentCopyIcon />
                </button>
              </div>
              <div className={styles.actionsContainer}>
                <Button
                  className="slapShotButton"
                  onClick={async () => {
                    const res = await generateAPIKey();
                    setApiKey(res.key);
                  }}
                >
                  Generate API Key
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
