import { ClickAwayListener } from "@mui/material";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BellIcon } from "assets/bell.svg";
import { ReactComponent as ExportNotificationIcon } from "assets/export-notification.svg";
import { ReactComponent as InferenceNotificationIcon } from "assets/inference-notification.svg";
import { ReactComponent as ShotNotificationIcon } from "assets/shot-notification.svg";
import { ReactComponent as XMark } from "assets/x-mark.svg";
import { SLAPSHOT_HOME_URL } from "constants/urls";
import { Notification } from "models/Notification";

import styles from "./Notifications.module.scss";
import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../../services/NotificationService";
import {
  ACTION_SUBSCRIBE_NOTIFICATION_UPDATE,
  getRequestData,
  getSocket,
} from "../../services/WebSocketService";

const Notifications = () => {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [exiting, setExiting] = useState(false);

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "100px",
  });

  useEffect(() => {
    setLoading(true);
    getNotifications(showOnlyUnread, page).then((res) => {
      if (page === 1) {
        setNotifications(res.results);
      } else {
        setNotifications((prev) => [...prev, ...res.results]);
      }
      setHasMore(!!res.next);
      setLoading(false);
    });
  }, [showOnlyUnread, page]);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, hasMore, loading]);

  const newNotificationsCount = notifications.filter(
    (notification) => !notification.is_read,
  ).length;

  // Subscribing to notifications
  useEffect(() => {
    const socket = getSocket("notification");
    if (!socket) {
      return () => {};
    }
    socket.onopen = (event) => {
      socket.send(getRequestData(ACTION_SUBSCRIBE_NOTIFICATION_UPDATE));
    };

    socket.onmessage = (event) => {
      const e = JSON.parse(event.data).data;
      if (e.type === "create") {
        setNotifications((prev) => {
          return [e.notification, ...prev];
        });
      }
    };

    return () => {
      socket.close();
    };
  }, [notifications]);

  const getNotificationIcon = (type: string) => {
    if (type === "SHOT") {
      return <ShotNotificationIcon />;
    }
    if (type === "EXPORT") {
      return <ExportNotificationIcon />;
    }
    if (type === "INFERENCE") {
      return <InferenceNotificationIcon />;
    }

    return <InferenceNotificationIcon />;
  };

  const getNotificationTime = (created: Date) => {
    const currentTime = new Date();
    const diff = currentTime.getTime() - new Date(created).getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30); // Rough estimation, not precise
    const years = Math.floor(days / 365); // Rough estimation, not precise

    let creationText = "";
    if (seconds < 60) {
      creationText = `A few seconds ago`;
    } else if (minutes < 60) {
      creationText = `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      creationText = `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      creationText = `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4 || months === 0) {
      creationText = `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      creationText = `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      creationText = `${years} year${years !== 1 ? "s" : ""} ago`;
    }

    return creationText;
  };

  const handleClose = () => {
    setExiting(true);
    setTimeout(() => {
      setShowNotifications(false);
      setExiting(false);
    }, 300);
  };

  return (
    <div className={styles.notificationsContainer}>
      <button
        type="button"
        className={styles.notificationsButton}
        onClick={() => {
          setShowNotifications(true);
        }}
      >
        <BellIcon />
        {newNotificationsCount > 0 && (
          <div className={styles.redDot} style={{ marginLeft: 0 }} />
        )}
        <span>Notifications</span>
      </button>
      {showNotifications && (
        <ClickAwayListener
          onClickAway={(e) => {
            handleClose();
          }}
          className={styles.noOverflow}
        >
          <div
            className={cx(
              styles.popoverContainer,
              exiting
                ? styles.notificationsExiting
                : styles.notificationsVisible,
            )}
          >
            <div className={styles.notificationsHeader}>
              <div className={styles.title}>
                <h3>Notifications</h3>
                {newNotificationsCount > 0 && (
                  <p className={styles.notificationsCount}>
                    {newNotificationsCount}
                  </p>
                )}
              </div>
              <div className={styles.flexCol}>
                <button
                  type="button"
                  className={styles.readAllButton}
                  onClick={() => {
                    markAllNotificationsAsRead().then((res) => {
                      setNotifications(res);
                    });
                  }}
                >
                  Mark all as read
                </button>
                <button
                  type="button"
                  className={styles.closeButton}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <XMark />
                </button>
              </div>
            </div>
            <div className={styles.filters}>
              <span>Show only unread</span>
              <span className={styles.switch}>
                <input
                  id="switch-rounded"
                  type="checkbox"
                  checked={showOnlyUnread}
                  onClick={() => {
                    setShowOnlyUnread(!showOnlyUnread);
                  }}
                />
                <label htmlFor="switch-rounded" />
              </span>
            </div>
            <div className={styles.notifications}>
              {notifications.map((n) => (
                <div
                  key={n.id}
                  className={cx(
                    styles.notificationContainer,
                    !n.is_read && styles.unreadNotification,
                  )}
                  onClick={() => {
                    markNotificationAsRead(n.id).then((res) => {
                      setNotifications((prevNotifications) =>
                        prevNotifications.map((notification) =>
                          notification.id === n.id
                            ? { ...notification, is_read: true }
                            : notification,
                        ),
                      );
                      navigate(`${SLAPSHOT_HOME_URL}${n.ui.url}`);
                      setShowNotifications(false);
                    });
                  }}
                >
                  <div className={styles.notificationIcon}>
                    {getNotificationIcon(n.ui.type.toUpperCase())}
                  </div>
                  <div className={styles.textContainer}>
                    {/* eslint-disable react/no-danger */}
                    <h3
                      className={styles.message}
                      dangerouslySetInnerHTML={{ __html: n.ui.message }}
                    />
                    {/* eslint-enable react/no-danger */}
                    <span className={styles.createdText}>
                      {getNotificationTime(n.created)}
                    </span>
                  </div>
                </div>
              ))}
              {hasMore && (
                <div ref={ref} className={styles.loadingIndicator}>
                  {loading ? "Loading more..." : "Scroll to load more"}
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Notifications;
