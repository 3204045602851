import * as assert from "node:assert";

import {
  deleteAPIService,
  getAPIService,
  postAPIService,
  putAPIService,
} from "./BaseServce";
import { Workspace } from "../models/Workspace";

export const getWorkspace = async () => {
  const res = await getAPIService("/company");
  return res.data as Workspace[];
};

export const renameWorkspace = async (id: number, name: string) => {
  const res = await putAPIService(`/company/${id}`, { name });
  return res.data;
};

export const getWorkspaceDetail = async (id: number) => {
  const res = await getAPIService(`/company/${id}`);
  return res.data as Workspace;
};

export const inviteMemberWorkspace = async (
  id: number,
  emails: string[],
  projectIds: number[],
) => {
  const res = await postAPIService(`/company/${id}/invite`, {
    emails,
    project_ids: projectIds,
  });
  return res.data as Workspace;
};

export const changeAccessWorkspace = async (
  id: number,
  userId: number,
  accessLevel: string,
) => {
  const res = await postAPIService(`/company/${id}/change-access`, {
    user_id: userId,
    access_level: accessLevel,
  });
  return res.data as Workspace;
};

export const revokeInvite = async (id: number) => {
  const res = await deleteAPIService(`/pending-invites/${id}/revoke`);
  return res.data as Workspace;
};

export const revokeAccessWorkspace = async (id: number, userId: number) => {
  const res = await postAPIService(`/company/${id}/revoke-access`, {
    user_id: userId,
  });
  return res.data as Workspace;
};

export const acceptInvite = async (code: string) => {
  const res = await postAPIService(`/company/accept-invite`, {
    token: code,
  });
  return res.data;
};

export const updateProjectAccess = async (
  id: number,
  userId: number,
  projectIds: number[],
) => {
  const res = await postAPIService(`/company/${id}/update-project-access`, {
    user_id: userId,
    project_ids: projectIds,
  });
  return res.data as Workspace;
};
