import CheckIcon from "@mui/icons-material/CheckCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadOutlineFileIcon from "@mui/icons-material/UploadOutlined";
import cx from "classnames";
import React, { useState } from "react";
import Dropzone, { FileWithPath } from "react-dropzone";

import styles from "./UploadAnnotationsField.module.scss";
import DotElastic from "../DotElastic";

interface UploadAnnotationsFieldParams {
  onSelect: (files: File[]) => void;
  className?: string;
  isUploadStarted?: boolean;
  isUploadCompleted?: boolean;
}

const MAX_ALLOWED_FILES = 10;
const MAX_SIZE = 1024;

const UploadAnnotationsField: React.FC<UploadAnnotationsFieldParams> = ({
  onSelect,
  className,
  isUploadStarted,
  isUploadCompleted,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [error, setError] = useState("");
  return (
    <Dropzone
      onDragEnter={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
      disabled={isUploadStarted}
      accept={{
        "image/png": [".png"],
      }}
      maxSize={1024 * MAX_SIZE}
      maxFiles={MAX_ALLOWED_FILES}
      onDrop={async (acceptedFiles, fileRejections) => {
        setError("");
        setIsDragOver(false);

        if (fileRejections.length > 0) {
          if (fileRejections.length > MAX_ALLOWED_FILES) {
            setError(
              `You can not upload more than ${MAX_ALLOWED_FILES} cutouts at once.`,
            );
          } else {
            setError(`You can not upload a cutout of more than ${MAX_SIZE}Kb.`);
          }
        } else {
          setIsDragOver(false);
          // Removing hidden files
          const files: FileWithPath[] = acceptedFiles.filter(
            (file) => !file.name.startsWith("."),
          );
          onSelect(files);
        }
      }}
    >
      {({ getRootProps, getInputProps, inputRef }) => {
        return (
          <section className={className}>
            <div className={cx(styles.dragDropBlock)} {...getRootProps()}>
              {!isUploadStarted && (
                <>
                  {isDragOver && <div className={styles.fullScreenBlock} />}
                  <input {...getInputProps()} />

                  <UploadOutlineFileIcon style={{ fontSize: "80px" }} />
                  <p className={styles.text}>
                    Drag and drop annotations to upload...
                  </p>
                  <p className={styles.hint}>
                    Max number of cutouts allowed: {MAX_ALLOWED_FILES}
                  </p>
                  <p className={styles.hint}>Max cutout size: {MAX_SIZE}Kb</p>
                </>
              )}
              {error && (
                <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
              )}
              {isUploadStarted && !isUploadCompleted && (
                <>
                  <UploadFileIcon
                    style={{ color: "orange", fontSize: "80px" }}
                  />
                  <b className={cx(styles.message)}>
                    Uploading your annotations...
                  </b>

                  <DotElastic />
                </>
              )}
              {isUploadStarted && isUploadCompleted && (
                <>
                  <CheckIcon style={{ color: "green", fontSize: "80px" }} />
                  <b className={styles.message}>
                    Your cutouts have been processed successfully.
                  </b>
                </>
              )}
            </div>
          </section>
        );
      }}
    </Dropzone>
  );
};

export default UploadAnnotationsField;
