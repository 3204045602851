/* eslint-disable */
export function hash(x: number) {
  x = x ^ 61 ^ (x >> 16);
  x += x << 3;
  x ^= x >> 4;
  x *= 0x27d4eb2d;
  x ^= x >> 15;
  return x;
}

export function getRGB(idx: number) {
  const r = hash(idx) & 0xff;
  const g = hash(idx >> 8) & 0xff;
  const b = hash(idx >> 16) & 0xff;
  return [255, 0, 0];
}

export function decompress(
  compressedMask: string,
  width: number,
  height: number,
) {
  const pairs: [number, number][] = [];
  let countStr = "";
  for (const char of compressedMask) {
    if (/\d/.test(char)) {
      countStr += char;
    } else {
      pairs.push([parseInt(countStr), char === "T" ? 1 : 0]);
      countStr = "";
    }
  }
  const mask = new Array(height).fill(0).map(() => new Array(width).fill(0));
  let x = 0;
  let y = 0;
  for (const [count, value] of pairs) {
    for (let i = 0; i < count; i++) {
      mask[y][x] = value;
      x++;
      if (x === width) {
        x = 0;
        y++;
      }
    }
  }
  // above mask is a 2d mask, convert it to index mask for better performance
  for (let i = 0; i < height; i++) {
    const sum = mask[i].reduce((a, b) => a + b, 0);
    if (sum === 0) {
      mask[i] = [];
    } else {
      const indexes = mask[i]
        .map((v, index) => (v === 1 ? index : -1))
        .filter((v) => v !== -1);
      mask[i] = indexes;
    }
  }
  return mask;
}

export function formatLongText(text: string, maxChars: number) {
  if (text.length > maxChars) return `${text.slice(0, maxChars)}...`;
  return text;
}
